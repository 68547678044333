import React from 'react';
import styled from 'styled-components';
import PageHelmet from '../PageHelmet/index';
import { _GetAccessToken } from '@netfront/gelada-react-shared';
// Components
import ConsoleHeader from '../../components/Header/ConsoleHeader';
import Header from '../../components/Header/Header';
import SecondaryHeader from '../../components/Header/SecondaryHeader';
import Footer from '../../components/Footer/Footer';
import { Wrapper } from '../../pages/ConsolePage/styled';

export const Container = styled.div`
  width: 80%;
  max-width: 75rem;
  margin: 2em auto;
  background-color: #fff;
`;

const LayoutGeneralPage = (props) => {
  const { title, children } = props;
  return (
    <Wrapper>
      <PageHelmet title={`FFSP: ${title}`} />
      {_GetAccessToken() ? <ConsoleHeader /> : <Header isHome />}
      <SecondaryHeader isPublic title={title} />
      {children}
      <Footer />
    </Wrapper>
  );
};

export default LayoutGeneralPage;
