import React, { useRef, useState } from "react";
import PageHelmet from "../../components/PageHelmet";
import { Form, PageBg, PageWrapper } from "../LoginPage/styled";
import { withRouter } from "react-router";
import Footer from "../../components/Footer/Footer";
import styled from "styled-components";
import Preloader from "../../components/UI/Loader/Preloader";
import img from "../../assets/img/login.svg";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import GetStep, { GET_USERFLOW_STEP } from "../../middleware/Userflow/getUserFlowStep";
import ReactGA from "react-ga";
import GeladaClient from "../../middleware/GeladaClient";
import { _Store, _StoreTokens } from "@netfront/gelada-react-shared";
import { useLazyQuery } from "../../hooks/useLazyQuery";
const LoadingOverlay = styled.div`
  position: fixed;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LOGIN = gql`
  query login($token: String!) {
    loginWithAuthenticationToken(token: $token) {
      token
      refreshToken
      user {
        id
        firstname
        created
        lastname
        communityName
        credential {
          email
        }
      }
    }
  }
`;

const AccessPage = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const accessKey = urlParams.get("k");
  const module = urlParams.get("module");

  if (!accessKey) {
    window.location.href = "/";
  }

  const client = useRef(GeladaClient(false));

  const handleCheckSurveyComplete = (data, type) => {
    const {
      userFlowStep: { getUserFlowStep },
    } = data;
    if (
      getUserFlowStep.userRepetition.length <= getUserFlowStep.repetitionAllowed &&
      (getUserFlowStep.userRepetition.length === 0 ||
        getUserFlowStep.userRepetition[0].completed === null)
    ) {
      const lastViewed =
        getUserFlowStep.lastViewedContentPage !== null
          ? getUserFlowStep.lastViewedContentPage.url
          : getUserFlowStep.contentGroup.contentPages.find((p) => p.sort === 0).url;
      props.history.push(`/content/alcohol-or-other-drugs/${getUserFlowStep.url}/${lastViewed}`);
    } else if (module?.includes("domestic-violence")) {
      props.history.push(
        "/content/domestic-violence/domestic-violence/introduction-to-the-module-1"
      );
    } else {
      props.history.push("/dashboard");
    }
  };

  const [getUserFlowStep] = useLazyQuery(GET_USERFLOW_STEP, {
    variables: {
      userFlowStepId: 40,
    },
    onCompleted: handleCheckSurveyComplete,
  });

  const handleErrorLogin = (error) => {
    console.error(error);
    props.history.push("/");
  };

  const [sendQuery, { refetch }] = useLazyQuery(
    gql`
      query ($userId: Int!) {
        user {
          checkUserAuthorizationByUserId(userId: $userId)
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.user.checkUserAuthorizationByUserId) {
          getUserFlowStep();
        } else {
          setInterval(() => {
            refetch();
          }, 1000);
        }
      },
    }
  );

  const handleLogin = (data) => {
    const {
      loginWithAuthenticationToken: { token, refreshToken, user },
    } = data;
    _Store(user, "UserData");
    _StoreTokens(token, refreshToken);
    ReactGA.set({ userId: user.id });
    sendQuery({
      variables: {
        userId: user.id,
      },
    });
  };

  useQuery(LOGIN, {
    client: client.current,
    onCompleted: handleLogin,
    onError: handleErrorLogin,
    variables: {
      token: accessKey,
    },
  });

  return (
    <div style={{ minWidth: "300px" }}>
      <PageHelmet title="FFSP" />
      <PageBg />
      <PageWrapper>
        <loginContainer logo={img} title={"Login"} description={"description"}>
          <Form></Form>
        </loginContainer>
      </PageWrapper>
      <Preloader />
      <LoadingOverlay id="loading" />
      <Footer />
    </div>
  );
};

export default withRouter(AccessPage);
