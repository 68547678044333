import React from "react";

const ValidationProcessor = validations => {
  const validationErrors = [];
  let id = 0;

  Object.keys(validations).forEach(property => {
    if (!validations[property].validation)
      validationErrors.push(
        <li key={`validations[property].errorMessage.substring(0, 5)}-${id}`}>
          {validations[property].errorMessage}
        </li>
      );
    id++;
  });

  const response = {
    modelValid: validationErrors.length === 0,
    validations: validationErrors
  };

  return response;
};

export default ValidationProcessor;
