// Libraries
import React, { useState } from "react";
import { Link } from "react-router-dom";
// Components
import PageHelmet from "../../components/PageHelmet/index";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import LoginContainer from "../../containers/LoginContainer/LoginContainer";
import ErrorMessage from "../../components/Message/ErrorMessage";
import NoticeMessage from "../../components/Message/NoticeMessage";
import ValidationProcessor from "../../components/Message/ValidationProcessor";
import {
  PageBg,
  PageWrapper,
  Wrapper,
  Wrapper2,
  NameBox,
  NameGroup,
  RequiredStar,
  InputGroup,
  TextBox,
  CheckBox,
  Agreement,
  RegisterButton,
  P,
  SimpleIcon,
} from "./styled";
import img from "../../assets/img/login.svg";

import { useMutation } from "../../hooks/useMutation";
import {
  SignUp_Mutation,
  ResendActivationCode_Mutation,
  Client,
} from "@netfront/gelada-react-shared";

const RegisterPage = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [checkTerms, setCheckTerms] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);

  const [registerUser] = useMutation(SignUp_Mutation, {
    client: Client(false),
  });

  const [resendActivation] = useMutation(ResendActivationCode_Mutation, {
    client: Client(false),
  });

  const validate = () => ({
    FirstnameEmpty: {
      validation: firstname.trim().length > 0,
      errorMessage: "Firstname must not be empty.",
    },
    LastnameEmpty: {
      validation: lastname.trim().length > 0,
      errorMessage: "Lastname must not be empty.",
    },
    EmailEmpty: {
      validation: email.trim().length > 0,
      errorMessage: "Email must not be empty.",
    },
    PasswordsMatch: {
      validation: password === rePassword,
      errorMessage: "Passwords do not match.",
    },
    PasswordsMinLength: {
      validation: password.length >= 6,
      errorMessage: "Password must have minimum 6 chars.",
    },
    PasswordEmpty: {
      validation: password.trim().length > 0,
      errorMessage: "Password must not be empty.",
    },
    UnCheckedTerms: {
      validation: checkTerms,
      errorMessage: "You must agree to the terms.",
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = ValidationProcessor(validate());
    if (!validationErrors.modelValid) {
      setValidationErrors(validationErrors.validations);
    } else {
      setValidationErrors("");
      registerUser({
        variables: {
          user: { firstname: firstname, lastname: lastname },
          credential: { email: email, password: password },
        },
      })
        .then(() => {
          setIsCompleted(true);
        })
        .catch((error) => {
          setValidationErrors(
            error.message.substring(
              error.message.indexOf(":") + 1,
              error.message.length
            )
          );
        });
    }
  };

  const handleResend = () => {
    setResendEmail(true);
    resendActivation({
      variables: {
        email: email,
      },
    })
      .then(() => {})
      .catch((error) => {
        setValidationErrors(
          error.message.substring(
            error.message.indexOf(":") + 1,
            error.message.length
          )
        );
      });
  };

  return (
    <div style={{ minWidth: "300px" }}>
      <PageHelmet title="FFSP: Login" />
      <Header />
      <PageBg />
      <PageWrapper>
        <LoginContainer
          logo={img}
          header={<Header />}
          title={isCompleted ? "THANK YOU" : "REGISTER"}
          description={
            isCompleted
              ? "Thank you for registration"
              : "Please fill out the information to create your account"
          }
        >
          {!isCompleted ? (
            <Wrapper>
              <NameBox>
                <NameGroup htmlFor="input">
                  First name<RequiredStar>*</RequiredStar>
                  <Input isChanged={(value) => setFirstname(value)} />
                </NameGroup>
                <NameGroup htmlFor="input">
                  Last name<RequiredStar>*</RequiredStar>
                  <Input isChanged={(value) => setLastname(value)} />
                </NameGroup>
              </NameBox>
              <InputGroup htmlFor="input">
                Email<RequiredStar>*</RequiredStar>
                <Input isChanged={(value) => setEmail(value)} />
              </InputGroup>
              <InputGroup htmlFor="input">
                Password<RequiredStar>*</RequiredStar>
                <Input
                  isChanged={(value) => setPassword(value)}
                  type="password"
                />
              </InputGroup>
              <InputGroup htmlFor="input">
                Repeat Password<RequiredStar>*</RequiredStar>
                <Input
                  isChanged={(value) => setRePassword(value)}
                  type="password"
                />
              </InputGroup>
              <TextBox>
                <CheckBox
                  type="checkbox"
                  name="Agree"
                  value="Agree"
                  onChange={() => setCheckTerms(!checkTerms)}
                />
                <Agreement>
                  <b>Agree</b> to our Terms &amp; Conditions
                </Agreement>
              </TextBox>

              <RegisterButton>
                <Button
                  bg="#fff"
                  color="#F02229"
                  borderColor="#F02229"
                  bold
                  isClicked={handleSubmit}
                >
                  REGISTER
                </Button>
              </RegisterButton>
            </Wrapper>
          ) : (
            <Wrapper2>
              <SimpleIcon>☺</SimpleIcon>
              <P>Thank you!</P>
              <P>
                Your registration has been successful, please check your email to activate your account.
              </P>
              <P>
                If you have not received the link check your junk/spam folders or click 'RESEND' below.
              </P>
              <NameBox>
                <Button isClicked={handleResend}>RESEND</Button>
                <Link to="/login">
                  <Button bg="#fff" color="#F02229" borderColor="#F02229" bold>
                    LOGIN
                  </Button>
                </Link>
              </NameBox>
            </Wrapper2>
          )}
        </LoginContainer>
      </PageWrapper>
      <Footer />
      {validationErrors !== "" && <ErrorMessage message={validationErrors} />}
      {resendEmail && (
        <NoticeMessage
          resetMsg={() => setResendEmail(false)}
          message="✉ Resend email"
        />
      )}
    </div>
  );
};

export default RegisterPage;
