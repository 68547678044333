import React from 'react';
import PageHelmet from '../../components/PageHelmet/index';
import WhiteHeader from '../../components/Header/WhiteHeader';
import Button from '../../components/UI/Button/Button';
import Card from '../../components/UI/Card/Card';
import CardContainer from '../../containers/CardContainer/CardContianer';
import { programCardData } from '../PageData/pageData';
import ProjectPartnersFooter from '../../components/Footer/ProjectPartnersFooter';
import Popup from '../../components/Popup/Popup';
import Cookies from 'universal-cookie';

import {
  InnerContainer,
  Container1,
  Container2,
  Container3,
  Container4,
  ProgramsContainer,
  LeftBox,
  LeftBox1,
  LeftBox4,
  RightBox,
  RightBox2,
  RightBox4,
  H2,
  H2Title,
  H2Title2,
  Strong,
  Text1Box,
  Text2,
  BtnBox3,
  P,
  Img,
  DynamicWrapper,
} from './styled';
// Images & logo
import programLogo from '../../assets/img/programs.svg';
import activitieLogo from '../../assets/img/activities.svg';
import storyLogo from '../../assets/img/stories.svg';
import trainingLogo from '../../assets/img/training.svg';
import womanImg from '../../assets/img/woman-looking-train@2x.72ad8af5-min.png';

const homepage = () => {
  const cookies = new Cookies('registered');

  const popupFooter = (
    <span>
      Click
      <a
        href='https://breathingspace.community/FFSP'
        style={{ color: '#02A4B7', fontWeight: '700' }}
        target='_blank'
        rel='noopener noreferrer'
      >
        &nbsp;here&nbsp;
      </a>
      to participate
    </span>
  );

  return (
    <div>
      <PageHelmet title='FFSP: Landing' />
      <WhiteHeader isHome />
      <Container1>
        <LeftBox1>
          <Text1Box>
            <H2Title>
              What are the
              <Strong> family and friends support</Strong> Programs?
            </H2Title>
            <br />
            <span>
              The Family and Friend Support Programs are a set of online
              interventions and support packages designed by experts to help
              families and friends supporting a loved one who may be using ice,
              alcohol and/or other drugs. They also offer information and
              support for people who may be experiencing domestic and family
              violence, as well as people who are living in rural or remote
              regions.
            </span>
            <br />
            <a
              href='#introduction'
              style={{ display: 'block', color: '#00A4B7', marginTop: '1rem' }}
            >
              Read more &#x2794;
            </a>
            <div style={{ margin: '2rem 0 0 0' }}>
              <Button color='#EE020A' borderColor='#EE020A' bold>
                <a href='/register' style={{ color: '#EE020A' }}>
                  GET STARTED &#x2794;
                </a>
              </Button>
            </div>
          </Text1Box>
        </LeftBox1>
      </Container1>
      <Container2 id='introduction'>
        <InnerContainer>
          <LeftBox>
            <H2 white>
              WHAT DO <Strong> THE FAMILY AND FRIEND SUPPORT PROGRAMS </Strong>{' '}
              OFFER?
            </H2>
            <Text2>
              A set of free online programs and access to trusted and reliable
              information to assist, families, friends and health professionals
              supporting loved ones or themselves.
            </Text2>
          </LeftBox>
          <RightBox>
            <Card logo={programLogo}>
              Online programs to help you manage your situation.
            </Card>
            <Card logo={activitieLogo}>Helpful activities</Card>
            <Card logo={storyLogo} top>
              Real stories from families and friends.
            </Card>
            <Card logo={trainingLogo}>
              Online resources and service information
            </Card>
          </RightBox>
        </InnerContainer>
      </Container2>
      <Container3>
        <InnerContainer style={{ position: 'relative', paddingBottom: '2em' }}>
          <LeftBox>
            <H2Title2>
              WHAT IS THE <Strong>FAMILY AND FRIEND SUPPORT</Strong> PROGRAM?
            </H2Title2>
            <P>
              Alcohol and drug use not only affects people using the alcohol or
              drugs, but it also has an adverse impact on their family, friends,
              and their community.
            </P>
            <P>
              Family and peer support networks are critical to a persons'
              recovery from alcohol/drug use, but too often, relationships
              become strained and users become isolated from their families,
              friends and social networks due to the problems arising from their
              alcohol or drug use.
            </P>
            <P>
              Families and friends can play a critical role in the recovery of
              people using alcohol and other drugs. However, there is little
              support for families in this situation.
            </P>
          </LeftBox>
          <RightBox2>
            <H2 style={{ margin: '0px 4.5em 0px 0px', visibility: 'hidden' }}>
              WHAT IS THE<Strong>FAMILY AND FRIEND SUPPORT</Strong> PROGRAM?
            </H2>
            <P>
              The Family and Friend Support Program is an online intervention
              and support package for families/friends supporting loved ones
              using alcohol/drugs. It includes information on how families and
              friends can best help their loved ones and protect them from
              adverse impacts of their lifestyle of drinking alcohol or using
              drugs. The Family and Friend Support Program recognises that
              supporting someone who is drinking or using can be extremely
              stressful, and aims to assist families and friends to best manage
              the demands of this role.
            </P>

            <P>
              If you are supporting someone who is using crystal
              methamphetamine, please refer to our ice specific program at{' '}
              <a href='http://ffsp.com.au' target='blank'>
                ffsp.com.au
              </a>
            </P>
          </RightBox2>
          <BtnBox3>
            <Button color='#EE020A' borderColor='#EE020A' bold>
              <a href='/register' style={{ color: '#EE020A' }}>
                GET STARTED &#x2794;
              </a>
            </Button>
          </BtnBox3>
        </InnerContainer>
      </Container3>
      <Container4>
        <InnerContainer>
          <LeftBox4>
            <Img alt='woman' src={womanImg} />
          </LeftBox4>
          <RightBox4>
            <H2Title2>
              Who is behind the
              <Strong> family and friend support</Strong> programs
            </H2Title2>
            <div>
              <P>
                These online programs were developed by researchers and
                clinicians, with the help of real families and friends who are
                supporting loved ones.
              </P>
            </div>
          </RightBox4>
        </InnerContainer>
      </Container4>

      <ProgramsContainer>
        <H2>
          For more information, or to sign up, click on any of the available
          <Strong>programs</Strong> below:
        </H2>
        <DynamicWrapper>
          <CardContainer cardData={programCardData} />
        </DynamicWrapper>
      </ProgramsContainer>
      <ProjectPartnersFooter />
    </div>
  );
};

export default homepage;
