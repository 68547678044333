import styled from "styled-components";
// Assests
import bg from "../../assets/img/console-home-bg.svg";

export const Wrapper = styled.div`
  background-color: #00c7dd;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 315px;
`;

export const Container = styled.div`
  margin: 75px auto 140px auto;
  width: 90%;
  max-width: 1280px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 230px);
`;

export const TextBox = styled.div`
  color: #fff;
  margin: 2rem 0 0 0;
  text-align: center;
  width: 50%;
  @media only screen and (max-width: 1023px) {
    width: 80%;
  }
`;

export const TextBox2 = styled(TextBox)`
  width: 40%;
  margin: 1rem 0 0 0;
  @media only screen and (max-width: 1023px) {
    width: 80%;
    margin-left: unset;
  }
`;

export const H2 = styled.h2`
  font-size: 40px;
  text-transform: uppercase;
  @media only screen and (max-width: 767px) {
    font-size: 36px;
  }
`;
export const H3 = styled.h3`
  font-size: 30px;
  text-transform: uppercase;
  @media only screen and (max-width: 767px) {
    font-size: 27px;
  }
`;
export const P = styled.p``;

export const ContentBox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 80%;
  max-height: 10rem;
  margin-top: 2rem;
  z-index: 1;
  &:hover {
    z-index: 2;
  }
  @media only screen and (max-width: 1023px) {
    grid-row-gap: 3rem;
    grid-template-columns: 50% 50%;
    padding-bottom: 12rem;
  }
  @media only screen and (max-width: 767px) {
    grid-row-gap: 0;
    padding-bottom: 16rem;
  }
`;

export const ContentBox2 = styled(ContentBox)`
  @media only screen and (max-width: 1023px) {
    padding-bottom: 20rem;
  }
  @media only screen and (max-width: 767px) {
    padding-bottom: 26rem;
  }
`;

export const ContentBox3 = styled(ContentBox)`
  margin-top: 4rem;
  @media only screen and (max-width: 1023px) {
    margin-top: 2rem;
  }
`;

export const PathContainer1 = styled.div`
  width: 64%;
  height: 8rem;
  margin-top: 2rem;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const Path = styled.div`
  box-sizing: content-box;
  border: dashed #fff;
  height: 100%;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const Path1 = styled(Path)`
  width: 50%;
  border-width: 3px 0 0 0;
`;

export const Path2 = styled(Path)`
  width: 50%;
  border-width: 3px 3px 3px 0;
  border-radius: 0 100px 100px 0;
`;

export const Path3 = styled(Path)`
  width: 50%;
  height: calc(100% - 6px);
  border-width: 3px 0 3px 3px;
  border-radius: 250px 0 0 250px;
  position: absolute;
  left: 0;
  top: 3px;
`;

export const Path4 = styled(Path)`
  width: 30%;
  border-width: 3px 3px 3px 0;
  border-radius: 0 140px 140px 0;
  position: absolute;
  right: 20%;
`;

export const Path5 = styled(Path)`
  width: 30%;
  border-width: 0 0 3px 0;
  position: absolute;
  left: 20%;
  bottom: -6px;
`;

export const Section2 = styled.div`
  width: 100%;
  position: relative;
`;

export const BoxInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  @media only screen and (max-width: 1023px) {
    width: 100%;
    margin-left: unset;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;

export const UserGuide = styled.a`
  position: absolute;
  top: 9rem;
  right: 1rem;
  background-color: #ffce78;
  color: #000;
  font-weight: bold;
  border: none;
  width: 14rem;
  height: 4rem;
  text-align: center;
  line-height: 3.6em;
  cursor: pointer;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    transform: scale(1.04);
  }
`;

export const Destination = styled.span`
  position: absolute;
  left: calc(20% - 1rem);
  bottom: -22px;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  font-size: 2rem;
  background-color: #fff;
  text-align: center;
  line-height: 2rem;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const ReturnButton = styled.a`
  color: #fff;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
