import React, { useState } from 'react';
import PageHelmet from '../../components/PageHelmet/index';
// Components
import Header from '../../components/Header/ConsoleHeader';
import Footer from '../../components/Footer/Footer';
import Card from '../../components/UI/Card/ModuleCard';
import {
  Wrapper,
  Container,
  TextBox,
  H2,
  H3,
  P,
  ContentBox,
  UserGuide,
  ReturnButton,
} from './styled';
import ShipLogo from '../../assets/img/ffsp-logo-icon.png';
// GraphQL
import SearchUserflow from '../../middleware/Userflow/searchUserflows';

const ConsolePage = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [useflowSteps, setUserflowSteps] = useState([]);

  let moduleList = [];
  if (useflowSteps.length > 0 && moduleList.length === 0)
    moduleList = useflowSteps
      .slice(11)
      .map((item) => <Card userFlowId={"modules"} key={item.id} item={item} />);

  const handleGetUserflow = (data, type) => {
    if (type === 'data') {
      const learnSteps = data[0].userFlowSteps.filter(
        (u) => u.contentGroup.type === 'LEARNING'
      );
      setUserflowSteps(
        learnSteps.map((item) => {
          return {
            ...item,
            title: item.stepName,
            text: item.description,
            isCurrent: false,
          };
        })
      );
    }
    setLoaded(true);
  };

  return (
    <>
      <PageHelmet title="FFSP: Console" />
      <Wrapper>
        <Header />
        <UserGuide href="/user-guide">USER GUIDE FOR FFSP</UserGuide>
        <Container>
          <TextBox>
            <H2>MINI-MODULES 4</H2>
            <H3>SEARCHING FOR CALMER SEAS</H3>
            <P>
              Welcome to the Mindfulness mini-module of the Family and Friend Support Program. 
              In this mini-module we will introduce you to the practice of mindfulness, 
              help you to understand how it can be helpful, 
              and guide you through a 4-week mindfulness program so that you can use this strategy to help you find 'calm' when you need it.
            </P>
          </TextBox>
          <ContentBox>{moduleList}</ContentBox>
          <ReturnButton href="/console/alcohol-or-other-drugs">
            <img alt="boat icon" src={ShipLogo} />
            <P>Click here to return to modules</P>
          </ReturnButton>
        </Container>
        <Footer />
      </Wrapper>
      {!loaded && (
        <SearchUserflow
          onComplete={handleGetUserflow}
          projectId={process.env.REACT_APP_PROJECT_ID}
        />
      )}
    </>
  );
};

export default ConsolePage;
