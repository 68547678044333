import React from "react";
import { useQuery } from "../../hooks/useQuery";
import gql from "graphql-tag";
import Error from "../QueryError";

export const GET_USERFLOW_STEP = gql`
  query getUserFlowStep($userFlowStepId: Int!) {
    userFlowStep {
      getUserFlowStep(userFlowStepId: $userFlowStepId) {
        id
        url
        isComplete
        repetitionAllowed
        userRepetition {
          id
          completed
        }
        lastViewedContentPage {
          id
          url
        }
        contentGroup {
          id
          contentPages {
            id
            url
            sort
          }
        }
      }
    }
  }
`;

const GetUserFlowStep = (props) => {
  const { onComplete, userFlowStepId } = props;
  const { error, data } = useQuery(GET_USERFLOW_STEP, {
    variables: {
      userFlowStepId,
    },
  });

  const handleError = (error) => {
    onComplete(error, "error");
  };

  if (error) {
    onComplete(error.message, "error");
    return <Error message={error.message} onError={handleError} />;
  }
  if (data && Object.entries(data).length !== 0) {
    onComplete(data.userFlowStep.getUserFlowStep, "data");
    return null;
  }
  return <></>;
};

export default GetUserFlowStep;
