import React from "react";
import GeneralPage, {
  Container,
} from "../../components/LayoutGeneralPage/index";
import { ContentWrapper } from "@netfront/ekardo-shared-library";
import "./styled.css";

const UserGuidePage = () => {
  return (
    <GeneralPage title="User Guide">
      <Container id="user-guide">
        <ContentWrapper pageId={619} mode={false} isPublic={true} />
      </Container>
    </GeneralPage>
  );
};

export default UserGuidePage;
