import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
// import Cookies from "universal-cookie";
import introspectionQueryResultData from "../fragmentTypes.json";
import { _GetAccessToken } from "@netfront/gelada-react-shared";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export function EkardoClient() {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_EKARDO_API_URL,
    Platform: "Web",
    fetch,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = _GetAccessToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ fragmentMatcher }),
  });
  return client;
}
