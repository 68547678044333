import {
  AccordionData,
  ContentSnippetProps,
  IToolbox,
  UserFlowsProps,
} from "./Interfaces";

export const contentSnippetUserFlowStepNameFilter = (
  contentSnippet: ContentSnippetProps,
  filterByUserFlowStepName: string
) => {
  const {
    contentPage: {
      userFlowStep: { stepName: userFlowStepName },
    },
  } = contentSnippet;

  return filterByUserFlowStepName === userFlowStepName;
};

export const convertBytesToMegabytes = (number: number, decimalPlaces = 2): string => {
  const BYTES_IN_KILOBYTE = 1024;

  const megabytes = number / (BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE);

  return `${megabytes.toFixed(decimalPlaces)} MB`;
};

export const formatNumberWithThousandsSeparators = (number: number, decimalPlaces = 2): string => {
  const numberParts = number.toFixed(decimalPlaces).toString().split(".");
  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numberParts.join(".");
};

export const getContentSnippetsWithUniqueAssets = (
  contentSnippets: ContentSnippetProps[]
): ContentSnippetProps[] => {
  const assetIds = contentSnippets.map(
    (contentSnippet: ContentSnippetProps) => contentSnippet.asset.assetId
  );
  return Array.from(new Set(assetIds)).map((assetId) =>
    contentSnippets.find((contentSnippet) => contentSnippet.asset.assetId === assetId)
  );
};

export const getUniqueUserFlowStepNames = (contentSnippets: ContentSnippetProps[]): string[] => {
  const userFlowStepNames = contentSnippets.map((contentSnippet: ContentSnippetProps) => {
    const {
      contentPage: { userFlowStep },
    } = contentSnippet;

    return userFlowStep.stepName;
  });

  return Array.from(new Set<string>(userFlowStepNames));
};

export function createAccordionData(toolboxesData: IToolbox[], userFlows: UserFlowsProps[]) {
  if (!toolboxesData.length) return;
  const accordionData: AccordionData[] = [];
  const accordionChildrenData: AccordionData[] = [];

  const modules = toolboxesData.filter((t) => t.userFlowUrl === "alcohol-or-other-drugs");
  const moduleGroupUrls = modules.map((module) => module.contentGroupUrl);
  const uniqueModuleGroupUrls = Array.from(new Set(moduleGroupUrls));
  uniqueModuleGroupUrls.forEach((contentGroupUrl) => {
    accordionChildrenData.push({
      title: contentGroupUrl,
      pages: toolboxesData.filter((t) => t.contentGroupUrl === contentGroupUrl),
    });
  });
  accordionData.push({
    title: "Alcohol and/or other drugs",
    children: accordionChildrenData,
  });
  const moduleList = userFlows.filter((flow) => flow.url !== "alcohol-or-other-drugs").map(flow => flow.url);
  moduleList.forEach((userFlowUrl) => {
    const module = toolboxesData.find((t) => t.userFlowUrl === userFlowUrl);
    if (!module) return;
    accordionData.push({
      title: module.userFlowStepName ?? "Group " + userFlowUrl,
      pages: toolboxesData.filter((t) => t.userFlowUrl === userFlowUrl),
    });
  });
  return accordionData;
}
