import React from "react";
import { useQuery } from "../../hooks/useQuery";
import gql from "graphql-tag";
import Error from "../QueryError";

export const GET_PAGE = gql`
  query getPage($projectId: String!, $contentPageUrl: String!) {
    contentPage {
      getContentPagePerName(
        projectId: $projectId
        contentPageUrl: $contentPageUrl
      ) {
        id
        title
        contentGroup {
          id
          title
        }
        isBookmarked
      }
    }
  }
`;

const GetPageByUrl = (props) => {
  const { onComplete, projectId, contentPageUrl } = props;
  const { error, data } = useQuery(GET_PAGE, {
    variables: {
      projectId,
      contentPageUrl,
    },
  });

  const handleError = (error) => {
    onComplete(error, "error");
  };
  
  if (error) {
    onComplete(error.message, "error");
    return <Error message={error.message} onError={handleError} />;
  }
  if (data && Object.entries(data).length !== 0) {
    onComplete(data.contentPage.getContentPagePerName, "data");
    return null;
  }
  return <></>;
};

// getUserFlowStepTrack.propTypes = {
//   userFlowStepId: PropTypes.number.isRequired,
//   onComplete: PropTypes.func.isRequired,
// };

export default GetPageByUrl;
