import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { ForgotPassword_Mutation } from "@netfront/gelada-react-shared";
import GeladaClient from "../../middleware/GeladaClient";
import PageHelmet from "../../components/PageHelmet/index";
import img from "../../assets/img/login.svg";
import bgImg from "../../assets/img/login-bg.svg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/UI/Button/Button";
import LoginContainer from "../../containers/LoginContainer/LoginContainer";
import ErrorMessage from "../../components/Message/ErrorMessage";
import { withRouter } from "react-router";

// **************styled Components********************
const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5.5rem;
  height: calc(100vh - 8.9rem);
  max-width: 1500px;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    height: fit-content;
    padding: 15rem 0 5rem 0;
    background: transparent url(${bgImg}) 0% 0% no-repeat padding-box;
    background-size: cover;
  }
  @media only screen and (min-width: 1024px) and (max-height: 800px) {
    transform: scale(0.6);
  }
`;

const PageBg = styled.div`
  position: absolute;
  width: 50%;
  height: calc(100vh);
  background: transparent url(${bgImg}) 0% 0% no-repeat padding-box;
  background-size: cover;
  z-index: -1;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const Form = styled.form`
  width: 80%;
`;

const InputGroup = styled.label`
  display: block;
  text-align: left;
`;

const LoginButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const RequiredStar = styled.span`
  color: red;
`;

const MsgBox = styled.div`
  padding: 2rem;
`;

// **************styled Components********************

const title = "FORGET PASSWORD";
const description = "Please enter your email address";
const message =
  "An email has been sent with a reset password link to your nominated email. To finalise your password reset click the link within the email and follow the prompts to complete the password reset process.";

const ForgetPasswordPage = (props) => {
  const [email, setEmail] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [validationErrors, setValidationErrors] = useState("");

  const [callMutation] = useMutation(
    ForgotPassword_Mutation,
    {
      context: { clientName: "auth" },
      client: GeladaClient(),
      onCompleted: () => {
        setSuccessMsg(message);
        // props.history.push("/forgot-password-success");
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    callMutation({ variables: { email } }).catch((err) => {
      setValidationErrors(err.message.replace("GraphQL error: ", ""));
    });
  };

  return (
    <div style={{ minWidth: "300px" }}>
      <PageHelmet title="FFSP: Login" />
      <Header />
      <PageBg />
      <PageWrapper>
        <LoginContainer logo={img} title={title} description={description}>
          {successMsg === "" ? (
            <Form onSubmit={handleSubmit}>
              <InputGroup htmlFor="input">
                Email address<RequiredStar>*</RequiredStar>
                <Input isChanged={(value) => setEmail(value)} />
              </InputGroup>
              <LoginButton>
                <Button
                  bg="#fff"
                  color="#F02229"
                  borderColor="#F02229"
                  bold
                  type="submit"
                >
                  GET RESET LINK
                </Button>
              </LoginButton>
            </Form>
          ) : (
            <MsgBox>
              An email has been sent with a reset password link to your
              nominated email. To finalise your password reset click the link
              within the email and follow the prompts to complete the password
              reset process.
            </MsgBox>
          )}
        </LoginContainer>
      </PageWrapper>
      <Footer />
      {validationErrors !== "" && <ErrorMessage message={validationErrors} />}
    </div>
  );
};

export default withRouter(ForgetPasswordPage);
