import gql from 'graphql-tag';

export const GET_TOOLBOX_DATA = gql`
  query getContentSnippetsForToolbox(
    $includeApps: Boolean = false
    $includeAudios: Boolean = false
    $includeContentPage: Boolean = false
    $includeDocuments: Boolean = false
    $includeEmbeds: Boolean = false
    $includeForms: Boolean = false
    $includeUserFlowStep: Boolean = false
    $includeVideos: Boolean = false,
    $userFlowIds: [Int]!
  ) {
    contentSnippet {
      getContentSnippetsForToolbox(
        projectId: "${process.env.REACT_APP_PROJECT_ID}",
        userFlowIds: $userFlowIds) {
        ... on ContentSnippetAppType @include(if: $includeApps) {
          app {
            title
          }
          contentPage @include(if: $includeContentPage) {
            ...contentPageFragment
          }
          __typename
        }
        ... on ContentSnippetAudioType @include(if: $includeAudios) {
          asset {
            ...assetFragment
          }
          contentPage @include(if: $includeContentPage) {
            ...contentPageFragment
          }
          __typename
        }
        ... on ContentSnippetDocumentType @include(if: $includeDocuments) {
          asset {
            ...assetFragment
          }
          contentPage @include(if: $includeContentPage) {
            ...contentPageFragment
          }
          __typename
        }
        ... on ContentSnippetEmbedType @include(if: $includeEmbeds) {
          code
          contentPage @include(if: $includeContentPage) {
            ...contentPageFragment
          }
          title
          __typename
        }
        ... on ContentSnippetFormType @include(if: $includeForms) {
          contentPage @include(if: $includeContentPage) {
            ...contentPageFragment
          }
          form {
            title
          }
        }
        ... on ContentSnippetVideoType @include(if: $includeVideos) {
          asset {
            ...assetFragment
          }
          contentPage @include(if: $includeContentPage) {
            ...contentPageFragment
          }
          __typename
        }
      }
    }
  }

  fragment assetFragment on AssetType {
    assetId
    contentType
    fileName
    fileSizeInBytes
    s3Key
  }

  fragment contentPageFragment on ContentPageType {
    id
    title
    url
    userFlowStep @include(if: $includeUserFlowStep) {
      ...userFlowStepFragment
    }
  }

  fragment contentGroupFragment on ContentGroupType {
    id
    url
    parentId
    title
    iconAsset {
      contentType
      s3Key
      __typename
    }
  }

  fragment userFlowStepFragment on UserFlowStepType {
    id
    userFlowId
    stepName
    url
    contentGroup {
      ...contentGroupFragment
    }
  }
`;
