import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import StarIcon from '../../assets/img/toolbox/star.png';
import client from '../../middleware/client';
import Error from '../../middleware/QueryError';
import { GET_TOOLBOX_DATA } from '../../middleware/Toolbox/getToolboxData';
import {
  BookmarkIcon, Container, NoBookmarkDesciption, NoBookmarkTitle, NoBookmarkWrapper, Wrapper
} from '../Bookmark/styled';
import Spinner from '../UI/Loader/Preloader';
import {
  CONTENT_SNIPPET_TYPE_APP,
  CONTENT_SNIPPET_TYPE_AUDIO,
  CONTENT_SNIPPET_TYPE_DOCUMENT,
  CONTENT_SNIPPET_TYPE_EMBED,
  CONTENT_SNIPPET_TYPE_FORM,
  CONTENT_SNIPPET_TYPE_VIDEO
} from './Common/ContentSnippetConstants';
import { ContentSnippetProps, UserFlowsProps } from './Common/Interfaces';
import ActivityItems from './ToolBoxContentTypes/ActivityItems';
import AudioItems from './ToolBoxContentTypes/AudioItems';
import DocumentItems from './ToolBoxContentTypes/DocumentItems';
import VideoItems from './ToolBoxContentTypes/VideoItems';

export const USER_FLOWS: UserFlowsProps[] = [
  { id: 9, url: "alcohol-or-other-drugs", type: 'aod' },
  { id: 19, url: "domestic-violence", type: 'domestic' },
  { id: 20, url: "rural-and-remote", type: 'rural' }
]

type ToolBoxContentType = 'ACTIVITIES' | 'AUDIO' | 'FILES' | 'FORMS' | 'VIDEOS';

interface ToolBoxContentProps {
  type: ToolBoxContentType;
}

const ToolBoxContentTypeContentSnippetTypeMap = {
  ACTIVITIES: [CONTENT_SNIPPET_TYPE_APP],
  AUDIO: [CONTENT_SNIPPET_TYPE_AUDIO],
  FILES: [CONTENT_SNIPPET_TYPE_DOCUMENT],
  FORMS: [CONTENT_SNIPPET_TYPE_FORM],
  VIDEOS: [CONTENT_SNIPPET_TYPE_EMBED, CONTENT_SNIPPET_TYPE_VIDEO],
};


const ToolBoxContent = (props: ToolBoxContentProps) => {
  const { type } = props;

  const contentSnippetTypes = ToolBoxContentTypeContentSnippetTypeMap[type];

  const { data, error, loading } = useQuery(GET_TOOLBOX_DATA, {
    client,
    variables: {
      includeApps: contentSnippetTypes.includes(CONTENT_SNIPPET_TYPE_APP),
      includeAudios: contentSnippetTypes.includes(CONTENT_SNIPPET_TYPE_AUDIO),
      includeContentPage: true,
      includeDocuments: contentSnippetTypes.includes(CONTENT_SNIPPET_TYPE_DOCUMENT),
      includeEmbeds: contentSnippetTypes.includes(CONTENT_SNIPPET_TYPE_EMBED) || contentSnippetTypes.includes(CONTENT_SNIPPET_TYPE_VIDEO),
      includeForms: contentSnippetTypes.includes(CONTENT_SNIPPET_TYPE_FORM),
      includeUserFlowStep: true,
      includeVideos: contentSnippetTypes.includes(CONTENT_SNIPPET_TYPE_VIDEO),
      userFlowIds: USER_FLOWS.map(flow => flow.id)
    },
  });


  if (loading) {
    return <Spinner />;
  }

  if (error) {
    const errMsg = error.message.substring(error.message.indexOf(':') + 1, error.message.length);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return <Error message={errMsg} onError={() => {}} />;
  }

  if (!data) {
    return;
  }

  const contentSnippetFilter = (contentSnippet, typeName) => {
    return Object.keys(contentSnippet).length > 1 && contentSnippet.__typename.toUpperCase() === typeName.toUpperCase();
  };

  const { getContentSnippetsForToolbox } = data.contentSnippet;

  const contentSnippets = getContentSnippetsForToolbox as ContentSnippetProps[];

  const appContentSnippets = contentSnippets.filter((contentSnippet) => contentSnippetFilter(contentSnippet, CONTENT_SNIPPET_TYPE_APP));
  const audioContentSnippets = contentSnippets.filter((contentSnippet) => contentSnippetFilter(contentSnippet, CONTENT_SNIPPET_TYPE_AUDIO));
  const documentContentSnippets = contentSnippets.filter((contentSnippet) => contentSnippetFilter(contentSnippet, CONTENT_SNIPPET_TYPE_DOCUMENT));
  const embedContentSnippets = contentSnippets.filter((contentSnippet) => contentSnippetFilter(contentSnippet, CONTENT_SNIPPET_TYPE_EMBED));
  const formContentSnippets = contentSnippets.filter((contentSnippet) => contentSnippetFilter(contentSnippet, CONTENT_SNIPPET_TYPE_FORM));
  const videoContentSnippets = contentSnippets.filter((contentSnippet) => contentSnippetFilter(contentSnippet, CONTENT_SNIPPET_TYPE_VIDEO));

  const allContentSnippets = [
    ...appContentSnippets,
    ...audioContentSnippets,
    ...documentContentSnippets,
    ...embedContentSnippets,
    ...formContentSnippets,
    ...videoContentSnippets,
  ];

  return (
    <Wrapper>
      <Container>
        <ActivityItems contentSnippets={appContentSnippets} userFlows={USER_FLOWS}/>
        <AudioItems contentSnippets={audioContentSnippets} userFlows={USER_FLOWS} />
        <DocumentItems contentSnippets={documentContentSnippets} userFlows={USER_FLOWS} />
        <VideoItems
          embedContentSnippets={embedContentSnippets}
          videoContentSnippets={videoContentSnippets}
          userFlows={USER_FLOWS}
        />
        {!allContentSnippets.length && (
          <NoBookmarkWrapper>
            <BookmarkIcon alt="Star icon" src={StarIcon} />
            <NoBookmarkTitle>Complete your first module to view resources.</NoBookmarkTitle>
            <NoBookmarkDesciption>
              When you complete a module, you will be able to find all resources from that module in
              your Toolbox.
            </NoBookmarkDesciption>
          </NoBookmarkWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default ToolBoxContent;
