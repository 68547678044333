// Libraries
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
// Components

import { useMutation } from "../../hooks/useMutation";
import { useQueryString } from "../../hooks/useQueryString";
import { useCheckUserAuthorizationByJwt } from "../../hooks/useCheckUserAuthorizationByJwt";

import {
  ActivateAccount_Mutation,
  Client,
  _StoreTokens,
  _Store,
  _eStorageKey,
  _GetAccessToken,
} from "@netfront/gelada-react-shared";
import { useHandleLoadingState } from "../../context/LoadingContext";

const RegisterPage = ({ history }) => {
  const [
    activateUser,
    { called, loading },
  ] = useMutation(ActivateAccount_Mutation, { client: Client(false) });
  const { token } = useQueryString();

  const sendQuery = useCheckUserAuthorizationByJwt({
    pollInterval: 1500,
    onComplete() {
      history.push(
        "/content/alcohol-or-other-drugs/family-member-questionnaire/successful-registration"
      );
    },
  });

  useEffect(() => {
    if (!called && !loading && token) {
      activateUser({ variables: { activationCode: token } })
        .then((c) => {
          _StoreTokens(
            c.data.activateAccount.token,
            c.data.activateAccount.refreshToken
          );
          _Store(c.data.activateAccount.user, _eStorageKey.userData);
          sendQuery({
            variables: {
              claimType: _eStorageKey.userData,
              token: _GetAccessToken(),
            },
          });
        })
        .catch(() => {
          history.push("/dashboard");
        });
    }
  }, [token, activateUser, called, history, loading, sendQuery]);

  useHandleLoadingState(true);

  return <></>;
};

export default withRouter(RegisterPage);
