import React, { useState } from "react";
import styled from "styled-components";
// graphQL
import { AddBookmark, RemoveBookmark } from "@netfront/ekardo-shared-library";
// Asset
import BookmarkActive from "../../../assets/img/toolkit/Bookmark.svg";
import Bookmark from "../../../assets/img/toolkit/bookmark-empty.svg";
import PrintIcon from "../../../assets/img/toolkit/Print.svg";
import YellowBookIcon from "../../../assets/img/toolkit/YellowBook.svg";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  cursor: pointer;

  @media print {
    display: none !important;
  }
`;

const IconBox = styled.div`
  border-radius: 50%;
`;

const Icon = styled.img``;

const SideToolkit = (props) => {
  const { pageId, isBookmarked, setBookmarkState } = props;
  const [isAddBK, setIsAddBK] = useState(false);
  const [isRemoveBK, setIsRemoveBK] = useState(false);

  const handleClickBookmark = () => {
    setBookmarkState(!isBookmarked);
    if (isBookmarked) {
      setIsRemoveBK(true);
    } else {
      setIsAddBK(true);
    }
  };

  const handlePrint = () => {
    // We need style the print page
    window.print();
  };

  const handleAddBK = (data, type) => {
    if (type === "data") {
    }
    setIsAddBK(false);
  };

  const handleRemoveBK = (data, type) => {
    if (type === "data") {
    }
    setIsRemoveBK(false);
  };

  return (
    <>
      <Wrapper>
        <IconBox onClick={handleClickBookmark}>
          <Icon src={isBookmarked ? BookmarkActive : Bookmark} alt="icon" />
        </IconBox>
        <IconBox onClick={handlePrint}>
          <Icon src={PrintIcon} alt="icon" />
        </IconBox>
        <IconBox>
          <a href="/toolbox">
            <Icon src={YellowBookIcon} alt="icon" />
          </a>
        </IconBox>
      </Wrapper>
      {isAddBK && (
        <AddBookmark contentPageId={pageId} onComplete={handleAddBK} />
      )}
      {isRemoveBK && (
        <RemoveBookmark contentPageId={pageId} onComplete={handleRemoveBK} />
      )}
    </>
  );
};

export default SideToolkit;
