import React from "react";
import styled from "styled-components";

const Input = styled.input`
  display: block;
  width: calc(100% - 32px);
  background: #eceded;
  line-height: 1rem;
  border: 2px solid transparent;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 1em;
  font-size: 1em;
  &:focus {
    outline: none;
    background: #fff;
    border: 2px solid lightgray;
  }
  @media (max-width: 767px) {
    width: calc(100% - 20px);
    padding: 0.5em;
  }
`;

const input = props => {
  const { isChanged, type } = props;
  const handleChange = e => {
    isChanged(e.target.value);
  };
  return <Input onChange={e => handleChange(e)} type={type || "text"} />;
};

export default input;
