import styled from "styled-components";
// assets
import SeaBg from "../../assets/img/SeaBg.svg";
import SeaBgAOD from "../../assets/img/SeaBgAOD.svg";
import SeaBgDomestic from "../../assets/img/SeaBgDomestic.svg";
import SeaBgRural from "../../assets/img/SeaBgRural.svg";

const bgImages = {
  aod: SeaBgAOD,
  domestic: SeaBgDomestic,
  rural: SeaBgRural,
};

const getBgType = (userFlowId) => {
  if (userFlowId.includes("domestic")) return bgImages.domestic;
  else if (userFlowId.includes("rural")) return bgImages.rural;
  else return bgImages.aod;
};

export const Wrapper = styled.div`
  width: 100%;
  background: ${(props) =>
    `url(${props.bgType ? getBgType(props.bgType) : SeaBg}) no-repeat fixed bottom`};
  background-size: cover;
  min-height: 100vh;

  @media print {
    background: none;
  }
`;

export const ToolkitBox = styled.div`
  position: absolute;
  right: -5em;
  top: 8em;
`;

export const Container = styled.div`
  margin: 80px auto 0 auto;
  width: ${(props) => (props.isShowNav ? `calc(90% - 19rem)` : "95%")};
  max-width: 75rem;
  display: flex;
  flex-flow: column;
  align-items: inherit;
  position: relative;
  left: ${(props) => (props.isShowNav ? `calc(5% + 3.5rem)` : "0")};
  min-height: calc(100vh - 225px);
  padding-top: 2em;
  @media (min-width: 1600px) {
    width: ${(props) => (props.isShowNav ? "calc(90% - 22rem)" : "90%")};
    left: ${(props) => (props.isShowNav ? `calc(5% + 3rem)` : "0")};
  }

  @media print {
    left: 0 !important;
    margin: 0 !important;
    padding-top: 0 !important;
    width: 100% !important;
  }
`;

export const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 4em;

  @media print {
    display: none !important;
  }
`;

export const SaveLink = styled.a`
  color: #fff;
`;

export const BarContainer = styled.div`
  width: 100%;
  height: 1em;
  position: relative;
  left: 0;
  background-color: #e8e8ed;
  opacity: 0.7;
`;

export const ProcessBar = styled.div`
  background-color: #07c734;
  width: ${(props) => (props.percent ? `${props.percent * 100}%` : "0")};
  height: 1em;
  text-align: center;
`;

export const ProgressIndicator = styled.p`
  position: absolute;
  right: 1rem;
  top: 0;
  margin: 0;
  font-weight: 800;
  color: rgb(55, 60, 91);
`;

export const ContentContainer = styled.div`
  min-height: 95vh;
  position: relative;
`;

export const FooterWrapper = styled.div`
  @media print {
    display: none !important;
  }
`;
