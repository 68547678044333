import React from 'react';
import styled from 'styled-components';
import { _GetAccessToken } from '@netfront/gelada-react-shared';

const Container = styled.div`
  background-color: transparent;
  height: 10rem;
  margin-top: 5.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
`;

const Nav = styled.nav`
  background-color: transparent;
  color: #fff;
  width: 100%;
`;

const NavContainer = styled.div`
  padding: 1rem;
  max-width: ${(props) => (props.size === 'sm' ? '750px' : '70rem')};
  margin: 0 auto;
  text-align: right;
`;

const Link = styled.a`
  color: #fff;

  &:last-of-type {
    font-weight: 600;
  }
`;

const SecondaryHeader = (props) => {
  const { title, parentTitle, isPublic, size } = props;
  return (
    <div>
      <Container>
        <Title>{title}</Title>
      </Container>
      {isPublic ? (
        <Nav>
          <NavContainer>
            <Link href={_GetAccessToken() ? '/dashboard' : '/'}>Home &gt;</Link>{' '}
            {title}
          </NavContainer>
        </Nav>
      ) : (
        <Nav>
          <NavContainer size={size}>
            <Link href="/dashboard">Dashboard &gt;</Link>{' '}
            {parentTitle && (
              <Link
                href={`/${parentTitle.toLowerCase()}`}
              >{`${parentTitle} > `}</Link>
            )}
            <Link
              href={
                parentTitle
                  ? `/${parentTitle.toLowerCase()}/${title}`
                  : `/${title}`
              }
            >
              {' '}
              {title}
            </Link>
          </NavContainer>
        </Nav>
      )}
    </div>
  );
};

export default SecondaryHeader;
