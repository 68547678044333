import React, { useState } from 'react';
import styled from 'styled-components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';

import { ResSection } from '../../Bookmark/styled';
import { OtherResourcesData } from './const';
import './style.css';

const SectionTitle = styled.h3`
  margin: 1rem;
`;

const Link = styled.a`
  color: #00a4b7;
`;

const Container = styled.div`
  width: 100%;
`;

const ResourceList = (props) => {
  const { title, data } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <SectionTitle>{data.resTitle}</SectionTitle>
        <List component="div" disablePadding>
          {data.resources.length > 0 &&
            data.resources.map((item, index) => (
              <ListItem
                key={`${item.id}-${index}`}
                button
                style={{ paddingLeft: '1rem' }}
              >
                <Container>
                  <ListItemText primary={item.description} />
                  <Link href={item.link} target="_blank">
                    {item.link}
                  </Link>
                </Container>
                <ListItemAvatar>
                  <Avatar
                    alt="resource-logo"
                    variant="square"
                    src={item.logo}
                  />
                </ListItemAvatar>
              </ListItem>
            ))}
        </List>
        <SectionTitle>Phone support</SectionTitle>
        <List component="div" disablePadding>
          {data.support.length > 0 &&
            data.support.map((item, index) => (
              <ListItem
                key={`${item.id}-${index}`}
                button
                style={{ paddingLeft: '1rem' }}
              >
                <Container>
                  <p>
                    <b>{item.title}: </b>
                    <Link href={`tel: ${item.phone}`}>{item.phone}</Link> 
                  </p>
                  <ListItemText primary={item.description} />
                </Container>

                <ListItemAvatar>
                  <Avatar alt="support-logo" variant="square" src={item.logo} />
                </ListItemAvatar>
              </ListItem>
            ))}
        </List>
      </Collapse>
    </List>
  );
};

const OtherResources = () => {
  return (
    <>
      {OtherResourcesData.map((res) => (
        <ResSection key={res.id}>
          <ResourceList title={res.id} data={res} />
        </ResSection>
      ))}
    </>
  );
};

export default OtherResources;
