import React, { useState } from "react";
import PageHelmet from "../../components/PageHelmet";
import { Container, Wrapper } from "../ConsolePage/styled";
import Header from "../../components/Header/ConsoleHeader";
import SearchUserflow from "../../middleware/Userflow/searchUserflows";
import UserFlowCard from "../../components/UI/Card/UserFlowCard";
import Footer from "../../components/Footer/Footer";
import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  padding: 0;
  margin-right: -1.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 0;
  margin-top: 6rem;
  max-width: 900px;
`;

const CardColumn = styled.div`
  box-sizing: border-box;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  margin: 0;
  list-style-type: inherit;
  flex-basis: auto;
  width: 100%;

  @media only screen and (min-width: 64em) and (min-height: 36em), print {
    width: 50%;
  }
`;

const userflowMeta = [
  {
    id: 9,
    description:
      "<p>Program for people supporting someone who is using <span>alcohol and/or other drugs</span>.</p>",
    icon: "/images/aod-logo.svg",
    theme: "blue",
  },
  {
    id: 19,
    description:
      "<p>Program for people who are experiencing <span>domestic and family violence</span>.</p>",
    icon: "/images/domestic-violence-logo.svg",
    theme: "purple",
  },
  {
    id: 20,
    description: "<p>Program for people who live in <span>rural or remote regions</span>.</p>",
    icon: "/images/rural-logo.svg",
    theme: "burgandy",
  },
];

const Button = styled.button`
  background-color: #fff;
  border: 0.15rem solid var(--${(props) => props.theme});
  border-radius: 30px;
  color: var(--${(props) => props.theme});
  cursor: pointer;
  font-family: Apercu;
  font-size: 1rem;
  outline: none;
  padding: 0.6rem 2.2rem;
  text-transform: uppercase;
`;

const Description = styled.div`
  font-family: Apercu;
  margin: 1rem auto;

  span {
    color: var(--${(props) => props.theme});
  }
`;

const SectionSelectionPage = () => {
  const [loaded, setLoaded] = useState(false);
  const [userFlows, setUserFlows] = useState([]);

  const handleGetUserflow = (data) => {
    setLoaded(true);
    setUserFlows(data);
  };

  const getUserflowMeta = (id) => userflowMeta.filter((u) => u.id === id)[0];

  return (
    <>
      <PageHelmet title="FFSP: Console" />
      <Wrapper>
        <Header />
        <Container>
          <CardContainer>
            {userFlows.map((userFlow) => {
              const { url } = userFlow;
              const { description, icon, theme } = getUserflowMeta(userFlow.id);

              const hasSingleModule = userFlow.userFlowSteps.length === 1;

              let redirectURL = `/console/${url}`;

              if (hasSingleModule) {
                const module = userFlow.userFlowSteps[0];
                const lastViewed =
                  module.lastViewedContentPage !== null
                    ? module.lastViewedContentPage.url
                    : module.contentGroup.contentPages[0].url;

                redirectURL = `/content/${url}/${module.url}/${lastViewed}`;
              }

              return (
                <CardColumn>
                  <UserFlowCard userFlowId={userFlow.url} link={redirectURL}>
                    <img src={icon} alt={"user-flow-icon"} />
                    <Description dangerouslySetInnerHTML={{ __html: description }} theme={theme} />
                    <Button theme={theme}>Start</Button>
                  </UserFlowCard>
                </CardColumn>
              );
            })}
            <CardColumn>
              <UserFlowCard externalLink="https://ice.ffsp.com.au/">
                <img src="/images/ffsp-logo.svg" alt={"ffsp-logo"} />
                <Description theme="pink">
                  <p>
                    Program for people supporting someone who is using <span>ice</span>
                  </p>
                </Description>
                <Button theme="pink">VISIT</Button>
              </UserFlowCard>
            </CardColumn>
          </CardContainer>
        </Container>
        <Footer />
      </Wrapper>
      {!loaded && (
        <SearchUserflow
          onComplete={handleGetUserflow}
          projectId={process.env.REACT_APP_PROJECT_ID}
        />
      )}
    </>
  );
};

export default SectionSelectionPage;
