import React from "react";
import { useQuery } from "../../hooks/useQuery";
import gql from "graphql-tag";
import Error from "../QueryError";

export const GET_BOOKMARK = gql`
  query GetToolboxData($userFlowId: Int!) {
    userFlow {
      getUserFlow(userFlowId: $userFlowId) {
        userFlowSteps {
          id
          url
          contentGroup {
            id
            title
            contentPages(status: [PUBLISHED, UNPUBLISHED]) {
              bookmark {
                contentPage {
                  title
                  url
                }
                contentPageId
              }
            }
          }
        }
      }
    }
  }
`;

const GetBooksmarksPerGroup = (props) => {
  const { onComplete, userFlowId } = props;
  const { error, data } = useQuery(GET_BOOKMARK, {
    variables: {
      userFlowId,
    },
  });

  const handleError = (error) => {
    onComplete(error, "error");
  };

  if (error) {
    onComplete(error.message, "error");
    return <Error message={error.message} onError={handleError} />;
  }
  if (data && Object.entries(data).length !== 0) {
    onComplete(data.userFlow.getUserFlow.userFlowSteps, "data");
    return null;
  }
  return <></>;
};

export default GetBooksmarksPerGroup;
