import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const useProjectCMSClasses = ({ projectId }) => {
  const GET_STYLES_QUERY = gql`
    query GET_STYLES_QUERY($projectId: String!) {
      cssStyle {
        getPerProject(projectId: $projectId) {
          class
          mediaQueryProperties
          webProperties
        }
      }
    }
  `;

  // @todo - Update in ts
  const { data: { cssStyle: { getPerProject } = {} } = {}, error } = useQuery(GET_STYLES_QUERY, {
    variables: {
      projectId,
    },
  });

  const formatCSSClass = (className, properties) => `.${className} { ${properties} } `;

  const buildCSSTemplate = () => {
    if (!getPerProject) return;

    let classes = "";
    let mediaQueries = "";

    getPerProject.forEach((item) => {
      if (item.webProperties !== null) {
        classes += formatCSSClass(item.class, item.webProperties);
      }

      if (item.mediaQueryProperties !== null) {
        mediaQueries += formatCSSClass(item.class, item.mediaQueryProperties);
      }
    });

    return `
      ${classes}
      ${mediaQueries}
    `;
  };

  const projectClasses = buildCSSTemplate();

  return {
    projectClasses,
    error,
  };
};

export { useProjectCMSClasses };
