import React from "react";
import styled from "styled-components";
import ProgramCard from "../../components/UI/Card/ProgramCard";

const CardWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  height: auto;
  @media (max-width: 1027px) {
    margin-top: 5rem;
  }
`;

const ProgramCardContainer = ({ cardData, themeColor }) => {
  return (
    <CardWrapper>
      {cardData.map(({ logo, info, logoSize, id }) => (
        <ProgramCard
          key={id}
          logo={logo}
          logoSize={logoSize}
          info={info}
          themeColor={themeColor}
        />
      ))}
    </CardWrapper>
  );
};

export default ProgramCardContainer;
