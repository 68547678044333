// import { bucket, signedUrlTimeout } from '../../config';
import s3 from './s3';

export const getSignedUrl = (key, contentType) =>
  s3.getSignedUrl('getObject', {
    Bucket: process.env.REACT_APP_BUCKET,
    Key: key,
    Expires: Number(100),
    ResponseContentType: contentType,
  });
