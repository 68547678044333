import React from "react";
// Components
import PageHelmet from "../../components/PageHelmet/index";
import Header from "../../components/Header/ConsoleHeader";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import ToolBoxContent from "../../components/ToolBoxContent/ToolBoxContent";
import ResourceContent from "../../components/Toolbox/ResourceContent";
import Footer from "../../components/Footer/Footer";
import { Wrapper } from "../ConsolePage/styled";

const ContentPage = (props) => {
  const { match } = props;
  const type = match.params.contentType.charAt(0).toUpperCase() + match.params.contentType.slice(1);

  return (
    <Wrapper>
      <PageHelmet title={`FFSP: ${type}`} />
      <Header />
      <SecondaryHeader title={type} parentTitle="Toolbox" />
      {type === "Resources" ? <ResourceContent /> : <ToolBoxContent type={type.toUpperCase()} />}
      {/* {type === 'Resources' ? <ResourceContent /> : <Content type={type} />} */}
      <Footer />
    </Wrapper>
  );
};

export default ContentPage;
