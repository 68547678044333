import { useEffect } from "react";
import ReactGA from "react-ga";

export const initGA = (trackingID, gaOptions) => {
  // Global site tag (gtag.js) - Google Analytics
  ReactGA.initialize(trackingID, {
    // this is for GA debug
    // debug: true,
    titleCase: false,
    gaOptions,
  });
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export function usePageView() {
  const searchLocation = window.location.pathname + window.location.search;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [searchLocation]);
}
