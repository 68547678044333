import React, { useEffect } from "react";
import { iceCardData } from "./programData";

import ProjectPartnersFooter from "../../components/Footer/ProjectPartnersFooter";
import WhiteHeader from "../../components/Header/WhiteHeader";
import ProgramCardContainer from "../../containers/CardContainer/ProgramCardContainer";

import { scrollToTop } from "../../utils/utils";

import {
  DynamicContainer,
  GraphicBar,
  SectionContainer,
  IconContainer,
  TitleIcon,
  Title,
  Strong,
  P,
  AButton,
} from "./style";

const IcePage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const themeColor = "#2F6886";
  return (
    <>
      <WhiteHeader themeColor={themeColor} showProgramsNav={true} />
      <GraphicBar
        height={"17rem"}
        marginTop={"11.2rem"}
        url={"/images/ice-graphic.svg"}
      >
        <DynamicContainer
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          smallScreenMargin={"-2.2rem auto"}
          padding={"0"}
          smallScreenFlex={"flex"}
          smallScreenStack={"column"}
          smallScreenJustifyContent={"center"}
        >
          <IconContainer smallScreenMargin={"1rem"}>
            <TitleIcon src={"/images/ffsp-logo.svg"} alt="ice-icon" />
          </IconContainer>
          <Title textcolor={"white"} margin={"0"}>
            Awareness. Information. Support.
          </Title>
        </DynamicContainer>
      </GraphicBar>
      <DynamicContainer height={"auto"}>
        <Title>
          What is the{" "}
          <Strong color={themeColor}>
            {" "}
            Family and Friend Support Program{" "}
          </Strong>
          for people supporting someone who is using ice?
        </Title>
        <P regularFontSize={"1rem"} mobileFontSize={"0.8rem"}>
          What is the Family and Friend Support Program for people supporting
          someone who is using ice? Ice (crystal methamphetamine) use not only
          effects people who are using the drug, but it also has an adverse
          impact on their family, friends, and community. Family and peer
          support networks are critical to a person’s recovery from
          methamphetamine use, but too often, relationships become strained and
          users become isolated from their family, friends, and social networks
          due to the problems arising from their drug use. Families and friends
          can play a critical role in the recovery of people using ice. However,
          there is little support for families and friends in this situation.
          This is an online intervention for families and friends supporting
          someone who is using ice. It includes information on how families and
          friends can best help their loved ones and protect them from the
          adverse impacts of their drug-using lifestyle. The program recognises
          that supporting someone who is using can be an extremely stressful
          experience, and therefore aims to assist families and friends in best
          managing the demands of this role.
        </P>
        <AButton
          fatbuttonpadding={"1.2rem 0.8rem"}
          link={"https://ice.ffsp.com.au/"}
          target={"_blank"}
          textcolor={"#E84D7B"}
          bordercolor={"#E84D7B"}
          display={"inline-block"}
          textalign={"center"}
        >
          Register
        </AButton>

        <AButton
          fatbuttonpadding={"1.2rem 0.8rem"}
          link={"https://ice.ffsp.com.au/login"}
          target={"_blank"}
          textcolor={"#E84D7B"}
          bordercolor={"#E84D7B"}
          display={"inline-block"}
          textalign={"center"}
          margin={"0 0 1.5rem 0"}
        >
          Login
        </AButton>
      </DynamicContainer>
      <SectionContainer backgroundcolor={"#F4F4F9"} padding={"10rem 0 15rem 0"}>
        <DynamicContainer padding={"0 0 7rem 0"}>
          <Title>
            What the{" "}
            <Strong color={themeColor}>
              {" "}
              Family and Friend Support Program{" "}
            </Strong>{" "}
            for people supporting someone who is using ice offers
          </Title>

          <P>
            A free online program and access to trusted and reliable information
            to assist families, friends and health professionals supporting
            someone who is using ice.
          </P>
        </DynamicContainer>
      </SectionContainer>
      <GraphicBar tabletHeight={"auto"} url={"/images/ice-graphic.svg"}>
        <DynamicContainer transform={"translateY(-20%)"}>
          <ProgramCardContainer cardData={iceCardData} themeColor={"#4AAFBD"} />
          <Title textcolor={"white"} margin={"0 0 2rem 0"}>
            Get access to Family and Friend Support Program
          </Title>
          <AButton
            fatbuttonpadding={"1.2rem 0.8rem"}
            link={"https://ice.ffsp.com.au/"}
            target={"_blank"}
            textcolor={"white"}
            backgroundcolor={"#E84D7B"}
            bordercolor={"#E84D7B"}
            display={"inline-block"}
            textalign={"center"}
          >
            Register
          </AButton>
          <AButton
            fatbuttonpadding={"1.2rem 0.8rem"}
            link={"https://ice.ffsp.com.au/login"}
            textcolor={"white"}
            backgroundcolor={"#E84D7B"}
            bordercolor={"#E84D7B"}
            display={"inline-block"}
            textalign={"center"}
            margin={"0 0 1.5rem 0"}
          >
            Login
          </AButton>
        </DynamicContainer>
      </GraphicBar>
      <ProjectPartnersFooter themeColor={themeColor} textcolor={"white"} />
    </>
  );
};

export default IcePage;
