import React from "react";
import styled from "styled-components";
import Button from "../UI/Button/Button";
import Footer from "./Footer";
import GOV from "../../assets/img/GOV.svg";
import UNC from "../../assets/img/UNC.svg";
import UOS from "../../assets/img/uos-mono.svg";
import PREMISE from "../../assets/img/premise-logo.svg";

const PartnersTitle = styled.div`
  width: 40rem;
  text-align: center;
  margin: 5rem 0 2rem 0;
`;

const Partners = styled.div`
  width: 60rem;
  box-shadow: 0px 0px 6px rgba(35, 31, 32, 0.4);
  border-radius: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  background: #fff;
`;

const PartnerLogo = styled.img`
  width: 7rem;
  margin: 2rem;
`;

const GOVLogo = styled(PartnerLogo)`
  width: 10rem;
  margin: 1rem 1rem 1rem 2rem;
`;

const HighlightedText = styled.span`
  color: rgba(0, 164, 183, 1);
  font-weight: 500;
  text-transform: uppercase;
`;

const H2 = styled.h2`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.6rem")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  margin: ${({ margin }) => margin};
  text-transform: uppercase;

  color: ${({ textcolor, white }) =>
    textcolor ? textcolor : white ? "#fff" : "rgba(55, 60, 91, 1)"};

  @media (max-width: 767px) {
    font-size: "1.2rem";
  }
`;

const P = styled.p`
  color: rgba(55, 60, 91, 1);
  font-size: ${({ regularFontSize }) => regularFontSize};
  color: ${({ textcolor }) => textcolor};

  margin-bottom: 0;
  word-spacing: 0.15rem;
  line-height: 1.5;
  @media (max-width: 767px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

const Container5 = styled.div`
  display: flex;
  justify-content: center;
  height: 22rem;
  flex-flow: column;
  align-items: center;
  background-color: rgba(232, 232, 237, 1);
  @media (max-width: 1500px) {
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    ${Partners} {
      width: 90%;
      padding: 2rem 1rem;
      ${PartnerLogo} {
        margin: 0;
      }
      ${GOVLogo} {
        margin: 0;
      }
    }
  }
  @media (max-width: 767px) {
    height: auto;
    padding-top: 2rem;
    margin-bottom: -6rem;
    ${PartnersTitle} {
      width: 90%;
    }
    ${Partners} {
      width: min-content;
      height: auto;
    }
  }
`;
const Container6 = styled.div`
  background-color: ${({ backgroundcolor }) =>
    backgroundcolor ? backgroundcolor : "rgba(0, 199, 221, 1)"};

  display: flex;
  flex-flow: column wrap;
  height: 22rem;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
  @media (max-width: 767px) {
    ${H2} {
      font-size: 1.6rem;
    }
    ${P} {
      width: 90%;
      text-align: center;
    }
  }
`;

const ProjectPartnersFooter = ({ themeColor, textColor }) => {
  return (
    <>
      <Container5>
        <PartnersTitle>
          <H2>
            Project
            <HighlightedText> Partners</HighlightedText>
          </H2>
          <P>
            The Family and Friend Support Programs are made possible by the
            participation and funding of the following partner organisations:
          </P>
        </PartnersTitle>
        <Partners>
          <GOVLogo src={GOV} alt="GOV" />
          <PartnerLogo src={UNC} alt="UNC" />
          <PartnerLogo src={UOS} alt="UNiversity of Sydney" />
          <PartnerLogo src={PREMISE} alt="Premise" />
        </Partners>
      </Container5>
      <Container6 backgroundcolor={themeColor}>
        <P
          textcolor={textColor}
          style={{ fontWeight: "bold" }}
          regularScreenFontSize={"1.5rem"}
          mobileFontSize={"1.2rem"}
        >
          Want to learn more?
        </P>
        <H2
          textcolor={textColor}
          fontWeight={"bold"}
          fontSize={"3rem"}
          margin={"0.4rem 0 0.4rem 0"}
        >
          Register today
        </H2>
        <P
          textcolor={textColor}
          style={{ fontWeight: "500", margin: "0.3rem 0 1rem 0" }}
        >
          It's east to get set up, try the tool today for free!
        </P>
        <Button
          padding={"1.4rem 1.4rem"}
          color="#fff"
          borderColor="#fff"
          bg="transparent"
          bold
        >
          <a href="/register" style={{ color: "#fff" }}>
            Register
          </a>
        </Button>
      </Container6>
      <Footer />
    </>
  );
};

export default ProjectPartnersFooter;
