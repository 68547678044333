import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import Preloader from '../components/UI/Loader/Preloader'

const LoadingOverlay = styled.div`
  position: fixed;
  z-index: 999;
  background-color: rgb(0,0,0,0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const LoadingContext = React.createContext({ setIsLoading: () => {} });

function LoadingProvider({children}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{setIsLoading}}>
      { isLoading && (
        <>
          <Preloader />
          <LoadingOverlay id="loading" />
        </>
      )}
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider;

// For use in an action
// Do not use in a render method
export function useSetLoadingState() {
  return useContext(LoadingContext).setIsLoading
}

// For use in a render method
export function useHandleLoadingState(isLoading) {
  const setIsLoading = useSetLoadingState()
  useEffect(() => {
    setIsLoading(isLoading)
    return () => {
      setIsLoading(false)
    }
  })
}