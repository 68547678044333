import React from "react";
import styled from "styled-components";

const Logo = styled.img`
  width: 4rem;
  max-width: 4rem;
  position: relative;
  left: -1.6rem;
  top: ${props => (props.top ? "-4px" : "0px")};
`;

const Text = styled.p`
  margin-right: 2em;
  font-size: 1.3em;
  font-weight: 400;
`;

const CardBox = styled.div`
  display: flex;
  flex-flow: row;
  border-radius: 12px;
  align-items: center;
  min-height: 5rem;
  max-width: 30rem;
  width: 100%;
  color: #fff;
  margin-bottom: 1em;
`;

const Card = props => {
  const { logo, children, top } = props;
  return (
    <CardBox>
      <Logo src={logo} alt="logo" top={top} />
      <Text>{children}</Text>
    </CardBox>
  );
};

export default Card;
