import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { Token } from "@netfront/gelada-react-shared";

export default function HowlerClient() {
  const httpLink = createHttpLink({
    uri:
      "https://o26mr9a51j.execute-api.ap-southeast-2.amazonaws.com/Prod/graphql",
    fetch,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = Token;
    return {
      headers: {
        ...headers,
        authorization: token ? `${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return client;
}
