import { useEffect, useState } from 'react'
import { parse } from 'query-string'

export function useQueryString() {
  const [params, setParams] = useState<{ [key: string]: string | string[] | undefined | null }>({})

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    setParams(parse(location.search))
  }, [])

  return params
}
