import { useLazyQuery as useApolloLazyQuery } from "@apollo/react-hooks";
import { useHandleLoadingState } from '../context/LoadingContext'

export function useLazyQuery(...args) {
  const query = useApolloLazyQuery(...args)

  useHandleLoadingState(query.loading)
  
  return query
}

