import React, { useState } from "react";
import PageHelmet from "../../components/PageHelmet/index";
// Components
import Header from "../../components/Header/ConsoleHeader";
import Footer from "../../components/Footer/Footer";
import Card from "../../components/UI/Card/ModuleCard";
import {
  Wrapper,
  Container,
  TextBox,
  TextBox2,
  H2,
  H3,
  P,
  ContentBox2,
  ContentBox3,
  BoxInner,
  UserGuide,
  Destination,
  PathContainer1,
  Path1,
  Path2,
  Path3,
  Path4,
  Path5,
  Section2,
} from "./styled";
// GraphQL
import GetUserFlow from "../../middleware/Userflow/getUserFlow";
// Utils
import { findIndexReverse } from "../../utils/utils";

const ConsolePage = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [useflowSteps, setUserflowSteps] = useState([]);
  const [userFlow, setUserFlow] = useState(undefined);

  const moduleList = [],
    miniModuleList = [],
    miniModuleList2 = [];
  useflowSteps.map((item, i) => {
    if (i < 4) {
      moduleList.push(
        <Card userFlowId={props.match.params.id} key={item.id} item={item} />
      );
    }
    if (i >= 4 && i < 8) {
      miniModuleList.push(
        <Card userFlowId={props.match.params.id} key={item.id} item={item} />
      );
    }
    if (i >= 8 && i < 11) {
      miniModuleList2.push(
        <Card userFlowId={props.match.params.id} key={item.id} item={item} />
      );
    }
    return null;
  });

  const handleGetUserflow = (data, type) => {
    if (type === "data") {
      setUserFlow(data);
      const learnSteps = data.userFlowSteps.filter(
        (u) => u.contentGroup.type === "LEARNING"
      );
      const reorderSteps = [...learnSteps];
      const mini4 = reorderSteps.splice(11, 4);
      reorderSteps.splice(8, 0, ...mini4);
      const lastCompletedModuleIndex = findIndexReverse(
        (s) => s.isComplete,
        reorderSteps
      );

      let lastIncompleteModule = null;
      let incomplete = true;
      if (lastCompletedModuleIndex >= 8 && lastCompletedModuleIndex <= 11) {
        incomplete = learnSteps.slice(11).find((m) => !m.isComplete);
        if (!incomplete) lastIncompleteModule = reorderSteps[12];
        else lastIncompleteModule = reorderSteps[7];
      } else {
        lastIncompleteModule =
          lastCompletedModuleIndex !== null &&
          lastCompletedModuleIndex < reorderSteps.length
            ? reorderSteps[lastCompletedModuleIndex + 1]
            : reorderSteps[0];
      }

      setUserflowSteps(
        learnSteps.map((item) => {
          if (item.id === 36) {
            return {
              ...item,
              title: item.stepName,
              text: item.description,
              isComplete: !incomplete,
              isCurrent:
                lastIncompleteModule && item.id === lastIncompleteModule.id,
            };
          }
          return {
            ...item,
            title: item.stepName,
            text: item.description,
            isCurrent:
              lastIncompleteModule && item.id === lastIncompleteModule.id,
          };
        })
      );
    }
    setLoaded(true);
  };


  return (
    <>
      <PageHelmet title="FFSP: Console" />
      <Wrapper>
        <Header />
        <UserGuide href="/user-guide">USER GUIDE FOR FFSP</UserGuide>
        <Container>
          {userFlow && (
            <>
              <TextBox>
                <H2>MODULES</H2>
                <H3>CORE MODULES</H3>
                {userFlow && <P>{userFlow.description}</P>}
              </TextBox>
              <ContentBox2>{moduleList}</ContentBox2>
              <PathContainer1>
                {moduleList.length > 1 && (
                  <>
                    <Path1 />
                    <Path2 />
                  </>
                )}
              
              </PathContainer1>
              {userFlow && userFlow.id === 9 && (
                <>
                  <Section2>
                    <Path3 />
                    <BoxInner>
                      <TextBox2>
                        <H3>MINI MODULES</H3>
                        <P>
                          The following modules are designed to help you develop
                          skills to navigate the path ahead
                        </P>
                      </TextBox2>
                      <ContentBox2>{miniModuleList}</ContentBox2>
                    </BoxInner>
                  </Section2>
                  <Section2>
                    <Path4 />
                    <Path5 />
                    <BoxInner>
                      <ContentBox3>
                        {window.innerWidth > 1023
                          ? miniModuleList2.reverse()
                          : miniModuleList2}
                      </ContentBox3>
                    </BoxInner>
                    <Destination>X</Destination>
                  </Section2>
                </>
              )}
            </>
          )}
        </Container>
        <Footer />
      </Wrapper>
      {!loaded && (
        <GetUserFlow
          onComplete={handleGetUserflow}
          projectId={process.env.REACT_APP_PROJECT_ID}
          url={props.match.params.id}
        />
      )}
    </>
  );
};

export default ConsolePage;
