import React from "react";
import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { ContentSnippetProps, IToolbox, UserFlowsProps } from "../Common/Interfaces";
import { ToolboxBody } from "../Common/ToolboxBody";
interface ActivityItemsProps {
  contentSnippets: ContentSnippetProps[];
  userFlows: UserFlowsProps[];
}

const KEY_PREFIX = "tool-box-content-activity-items";

const ActivityItems = ({ contentSnippets, userFlows }: ActivityItemsProps) => {
  if (!contentSnippets.length) {
    return null;
  }

  const data: IToolbox[] = contentSnippets.map((contentSnippet: ContentSnippetProps) => {
    const {
      app: { title: accordionItemTitle },
      contentPage: {
        url: contentPageUrl,
        userFlowStep: { userFlowId, stepName: userFlowStepName, contentGroup: { url: contentGroupUrl }, url: userFlowStepUrl },
      },
    } = contentSnippet;

    const userFlowUrl = userFlows.find(flow => flow.id === userFlowId).url; 

    return {
      userFlowId,
      userFlowUrl,
      accordionItemTitle,
      contentGroupUrl,
      contentPageUrl,
      userFlowStepName,
      userFlowStepUrl,
    };
  });

  function renderAccordionBody(item: IToolbox, index: number) {
    const { userFlowUrl, accordionItemTitle, contentPageUrl, userFlowStepUrl } = item;
    return (
      <ListItem key={`${KEY_PREFIX}-accordion-item-link-${index}`} button style={{ paddingLeft: "1rem" }}>
        <ListItemIcon>
          <BookmarkIcon className="bookmark-icon" style={{ color: "rgba(0, 199, 221, 1)" }} />
        </ListItemIcon>
        <ListItemText primary={accordionItemTitle} />
        <Link className="go-to-page" href={userFlowStepUrl === null ? `${contentPageUrl}` : `/content/${userFlowUrl}/${userFlowStepUrl}/${contentPageUrl}`}>
          go to page
          <ListItemIcon>
            <ArrowForwardIosIcon style={{ color: "rgba(0, 199, 221, 1)" }} />
          </ListItemIcon>
        </Link>
      </ListItem>
    );
  }

  return (
    <ToolboxBody 
      keyPrefix={KEY_PREFIX}
      data={data}
      accordionBody={renderAccordionBody}/>
  );
};

export const Link = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10rem;
  color: #00c7dd;
  font-weight: 700;
  text-transform: uppercase;
`;

export default ActivityItems;
