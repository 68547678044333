import React, { useEffect } from "react";
import WhiteHeader from "../../components/Header/WhiteHeader";
import Footer from "../../components/Footer/Footer";

import { scrollToTop } from "../../utils/utils";

import { Wrapper } from "./style";

const AppPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <WhiteHeader showProgramsNav={false} />
      <Wrapper>
        <div className="min-h-body w-full">
          <div className="bg-ocean">
            <div className="container-sm text-center pt-12 pb-16 relative">
              <h1 className="color-white text-uppercase mb-8 font-header weight-300">
                You can find the FFSP app on <br /> the Apple Store and Google
                Play Store
              </h1>
              <p className="color-white">Click the links to download</p>

              <div className="bg-white radius p-6 px-8 absolute left-50 top-full -translate-50 shadow flex align-center">
                <a
                  className="mr-4 flex align-center"
                  href="https://apps.apple.com/au/app/ffsp/id1530011625"
                  target="_blank"
                >
                  <img src="/images/apple-store.svg" alt="apple store link" />
                </a>
                <a
                  className="flex align-center"
                  href="https://play.google.com/store/apps/details?id=com.netfront.ffsp"
                  target="_blank"
                >
                  <img
                    src="/images/google-play.svg"
                    alt="google play store link"
                  />
                </a>
              </div>
            </div>
          </div>

          <section>
            <div className="container-sm pt-20 pb-12">
              <p>
                The Family and Friends Support Program is now available as an
                app.
              </p>
              <p>
                You will be able to access the following Family and Friends
                Support Program’s:
              </p>

              <div className="flex flex-col md:flex-row -mx-4 py-8">
                <div className="w-full md:w-third px-4">
                  <div className="bg-white radius shadow p-8 flex flex-col align-center md:min-h-44">
                    <img
                      className="mb-4 max-h-16"
                      src="images/aod-logo.svg"
                      alt="alcohol and/or other drugs"
                    />
                    <p className="text-center">
                      Program for people supporting someone who is using{" "}
                      <span className="color-blue">
                        alcohol and/or other drugs.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-third px-4">
                  <div className="bg-white radius shadow p-8 flex flex-col align-center md:min-h-44">
                    <img
                      className="mb-4 max-h-16"
                      src="/images/domestic-violence-logo.svg"
                      alt="domestic and family violence"
                    />
                    <p className="text-center">
                      Program for people who are experiencing{" "}
                      <span className="color-purple">
                        domestic and family violence.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-third px-4">
                  <div className="bg-white radius shadow p-8 flex flex-col align-center md:min-h-44">
                    <img
                      className="mb-4 max-h-16"
                      src="/images/rural-logo.svg"
                      alt="rural or remote regions"
                    />
                    <p className="text-center">
                      Program for people who live in{" "}
                      <span className="color-brown">
                        rural or remote regions.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

export default AppPage;
