import React from "react";

import PageHelmet from "../../components/PageHelmet/index";
// Components
import Header from "../../components/Header/ConsoleHeader";
import Footer from "../../components/Footer/Footer";
import { MenuWrapper, Card, CardIcon, CardTitle } from "./styled";
import { Wrapper, Container, UserGuide, TextBox, H2, P } from "../ConsolePage/styled";
//Assets
import ActivitiesIcon from "../../assets/img/toolbox/activities.svg";
import AudioIcon from "../../assets/img/toolbox/audio.svg";
import VideosIcon from "../../assets/img/toolbox/videos.svg";
import PdfsIcon from "../../assets/img/toolbox/pdfs.svg";

const menu = [
  {
    id: 0,
    title: "activities",
    icon: ActivitiesIcon,
  },
  {
    id: 1,
    title: "videos",
    icon: VideosIcon,
  },
  {
    id: 2,
    title: "audio",
    icon: AudioIcon,
  },
  {
    id: 3,
    title: "resources",
    icon: PdfsIcon,
  },
];

const ToolboxPage = () => {
  return (
    <>
      <PageHelmet title="FFSP: Toolbox" />
      <Wrapper>
        <Header />
        <UserGuide href="/user-guide">USER GUIDE FOR FFSP</UserGuide>
        <Container>
          <TextBox>
            <H2>Toolbox</H2>
            <P>
              This is where you can find all activities, audio clips, videos, and PDF handouts from
              the program.
            </P>
          </TextBox>
          <MenuWrapper>
            {menu.map((item) => (
              <Card key={item.id} href={`/toolbox/${item.title}`}>
                <CardIcon alt="icon" src={item.icon} />
                <CardTitle>{item.title}</CardTitle>
              </Card>
            ))}
          </MenuWrapper>
        </Container>
        <Footer />
      </Wrapper>
    </>
  );
};

export default ToolboxPage;
