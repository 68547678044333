import React from "react";
import styled from "styled-components";
import CardWithButton from "../../components/UI/Card/CardWithButton";

const CardWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ alternatecardcroperties }) =>
    alternatecardcroperties && alternatecardcroperties.justifyContent};
  padding: 0;
  @media (min-width: 2047px) {
    max-width: ${({ alternatecardcroperties }) =>
      alternatecardcroperties && alternatecardcroperties.largeScreenMaxWidth};
  }
  @media (max-width: 1714px) {
    justify-content: center;
  }
`;

const CardContianer = ({ cardData, alternateCardProperties }) => {
  return (
    <CardWrapper alternatecardcroperties={alternateCardProperties}>
      {cardData.map(
        ({
          logo,
          info,
          highlightedText,
          buttonText,
          link,
          logoSize,
          themeColor,
          id,
          textColor,
          borderColor,
        }) => (
          <CardWithButton
            key={id}
            logo={logo}
            logoSize={logoSize}
            info={info}
            highlightedText={highlightedText}
            buttonText={buttonText}
            link={link}
            themeColor={themeColor}
            textColor={textColor}
            borderColor={borderColor}
            alternateCardProperties={alternateCardProperties}
          />
        )
      )}
    </CardWrapper>
  );
};

export default CardContianer;
