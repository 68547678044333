import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import EmergencyLogo from "../../../assets/img/emergency-call-2.svg";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "60%",
    maxWidth: "1000px",
    maxHeight: "80vh",
    position: "fixed",
    top: "2rem",
    overflow: "auto"
  },
}));

const Emergency = styled.button`
  background-color: #fe757a;
  color: #fff;
  border: none;
  width: 10em;
  height: 3.4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    transform: scale(1.04);
  }
`;

const Section = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: ${(props) => (props.isGrayBg ? "#e8e8ed" : "#fff")};
  padding: 1em 5em;
`;

const Title = styled.a`
  text-align: center;
  margin-top: 0;
  font-weight: 700;
  margin-bottom: 1rem;
  text-decoration: underline;
`;

const Introduction = styled.p`
  text-align: center;
  margin: 1.5rem auto 1rem auto;
  width: 80%;
`;

const Number = styled.a`
  width: 6em;
  border: 2px solid #00a4b7;
  padding: 0.6em 1em;
  color: #00a4b7;
  font-weight: 800;
  text-align: center;
  white-space: nowrap;
`;

const telBook = [
  {
    title: "National Alcohol and Other Drug Hotline",
    number: "1800 250 015",
    link: 'https://www.health.gov.au/contacts/national-alcohol-and-other-drug-hotline-contact'
  },
  {
    title: "Lifeline",
    number: "131114",
    link: 'https://www.lifeline.org.au/'
  },
  {
    title: "Suicide Call Back Service",
    number: "1300 659 467",
    link: 'https://www.suicidecallbackservice.org.au/'
  },
  {
    title: "Kids Helpline",
    number: "1800 551 800",
    link: 'https://kidshelpline.com.au/'
  },
  {
    title:
      "If you are concerned about your safety or the safety and wellbeing of someone else, we encourage you to leave immediately and contact the police.",
    number: "000",
  },
];

const EmergencyModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Emergency onClick={handleOpen}>
        <img alt="Emergency-Logo" src={EmergencyLogo} />
        EMERGENCY
      </Emergency>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Introduction>
              If you are in a crisis situation or need immediate assistance, 
              click the service names to access their website or call the numbers listed below:
            </Introduction>
            {telBook.map((item, i) => (
              <Section key={item.title} isGrayBg={(i + 1) % 2 === 0}>
                {item.link 
                  ? <Title href={item.link} isBold target="_blank">{item.title}</Title>
                  : <Introduction>{item.title}</Introduction>
                }
                <Number href={`tel:${item.number}`}>{item.number} </Number>
              </Section>
            ))}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

EmergencyModal.propTypes = {
  isChanged: PropTypes.func,
  text: PropTypes.string,
};

EmergencyModal.defaultProps = {
  isChanged: () => {},
  text: "",
};

export default EmergencyModal;
