import React from "react";
import styled from "styled-components";
import spin from "../../../assets/img/Spin.gif";

const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Preloader = props => {
  return (
    <Wrapper>
      <img alt="loading..." src={spin} />
    </Wrapper>
  );
};

export default Preloader;
