import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import "./utils.css";
import GlobalContext from "./context/globalContext";
import { ApolloProvider } from "@apollo/react-hooks";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import ActivationPage from "./pages/ActivationPage/ActivationPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import ConsolePage from "./pages/ConsolePage/ConsolePage";
import Mini4ConsolePage from "./pages/ConsolePage/MiniModule4ConsolePage";
import ContentPage from "./pages/ContentPage/ContentPage";
import BookmarksPage from "./pages/Dashboard/BookmarksPage";
import TermsAndConditionsPage from "./pages/GeneralPage/TermsAndConditionsPage";
import PrivacyPage from "./pages/GeneralPage/PrivacyPage";
import UserGuidePage from "./pages/GeneralPage/UserGuidePage";
import ContactPage from "./pages/GeneralPage/ContactPage";
import ToolboxPage from "./pages/Toolbox/index";
import ToolboxContentPage from "./pages/Toolbox/contentPage";
import Home from "./pages/HomePage/Home";
import RuralRemotePage from "./pages/ProgramPage/RuralRemotePage";
import AodPage from "./pages/ProgramPage/AodPage";
import DomesticViolencePage from "./pages/ProgramPage/DomesticViolencePage";
import IcePage from "./pages/ProgramPage/IcePage";
import AllProgramsPage from "./pages/ProgramPage/AllProgramsPage";
import BreathingSpacePage from "./pages/BreathingSpacePage/BreathingSpacePage";

import client from "./middleware/client";
import { _GetAccessToken, _GetUserData } from "@netfront/gelada-react-shared";
import LoadingContext from "./context/LoadingContext";
import { initGA } from "./components/GoogleAnalytics/index";
import SectionSelectionPage from "./pages/SectionSelection/SectionSelectionPage";
import AccessPage from "./pages/AccessPage/AccessPage";
import AppPage from "./pages/AppPage/AppPage";

function ProtectedRoute(props) {
  if (!_GetAccessToken()) return <Redirect to="/" />;
  return <Route {...props} />;
}

function App() {
  const [user, setUser] = useState("");
  const storeUser = (value) => {
    setUser(value);
  };
  //  Google Analytics
  initGA("UA-92528153-3", { userId: _GetUserData() ? _GetUserData().id : "" });

  return (
    <LoadingContext>
      <GlobalContext.Provider value={{ user, storeUser }}>
        <ApolloProvider client={client}>
          <Router>
            <Switch>
              <Route path="/access" component={AccessPage} />
              <Route path="/breathing-space" component={BreathingSpacePage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/forget-password" component={ForgetPasswordPage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route path="/public-contact" component={ContactPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/register" component={RegisterPage} />
              <Route path="/activation" component={ActivationPage} />
              <Route path="/programs" component={AllProgramsPage} />
              <Route path="/app" component={AppPage} />
              <Route path="/aod" component={AodPage} />
              <Route path="/ice" component={IcePage} />
              <Route
                path="/domestic-violence"
                component={DomesticViolencePage}
              />
              <Route path="/rural-remote" component={RuralRemotePage} />
              <ProtectedRoute
                path="/console/alcohol-or-other-drugs/mini-module-4"
                component={Mini4ConsolePage}
              />
              <ProtectedRoute
                path="/dashboard"
                component={SectionSelectionPage}
              />
              <ProtectedRoute path="/console/:id" component={ConsolePage} />

              <ProtectedRoute path="/bookmarks" component={BookmarksPage} />
              <ProtectedRoute
                path="/toolbox/:contentType"
                component={ToolboxContentPage}
              />
              <ProtectedRoute path="/toolbox" component={ToolboxPage} />
              <ProtectedRoute
                path="/content/:id/:moduleUrl/:pageUrl"
                component={ContentPage}
              />
              <Route
                path="/terms-and-conditions"
                component={TermsAndConditionsPage}
              />
              <Route path="/privacy" component={PrivacyPage} />
              <Route path="/user-guide" component={UserGuidePage} />

              <Route path="/" component={Home} />
              <Redirect to="/" />
            </Switch>
          </Router>
        </ApolloProvider>
      </GlobalContext.Provider>
    </LoadingContext>
  );
}

export default App;
