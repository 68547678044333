import React from "react";
import GeneralPage, {
  Container,
} from "../../components/LayoutGeneralPage/index";
import { ContentWrapper } from "@netfront/ekardo-shared-library";

const TermsPage = () => {
  return (
    <GeneralPage title="Terms and Conditions">
      <Container>
        <ContentWrapper pageId={620} mode={false} isPublic={true} />
      </Container>
    </GeneralPage>
  );
};

export default TermsPage;
