import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 22rem);
`;

export const Container = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  max-width: 70rem;
  margin: 0 auto;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);
  height: 100%;
  min-height: 20rem;
  margin: 1rem 0 4rem 0;
  padding: 2rem;
  width: 100%;
`;

export const ResContainer = styled.div`
  box-sizing: border-box;
  max-width: 70rem;
  margin: 0 auto;
  height: 100%;
  min-height: 20rem;
  margin: 1rem 0 4rem 0;
  padding: 2rem;
  width: 100%;
`;

export const Section = styled.div`
  border: 1px solid rgba(207, 209, 210, 1);
  margin-bottom: 1rem;
  color: rgba(21, 76, 117, 1);
`;

export const ResSection = styled(Section)`
  background-color: #fff;
  border-radius: 0.3rem;
`;
// TODO: General dropdown style
export const ResGeneralSection = styled(Section)`
  background-color: #4bc2ca;
  border-color: #4bc2ca;
  border-radius: 0.3rem;
  padding: 0;
  color: #fff;
`;

export const ResTitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  font-weight: bold;
`;

export const NoBookmarkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 1rem auto;
`;

export const BookmarkIcon = styled.img`
  width: 5rem;
  margin-bottom: 1rem;
`;

export const NoBookmarkTitle = styled.h3`
  color: #154c75;
  font-size: 1.5rem;
`;

export const NoBookmarkDesciption = styled.p`
  width: 460px;
  text-align: center;
  color: #373c5b;
`;
