import React, { useState } from "react";
import "./countdownCircle.css";

const CountdownCircle = (props) => {
  const { totalTime, onEndProcess } = props;
  const [remainingTime, setRemainingTime] = useState(totalTime);

  if (remainingTime >= 0) {
    setTimeout(() => {
      if (remainingTime === 0) onEndProcess();
      setRemainingTime(remainingTime - 1);
    }, 1000);
  }

  return (
    <div class="base-timer">
      <svg
        class="base-timer__svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g class="base-timer__circle">
          <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
        </g>
      </svg>
      <span class="base-timer__label">{remainingTime}</span>
    </div>
  );
};

export default CountdownCircle;
