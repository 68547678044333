export const inWords = (number) => {
  const strLength = number.toString().length;
  if (strLength >= 2 && strLength <= 6) {
    return number;
  }
  if (strLength >= 7 && strLength <= 8) {
    return `${parseInt(number / 1000000, 10)} million`;
  }
  if (strLength >= 9 && strLength <= 10) {
    return `${parseInt(number / 1000000000, 10)} billion`;
  }
  return 'unlimited';
};

export const dateUtil = (date) => {
  const extract = date.split('T')[0].split('-');
  const year = extract[0];
  const month = extract[1];
  const day = extract[2];
  const format = `${day}/${month}/${year.substring(2, 4)}`;
  return format;
};

export const stringToDate = (date) => {
  const finalDate = date.split('/');
  return new Date(finalDate[2], finalDate[1], finalDate[0]);
};

export const checkTokenValidity = (tokenObj) => {
  if (Object.keys(tokenObj).length === 0) {
    return false;
  }
  let expiresUtc = tokenObj;
  if (expiresUtc) {
    expiresUtc = stringToDate(expiresUtc.expireUtc);
  }

  let getTokenRequest = true;

  if (expiresUtc - new Date() <= 0) {
    getTokenRequest = false;
  }
  return getTokenRequest;
};

export const convertToURL = (str) => str.toLowerCase().split(' ').join('-');

export const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};
export const getValidUrl = (url = '') => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');
  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }
  return newUrl;
};

export const dynamicSort = (prop) => {
  let sortOrder = 1;
  let subProperty = prop;
  if (prop[0] === '-') {
    sortOrder = -1;
    subProperty = subProperty.substr(1);
  }
  return (a, b) => {
    if (a[subProperty] < b[subProperty]) {
      return -1 * sortOrder;
    }
    if (a[subProperty] > b[subProperty]) {
      return 1 * sortOrder;
    }
    return 0;
  };
};

export const removeByAttr = (arr, attr, value) => {
  const filteredPeople = arr.filter((item) => item[attr] !== value);
  return filteredPeople;
};

export const getQueryStringValue = (location, key) =>
  decodeURIComponent(
    location.search.replace(
      new RegExp(
        `^(?:.*[&\\?]${encodeURIComponent(key).replace(
          // eslint-disable-next-line no-useless-escape
          /[\.\+\*]/g,
          '\\$&'
        )}(?:\\=([^&]*))?)?.*$`,
        'i'
      ),
      '$1'
    )
  );

export const searchByFieldInArray = (array, key, searchText) =>
  array.filter(
    (item) =>
      String(item[key])
        .toLowerCase()
        .localeCompare(searchText.toLowerCase()) !== -1
  );

export const StatusEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
};

export const ContentStatusEnum = {
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
  DELETED: 'DELETED',
};

export const ContentGroupTypeEnum = {
  TRAINING: 'TRAINING',
  CARTOON: 'CARTOON',
  PAGE: 'PAGE',
  HELP: 'HELP',
};

export const ContentSectionEnum = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};

export const assetType = {
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  CODE: 'CODE',
  DOCUMENT: 'DOCUMENT',
};

export const TreeFormat = (data, level, path, menuArray, pageLvlIndex) => {
  const initPath = [...path];
  data.map((item) => {
    menuArray.push(item.id);
    const tmpPath = [...initPath];
    tmpPath.push(item.id);
    pageLvlIndex[item.id] = tmpPath;
    if (item.contentPageChildren.length > 0) {
      TreeFormat(
        item.contentPageChildren,
        level + 1,
        tmpPath,
        menuArray,
        pageLvlIndex
      );
    }
    return null;
  });
  return [menuArray, pageLvlIndex];
};

export const unflatten = (arr) => {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]['contentPageChildren'] = [];
  }

  for (var id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.parentId) {
        mappedArr[mappedElem['parentId']]['contentPageChildren'].push(
          mappedElem
        );
        mappedArr[mappedElem['parentId']]['contentPageChildren'].sort(
          (a, b) => a.sort - b.sort
        );
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
        tree.sort((a, b) => a.sort - b.sort);
      }
    }
  }
  return tree;
};

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

export const findIndexReverse = (func, arr) => {
  let index = null;
  for (let i = arr.length - 1; i >= 0; i--) {
    if (func(arr[i])) {
      index = i++;
      break;
    }
  }
  return index;
};

const findPageInChildren = (path, page, index) =>
  page.contentPageChildren.find((page) => page.id === path[index]);

export const findNewPage = (pages, newPageId,pageLvlIndex) => {
  // this part is for find the new page
  const tmpPath = pageLvlIndex[newPageId];
  let newPage = pages.find((page) => page.id === tmpPath[0]);
  for (let i = 1; i < tmpPath.length; i++) {
    newPage = findPageInChildren(tmpPath, newPage, i);
  }
  return newPage;
};
