import React from 'react';
import { getSignedUrl } from '../../../middleware/AWS/getSignedUrl';
import { ContentSnippetProps, IToolbox, UserFlowsProps } from '../Common/Interfaces';
import { ToolboxBody } from '../Common/ToolboxBody';
import { convertBytesToMegabytes } from '../Common/Utils';

interface DocumentItemsProps {
  contentSnippets: ContentSnippetProps[];
  userFlows: UserFlowsProps[];
}

const KEY_PREFIX = 'tool-box-content-document-items';

const DocumentItems = ({ contentSnippets, userFlows }: DocumentItemsProps) => {
  if (!contentSnippets.length) {
    return null;
  }

  const data: IToolbox[] = contentSnippets.map((contentSnippet: ContentSnippetProps) => {
    const {
      asset,
      contentPage: {
        title: accordionItemTitle,
        url: contentPageUrl,
        userFlowStep: {
          userFlowId,
          stepName: userFlowStepName,
          contentGroup: { url: contentGroupUrl },
          url: userFlowStepUrl,
        },
      },
    } = contentSnippet;

    const userFlowUrl = userFlows.find(flow => flow.id === userFlowId).url; 

    return {
      asset,
      userFlowId,
      userFlowUrl,
      accordionItemTitle,
      contentGroupUrl,
      contentPageUrl,
      userFlowStepName,
      userFlowStepUrl,
    };
  });

  function renderAccordionBody(item: IToolbox, index: number) {
    const { accordionItemTitle, asset: { contentType, fileName, fileSizeInBytes, s3Key } } = item;
    return (
      <div key={`document-${index}`} style={{ display: 'flex', padding: '1rem' }}>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <a href={getSignedUrl(s3Key, contentType)} rel="noopener noreferrer" target="_blank">
          <img src="/SVG/resources-white.svg" width="40" alt="video"/>
        </a>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem' }}>
        <div>
          <b>Page:</b> {accordionItemTitle}
        </div>
        <div>
          <a href={getSignedUrl(s3Key, contentType)} rel="noopener noreferrer" target="_blank">
            <b>File name:</b> {fileName}
          </a>
        </div>
        <div>
          <b>File size:</b> ({convertBytesToMegabytes(fileSizeInBytes)})
        </div>
      </div>
    </div>
    );
  }

  return (
    <ToolboxBody 
      keyPrefix={KEY_PREFIX}
      data={data}
      accordionBody={renderAccordionBody}/>
  );
};

export default DocumentItems;
