import React from "react";
import { useQuery } from "../../hooks/useQuery";
import gql from "graphql-tag";
import Error from "../QueryError";

export const GET_USERFLOW_STEP_BY_URL = gql`
  query($projectId: String!, $url: String!, $userFlowUrl: String!) {
    userFlowStep {
      getStepByUrl(
        url: $url
        projectId: $projectId
        userFlowUrl: $userFlowUrl
      ) {
        id
        isComplete
        repetitionAllowed
        userRepetition {
          id
          completed
        }
        contentGroup {
          id
          contentPages {
            id
            url
          }
        }
      }
    }
  }
`;

const GetUserFlowStepByUrl = (props) => {
  const { onComplete, userFlowStepUrl, userFlowUrl,projectId } = props;
  const { error, data } = useQuery(GET_USERFLOW_STEP_BY_URL, {
    variables: {
      url:userFlowStepUrl,
      userFlowUrl,
      projectId
    },
  });

  const handleError = (error) => {
    onComplete(error, "error");
  };

  if (error) {
    onComplete(error.message, "error");
    return <Error message={error.message} onError={handleError} />;
  }
  if (data && Object.entries(data).length !== 0) {
    onComplete(data.userFlowStep.getStepByUrl, "data");
    return null;
  }
  return <></>;
};

export default GetUserFlowStepByUrl;
