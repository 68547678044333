import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import HowlerClient from "../../middleware/HowlerClient";
import { SEND_CONTACT_FORM } from "../../middleware/Howler/SendContactForm";

import ErrorMessage from "../../components/Message/ErrorMessage";
import NoticeMessage from "../../components/Message/NoticeMessage";
import ValidationProcessor from "../../components/Message/ValidationProcessor";

export default function ContactForm() {
  const [contactDetails, setContactDetails] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");

  const [sendContactForm] = useMutation(SEND_CONTACT_FORM, {
    client: HowlerClient(),
  });
  const isRequired = (value) => value.trim().length > 0;

  const validate = () => ({
    FirstnameEmpty: {
      validation:
        contactDetails &&
        contactDetails.firstName &&
        isRequired(contactDetails.firstName),
      errorMessage: "First name must not be empty.",
    },
    LastnameEmpty: {
      validation:
        contactDetails &&
        contactDetails.lastName &&
        isRequired(contactDetails.lastName),
      errorMessage: "Last name must not be empty.",
    },
    EmailEmpty: {
      validation:
        contactDetails &&
        contactDetails.email &&
        isRequired(contactDetails.email),
      errorMessage: "Email must not be empty.",
    },
    MessageEmpty: {
      validation:
        contactDetails &&
        contactDetails.message &&
        isRequired(contactDetails.message),
      errorMessage: "Message must not be empty.",
    },
  });

  const handleUpdateDetails = (e) => {
    setContactDetails({
      ...contactDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = ValidationProcessor(validate());
    if (!validationErrors.modelValid) {
      setValidationErrors(validationErrors.validations);
    } else {
      setValidationErrors("");
      setIsSent(true);
      sendContactForm({
        variables: {
          projectId: process.env.REACT_APP_PROJECT_ID,
          firstname: contactDetails.firstName,
          lastname: contactDetails.lastName,
          sender: contactDetails.email,
          phoneNumber: contactDetails.mobile,
          message: contactDetails.message,
        },
      })
        .then((data) => {
          console.log("data", data.data.notification.sendContactForm);
        })
        .catch((error) => {
          setValidationErrors(
            error.message.substring(
              error.message.indexOf(":") + 1,
              error.message.length
            )
          );
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            inputProps={{
              autoCorrect: "off",
            }}
            onChange={handleUpdateDetails}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            inputProps={{
              autoCorrect: "off",
            }}
            onChange={handleUpdateDetails}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Your email"
            fullWidth
            autoComplete="email"
            type="email"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
              inputMode: "email",
            }}
            onChange={handleUpdateDetails}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="mobile"
            name="mobile"
            label="Your mobile(optional)"
            fullWidth
            autoComplete="tel"
            type="tel"
            inputProps={{
              autoCorrect: "off",
              inputMode: "tel",
            }}
            onChange={handleUpdateDetails}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="message"
            name="message"
            label="Your message"
            multiline
            rows={6}
            fullWidth
            onChange={handleUpdateDetails}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            type="submit"
            // onClick={handleSubmit}
            style={{
              backgroundColor: "rgba(240, 102, 142, 1)",
              color: "rgba(255, 255, 255, 1)",
            }}
          >
            send message
          </Button>
        </Grid>
      </Grid>
      {validationErrors !== "" && <ErrorMessage message={validationErrors} />}
      {isSent && (
        <NoticeMessage
          resetMsg={() => setIsSent(false)}
          message="✉ Message sent"
        />
      )}
    </form>
  );
}
