import React from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ToolboxAccordion from "src/components/Accordion/ToolboxAccordion";
import { Section } from "src/components/Bookmark/styled";
import { USER_FLOWS } from "../ToolBoxContent";
import { AccordionData, IToolbox } from "./Interfaces";
import { createAccordionData } from "./Utils";

interface ToolboxBodyProps {
  data: IToolbox[];
  accordionBody: (item: IToolbox, index: number) => JSX.Element;
  keyPrefix: string;
}

export const ToolboxBody: React.FC<ToolboxBodyProps> = ({ data, accordionBody, keyPrefix }) => {
  const accordionData = createAccordionData(data, USER_FLOWS);
  return (
    <>
      {accordionData.map((item: AccordionData, index: number) => {
        const { title, pages, children } = item;
        return (
          <List key={`${keyPrefix}-accordian-${index}`}>
            <Section>
              <ToolboxAccordion
                header={
                  <ListItemText key={`${keyPrefix}-accordion-title-${index}`} primary={title} />
                }
              >
                <List component="div" disablePadding>
                  {pages?.map((item, index) => accordionBody(item, index))}
                  {children?.map((item, index) => {
                    const { title, pages } = item;
                    return (
                      <List key={`${keyPrefix}-accordian-${index}`}>
                        <Section>
                          <ToolboxAccordion
                            header={
                              <ListItemText
                                key={`${keyPrefix}-accordion-children-title-${index}`}
                                primary={title}
                              />
                            }
                          >
                            <List component="div" disablePadding>
                              {pages?.map((item, index) => accordionBody(item, index))}
                            </List>
                          </ToolboxAccordion>
                        </Section>
                      </List>
                    );
                  })}
                </List>
              </ToolboxAccordion>
            </Section>
          </List>
        );
      })}
    </>
  );
};
