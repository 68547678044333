import React, { useState } from "react";
import styled from "styled-components";
import CountdownCircle from "../UI/ProcessBar/CountdownCircle";

const Wrapper = styled.div`
  background-image: linear-gradient(to right, #d87367, #cc5b6d);
  color: white;
  padding: 1em;
  width: 40%;
  position: absolute;
  right: 30%;
  bottom: 3.3em;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
`;

const Message = styled.ul`
  padding: 0 1rem;
`;

const ErrorMessage = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);
  const messages = [].concat(message);

  const handleDisappear = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <Wrapper>
          <div>
            {messages.map((msg, i) => (
              <Message key={i}>{msg}</Message>
            ))}
          </div>
          <CountdownCircle totalTime={3} onEndProcess={handleDisappear} />
        </Wrapper>
      )}
    </>
  );
};

export default ErrorMessage;
