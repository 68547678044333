export const iceCardData = [
  {
    logo: "/images/online-program-icon.svg",
    info: "Online program to help you manage your situation",
  },
  {
    logo: "/images/helpful-activites-icon.svg",
    info: "Helpful activities",
  },
  {
    logo: "/images/real-stories-icon.svg",
    info: "Real stories: What it’s like supporting someone using ice",
  },
  {
    logo: "/images/resources-icon.svg",
    info: "Training resources for health professionals",
  },
];

export const aodCardData = [
  {
    logo: "/images/online-program-icon.svg",
    info: "Online program to help you manage your situation",
  },
  {
    logo: "/images/helpful-activites-icon.svg",
    info: "Helpful activities",
  },
  {
    logo: "/images/real-stories-icon.svg",
    info:
      "Real stories: What it’s like supporting someone who is using alcohol and/or other drugs.",
  },
  {
    logo: "/images/resources-icon.svg",
    info: "Training resources for health professionals",
  },
];

export const ruralCardData = [
  {
    logo: "/images/online-program-icon.svg",
    info: "Online program to help you manage your situation",
  },
  {
    logo: "/images/helpful-activites-icon.svg",
    info: "Helpful activities",
  },
  {
    logo: "/images/real-stories-icon.svg",
    info: "A list of additional information and support services.",
  },
  {
    logo: "/images/resources-icon.svg",
    info: "Training resources for health professionals",
  },
];

export const domesticViolenceCardData = [
  {
    logo: "/images/online-program-icon.svg",
    info: "Online program to help you manage your situation",
  },
  {
    logo: "/images/helpful-activites-icon.svg",
    info: "Helpful activities",
  },
  {
    logo: "/images/real-stories-icon.svg",
    info: "Real stories: What it’s like supporting someone using ice",
  },
  {
    logo: "/images/resources-icon.svg",
    info: "Training resources for health professionals",
  },
];
