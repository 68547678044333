import React, { useState } from "react";
import styled from "styled-components";

// **************styled Components********************
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.size ? `${props.size + 20}px` : "20px")};
  border-radius: 50%;
  height: 1em;
  width: 1em;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: ${props => (props.show ? "#4BC1C9" : "#E1E1E1")};
  color: ${props => (props.show ? "#fff" : "#000")};
  transform: ${props => `rotate(${props.deg}deg)`};
  &:focus {
    outline: none;
  }
`;

const Icon = styled.span`
  line-height: 11px;
  font-size: ${props => (props.size ? `${props.size + 14}px` : "14px")};
`;

// **************funtions*****************************

// **************styled Components********************

const ShowHideButton = props => {
  const { direction, isShow, isClicked, size } = props;
  const [degree, setDegree] = useState(null);
  const [show, setShow] = useState(isShow || false);
  let deg = 0;

  switch (direction) {
    case "top":
      deg = 90;
      break;
    case "left":
      deg = 0;
      break;
    case "right":
      deg = 180;
      break;
    case "bottom":
      deg = -90;
      break;
    default:
      deg = 0;
      break;
  }

  const handleClick = () => {
    if (degree === null) {
      setDegree(deg + 180);
    } else setDegree(degree + 180);
    setShow(!show);
    isClicked();
  };

  return (
    <Button
      deg={degree === null ? deg : degree}
      onClick={handleClick}
      show={show}
      size={size}
    >
      <Icon size={size}>❮</Icon>
    </Button>
  );
};

export default ShowHideButton;
