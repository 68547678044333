import React, { useState } from "react";
import GetUserBookmarks from "../../middleware/ContentPage/getBooksmarksPerGroup";
import CollapseList from "../../components/UI/Dropdown/CollapseList";
import {
  Wrapper,
  Container,
  Section,
  NoBookmarkWrapper,
  BookmarkIcon,
  NoBookmarkTitle,
  NoBookmarkDesciption,
} from "./styled";
import BookmarkActive from "../../assets/img/toolkit/Bookmark.svg";

const Content = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [groups, setGroups] = useState([]);

  const handleLoadBookmarks = (data, type) => {
    if (type === "data") {
      const tmpGroup = data.reduce((validGroups, group) => {
        if (group.contentGroup) {
          const bookmarks =
            group.contentGroup &&
            group.contentGroup.contentPages.length > 0 &&
            group.contentGroup.contentPages.filter(
              (page) => page.bookmark !== null
            );

          if (bookmarks && bookmarks.length) {
            validGroups.push({
              userFlowStepId: group.id,
              userFlowStepUrl: group.url,
              groupId: group.contentGroup.id,
              groupTitle: group.contentGroup.title,
              bookmarks,
            });
          }
        }
        return validGroups;
      }, []);
      setGroups(tmpGroup);
    }
    setIsLoaded(true);
  };

  return (
    <Wrapper>
      <Container>
        {groups.length > 0 ? (
          groups.map((group) => (
            <Section key={group.groupId}>
              <CollapseList
                title={group.groupTitle}
                pageData={group.bookmarks}
                userFlowStepUrl={group.userFlowStepUrl}
              />
            </Section>
          ))
        ) : (
          <NoBookmarkWrapper>
            <BookmarkIcon alt="bookmark icon" src={BookmarkActive} />
            <NoBookmarkTitle>
              Complete your first module to view your bookmarks.
            </NoBookmarkTitle>
            <NoBookmarkDesciption>
              If you see something you think you might like to look at again
              within a module, make sure you click the bookmark icon on the page
              so you can find it here later.
            </NoBookmarkDesciption>
          </NoBookmarkWrapper>
        )}
      </Container>
      {!isLoaded && (
        <GetUserBookmarks
          userFlowId={Number(process.env.REACT_APP_USERFLOW_ID)}
          onComplete={handleLoadBookmarks}
        />
      )}
    </Wrapper>
  );
};

export default Content;
