import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import LeftSideBar from "./LeftSideBar";
// utils
import { findNewPage } from "../../utils/utils";

const MenuPointer = styled.div`
  display: inline-block;
  margin-right: 0.5em;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  transform: ${(props) => props.active && props.hasChildren && "rotate(90deg)"};
`;

const MenuTitle = styled.a`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0.5em 3rem 0.5em 0;
  margin-left: ${(props) => `${-2 - props.level}em`};
  padding-left: ${(props) => `${props.level * 0.5 + 0.5}em`};
  color: ${(props) => (props.active ? "cornflowerblue" : "inherit")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  background-color: ${(props) => (props.active ? "#fff" : "inherit")};
  &:hover {
    color: #000;
    background-color: #fff;
    ${MenuPointer} {
      visibility: visible;
    }
  }
`;

const MenuListItem = styled.li`
  list-style: none;
  /* margin-left: -2.5rem; */
  margin-left: ${(props) => `${2 * props.level - 2.5}rem`};
`;

const MenuItem = styled.ul`
  display: flex;
  flex-flow: column;
  cursor: pointer;
  margin: ${(props) => (props.level < 1 ? "0 -2em" : `0 -${props.level + 1}em`)};
  padding-left: ${(props) => `${props.level + 2}em`};
  color: ${(props) => (props.active ? "#000" : "#fff")};
  background-color: ${(props) =>
    props.active ? `rgba(255, 255, 255, ${(props.level + 3) / 10})` : "inherit"};

  width: ${(props) => (props.level > 0 ? "calc(100% - 1em)" : "calc(100% + 1em)%")};
`;

const Title = styled.p`
  margin: 0;
  max-width: 14em;
  @media (min-width: 1600px) {
    max-width: 18em;
  }
`;

const Circle = styled.span`
  padding: 0px 4.8px;
  border: 1px solid;
  border-radius: 50%;
  color: inherit;
  position: absolute;
  right: 1em;
`;

const Completed = styled(Circle)`
  background-color: #07c734;
`;

const ContentNavBar = (props) => {
  const {
    pageId,
    pages,
    getPageId,
    isShowed,
    groupTitle,
    pagePath,
    getPagePath,
    pageLvlIndex,
    match,
    history,
    endStackingPage,
  } = props;
  const [currentPageId, setCurrentPageId] = useState(null);
  const [navPath, setNavPath] = useState([]);
  const userFlowId = match.params.id;

  useEffect(() => {
    if (navPath === []) {
      setNavPath([pageId]);
    } else {
      setNavPath(pagePath);
    }

    setCurrentPageId(pageId);
  }, [navPath, pageId, pagePath]);

  const handleClick = (id, level, e) => {
    e.preventDefault();
    endStackingPage();
    let tmpArr = [];
    if (level === 0) {
      getPagePath([id]);
      getPageId(id);
    } else {
      for (let i = 0; i < level; i++) {
        tmpArr.push(navPath[i]);
      }
      tmpArr.push(id);
      getPagePath(tmpArr);
      getPageId(id);
    }
    const curURL = getUrl(id);
    history.push(curURL);
  };

  const handleShowSideBar = (show) => {
    isShowed(show);
  };

  const getUrl = (id) => {
    const newPage = findNewPage(pages, id, pageLvlIndex);
    return `/content/${match.params.id}/${match.params.moduleUrl}/${newPage.url}`;
  };

  const createMenu = (pages, level) =>
    pages
      .sort((a, b) => a.sort - b.sort)
      .map((item) => (
        <MenuListItem key={item.id} level={level}>
          <MenuItem
            active={item.id === navPath[level]}
            level={level}
            isCompleted={item.isCompleted}
          >
            <MenuTitle
              active={item.id === currentPageId}
              level={level}
              href={getUrl(item.id)}
              onClick={
                item.isCompleted
                  ? (e) => handleClick(item.id, level, e)
                  : (e) => {
                      e.preventDefault();
                    }
              }
            >
              <MenuPointer
                active={item.id === navPath[level]}
                hasChildren={item.contentPageChildren.length > 0}
              >
                ‣
              </MenuPointer>
              <Title>{item.title}</Title>
              {!item.isCompleted ? <Circle>&#10003;</Circle> : <Completed>&#10003;</Completed>}
            </MenuTitle>
            {item.contentPageChildren &&
              item.id === navPath[level] &&
              createMenu(item.contentPageChildren, level + 1)}
          </MenuItem>
        </MenuListItem>
      ));

  return (
    <LeftSideBar
      title={groupTitle}
      userFlowId={userFlowId}
      isShowed={handleShowSideBar}
      defaultOpen
    >
      {pages && createMenu(pages, 0)}
    </LeftSideBar>
  );
};

export default withRouter(ContentNavBar);
