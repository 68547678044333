import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-image: linear-gradient(
    to right,
    rgba(0, 164, 183, 1),
    rgba(187, 230, 234, 1)
  );
  color: white;
  padding: 1rem;
  width: 40%;
  position: absolute;
  right: 30%;
  bottom: 3.3rem;
  border-radius: 10px;
`;

const NoticeMessage = ({ message, resetMsg }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      resetMsg();
    }, 2000);
    setShow(true);
    return () => clearTimeout(timer);
  }, [message, resetMsg]);

  return <>{show && <Wrapper>{message}</Wrapper>}</>;
};

export default NoticeMessage;
