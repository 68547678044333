import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
// import Cookies from "universal-cookie";
import introspectionQueryResultData from "../fragmentTypes.json";
import { _GetAccessToken } from "@netfront/gelada-react-shared";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// const cookies = new Cookies();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_EKARDO_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = _GetAccessToken();
  const header = token
    ? {
        ...headers,
        authorization: token,
        Platform: "Web",
      }
    : {
        ...headers,
      };
  return {
    headers: header,
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;
