import React from "react";
import { _GetAccessToken } from "@netfront/gelada-react-shared";

import ContactForm from "../../components/Form/ContactForm";
import { SectionTitle, P, SectionContainer, OuterWrapper } from "./styled";
import { Wrapper } from "../ConsolePage/styled";
import PageHelmet from "../../components/PageHelmet/index";
import ConsoleHeader from "../../components/Header/ConsoleHeader";
import WhiteHeader from "../../components/Header/WhiteHeader";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import Footer from "../../components/Footer/Footer";

const ContactPage = () => {
  return (
    <Wrapper>
      <PageHelmet title="FFSP: Contact" />
      {_GetAccessToken() ? <ConsoleHeader /> : <WhiteHeader />}
      <SecondaryHeader size="sm" title="Contact" />
      <OuterWrapper>
        <SectionContainer size="sm">
          <SectionTitle>
            Questions/comments about family and friend support program
          </SectionTitle>
          <P>
            If you have any questions about the Family and Friend Support
            Program please use the form below:
          </P>
          <P>We will answer your email within 48 hours.</P>
          <br />
          <ContactForm />
        </SectionContainer>
      </OuterWrapper>
      <Footer />
    </Wrapper>
  );
};

export default ContactPage;
