import React from "react";
import styled from "styled-components";

// **************styled Components********************
const Wrapper = styled.div`
  position: relative;
  border: none;
  display: flex;
  width: 80%;
  padding: 2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #231f2040;
  border-radius: 23px;
  @media only screen and (max-width: 1024px) {
    transform: scale(0.8);
    flex-flow: column wrap;
    align-items: center;
    padding: 1rem;
  }
  @media only screen and (max-width: 767px) {
    transform: unset;
  }
`;

const TitleBox = styled.div`
  width: 60%;
  text-align: center;
  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-size: 2.2em;
  font-weight: 500;
  @media only screen and (max-width: 1024px) {
    margin: 0.4em 0;
  }
`;

const LogoBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
  color: #373c5b;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    position: relative;
    top: -5rem;
  }
`;

const Logo = styled.img`
  width: 16em;
  @media (max-width: 1024px) {
    width: 8em;
  }
  @media (max-width: 767px) {
    width: 6em;
  }
`;

const FormContainer = styled.div`
  width: 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: -3em;
  }
`;
// **************styled Components********************

const loginContainer = props => {
  const { children, logo, title, description } = props;
  return (
    <Wrapper>
      <LogoBox>
        <Logo src={logo} alt="icon" />
        <TitleBox>
          <Title>{title}</Title>
          <p>{description}</p>
        </TitleBox>
      </LogoBox>
      <FormContainer>{children}</FormContainer>
    </Wrapper>
  );
};

export default loginContainer;
