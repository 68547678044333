export const programCardData = [
  {
    id: 0,
    logo: "/images/ffsp-logo.svg",
    info: "Program for people supporting someone who is using",
    highlightedText: "ice",
    buttonText: "LEARN MORE",
    link: "/ice",
    themeColor: "#eb648c",
    textColor: "#551a8b",
    borderColor: "#551a8b",
  },
  {
    id: 1,
    logo: "images/aod-logo.svg",
    info: "Program for people supporting someone who is using",
    highlightedText: "alcohol and/or other drugs.",
    buttonText: "LEARN MORE",
    link: "/aod",
    themeColor: "#0383ba",
    textColor: "#551a8b",
    borderColor: "#551a8b",
  },
  {
    id: 2,
    logo: "/images/domestic-violence-logo.svg",
    logoSize: "4em",
    info: "Program for people who are experiencing ",
    highlightedText: "domestic and family violence.",
    buttonText: "LEARN MORE",
    link: "/domestic-violence",
    themeColor: "#978ef5",
    textColor: "#551a8b",
    borderColor: "#551a8b",
  },
  {
    id: 3,
    logo: "/images/rural-logo.svg",
    logoSize: "4.2rem",
    info: "Program for people who live in",
    highlightedText: "rural or remote regions.",
    buttonText: "LEARN MORE",
    link: "/rural-remote",
    themeColor: "#a85b54",
    textColor: "#551a8b",
    borderColor: "#551a8b",
  },
];
