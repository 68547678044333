import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-image: linear-gradient(to right, #d87367, #cc5b6d);
  color: white;
  padding: 1em;
  width: 40%;
  position: absolute;
  right: 30%;
  bottom: 3.3em;
  border-radius: 10px;
`;

const QueryError = ({ message, onError }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      onError(message);
    }, 2000);

    setShow(true);
    return () => clearTimeout(timer);
  }, [message, onError]);

  return <>{show && <Wrapper>{message}</Wrapper>}</>;
};

export default QueryError;
