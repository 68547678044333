import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Card = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px #231F2026;
  color: #000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  text-align: center;

  img {
    min-height: 90px;
  }
`

const UserFlowCard = props => {
    
  const { children, externalLink, link } = props;
  let history = useHistory();

  return (
    <Card onClick={() => externalLink ? window.location.href = externalLink : history.push(link)}>
      {children}
    </Card>
  );
};

export default UserFlowCard;
