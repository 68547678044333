import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const LinkButton = styled(Link)`
  border: 2px solid;
  display: ${({ display }) => display};
  text-align: ${({ textalign }) => textalign};
  color: ${({ textcolor }) => textcolor};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  border-color: ${({ bordercolor }) => bordercolor};
  font-size: 1rem;
  font-weight: bold;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  padding: ${({ fatbuttonpadding }) =>
    fatbuttonpadding ? fatbuttonpadding : "0.7rem 0.8rem"};
  border-radius: 4rem;
  min-width: 10rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    transform: scale(1.02);
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 0.7rem;
  }

  @media only screen and (max-width: 767px) {
    min-width: unset;
    width: 7rem;
    font-size: 0.7rem;
  }
`;

const ButtonWithLink = ({
  link,
  buttonText,
  textColor,
  borderColor,
  display,
  textAlign,
  margin,
  fatButtonPadding,
  backgroundColor,
  themeColor,
}) => {
  return (
    <LinkButton
      to={link}
      fatbuttonpadding={fatButtonPadding}
      textcolor={textColor}
      bordercolor={borderColor}
      themecolor={themeColor}
      backgroundcolor={backgroundColor}
      display={display}
      textalign={textAlign}
      margin={margin}
    >
      {buttonText}
    </LinkButton>
  );
};

export default ButtonWithLink;
