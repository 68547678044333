import React, { useEffect } from "react";
import WhiteHeader from "../../components/Header/WhiteHeader";
import Footer from "../../components/Footer/Footer";
import { programCardData } from "../PageData/pageData";
import CardContainer from "../../containers/CardContainer/CardContianer";

import { scrollToTop } from "../../utils/utils";

import {
  Wrapper,
  RightSection,
  LeftSection,
  DynamicContainer,
  Title,
  Strong,
  P,
} from "./style";

const alternateCardProperties = {
  height: "12rem",
  width: "11rem",
  LargeHeight: "18rem",
  LargeWidth: "17rem",
  fontSize: "0.9rem",
  justifyContent: "center",
  LargeScreenMaxWidth: "56rem",
};

const AllProgramsPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <WhiteHeader showProgramsNav={false} />
      <Wrapper>
        <LeftSection>
          <DynamicContainer padding={"0 2rem"}>
            <Title textcolor={"white"}>
              <Strong color={"#0383BA"}>Family and friends support </Strong>
              Programs
            </Title>
            <P
              textcolor={"white"}
              regularFontSize={"1rem"}
              mobileFontSize={"0.8rem"}
            >
              These are a set of online interventions and support packages
              designed by experts to help families and friends supporting a
              loved one who may be using ice, alcohol and/or other drugs. They
              also offer information and support for people who may be
              experiencing domestic and family violence, as well as people who
              are living in rural or remote regions.
            </P>
          </DynamicContainer>
        </LeftSection>
        <RightSection>
          <DynamicContainer padding={"0 2rem"}>
            <Title margin={"0 0 4rem 0"}>
              For more information, or to sign up, click on any of the available
              <Strong color={"#0383BA"}> programs </Strong>below:
            </Title>
            <CardContainer
              cardData={programCardData}
              alternateCardProperties={alternateCardProperties}
            />
          </DynamicContainer>
        </RightSection>
      </Wrapper>
      <Footer />
    </>
  );
};

export default AllProgramsPage;
