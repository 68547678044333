import React, { useEffect } from "react";
import { ruralCardData } from "./programData";
import ButtonWithLink from "../../components/UI/Button/ButtonWithLink";

import ProjectPartnersFooter from "../../components/Footer/ProjectPartnersFooter";
import WhiteHeader from "../../components/Header/WhiteHeader";
import ProgramCardContainer from "../../containers/CardContainer/ProgramCardContainer";

import { scrollToTop } from "../../utils/utils";

import {
  DynamicContainer,
  GraphicBar,
  SectionContainer,
  IconContainer,
  TitleIcon,
  Title,
  Strong,
  P,
} from "./style";

const RuralRemotePage = () => {
  const themeColor = "#A85B54";
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <WhiteHeader themeColor={themeColor} showProgramsNav={true} />
      <GraphicBar
        background={themeColor}
        height={"17rem"}
        marginTop={"11.2rem"}
        url={"/images/rural-bg-light.svg"}
      >
        <DynamicContainer
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          smallScreenMargin={"-2.2rem auto"}
          padding={"0"}
          smallScreenFlex={"flex"}
          smallScreenStack={"column"}
          smallScreenJustifyContent={"center"}
        >
          <IconContainer smallScreenMargin={"1rem"}>
            <TitleIcon src={"/images/rural-logo.svg"} alt="remote-icon" />
          </IconContainer>
          <Title textcolor={"white"} margin={"0"}>
            Awareness. Information. Support.
          </Title>
        </DynamicContainer>
      </GraphicBar>
      <DynamicContainer height={"auto"}>
        <Title>
          What is the program for people who live in
          <Strong color={themeColor}> rural or remote regions? </Strong>
        </Title>
        <P regularFontSize={"1rem"} mobileFontSize={"0.8rem"}>
          This module is designed for people who are living in a rural or remote
          area of Australia and would like some extra resources for supporting
          themselves or others during difficult times. You may be supporting
          someone who is using alcohol and/or other drugs and would like some
          information about how you can best care for and support them in rural
          or remote parts of the country, where other resources or supports
          might be limited or more difficult to access. This module is therefore
          designed to provide you with knowledge about some of the experiences,
          strengths, and challenges, that people who live in rural or remote
          areas may come across, and how you can identify and respond to some of
          these either in your own life, or when supporting someone who is using
          alcohol and/or other drugs.
        </P>
        <ButtonWithLink
          fatButtonPadding={"1.2rem 0.8rem"}
          link={"/register"}
          textColor={"#373C5B"}
          backgroundColor={"#63f3f5"}
          borderColor={"#63f3f5"}
          buttonText={"REGISTER"}
          display={"inline-block"}
          textAlign={"center"}
        />

        <ButtonWithLink
          fatButtonPadding={"1.2rem 0.8rem"}
          link={"/login"}
          textColor={"#373C5B"}
          backgroundColor={"#63f3f5"}
          borderColor={"#63f3f5"}
          buttonText={"LOGIN"}
          display={"inline-block"}
          textAlign={"center"}
          margin={"0 0 1.5rem 0"}
        />
      </DynamicContainer>
      <SectionContainer backgroundColor={"#F4F4F9"} padding={"10rem 0 15rem 0"}>
        <DynamicContainer padding={"0 0 7rem 0"}>
          <Title>
            What the program for people who live in
            <Strong color={themeColor}> rural or remote regions</Strong> offers
          </Title>
          <P>
            A free online, information-based program and access to trusted and
            reliable information for people in rural or remote regions to assist
            in supporting themselves or their loved ones.
          </P>
        </DynamicContainer>
      </SectionContainer>
      <GraphicBar
        background={themeColor}
        tabletHeight={"auto"}
        url={"/images/rural-bg-dark.svg"}
        offsetMarginForCardTransition={"-5rem"}
      >
        <DynamicContainer transform={"translateY(-20%)"}>
          <ProgramCardContainer
            cardData={ruralCardData}
            themeColor={"#373C5B"}
          />
          <Title textcolor={"white"} margin={"0 0 2rem 0"}>
            Get access to the program for people who live in rural or remote
            regions.
          </Title>
          <ButtonWithLink
            fatButtonPadding={"1.2rem 0.8rem"}
            link={"/register"}
            textColor={"#373C5B"}
            backgroundColor={"#63f3f5"}
            borderColor={"#63f3f5"}
            buttonText={"REGISTER"}
            display={"inline-block"}
            textAlign={"center"}
          />
          <ButtonWithLink
            fatButtonPadding={"1.2rem 0.8rem"}
            link={"/login"}
            textColor={"#373C5B"}
            backgroundColor={"#63f3f5"}
            borderColor={"#63f3f5"}
            buttonText={"LOGIN"}
            display={"inline-block"}
            textAlign={"center"}
            margin={"0 0 1.5rem 0"}
          />
        </DynamicContainer>
      </GraphicBar>

      <ProjectPartnersFooter themeColor={themeColor} textColor={"white"} />
    </>
  );
};

export default RuralRemotePage;
