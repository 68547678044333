import React, { useState, useEffect, useRef } from "react";
import {
  ContentWrapper,
  GetNavMenu,
  StartUserflowStep as StartTrackModule,
  StartContentPageCompletion as StartTrackPage,
  EndContentPageCompletion as CompleteTrackPage,
  EndUserFlowStep as CompleteTrackModule,
} from "@netfront/ekardo-shared-library";
// Components
import PageHelmet from "../../components/PageHelmet/index";
import Header from "../../components/Header/ConsoleHeader";
import Footer from "../../components/Footer/Footer";
import NormalBtn from "../../components/UI/Button/Button";
import ContentNavBar from "../../components/LeftSideBar/ContentNavBar";
import Toolkit from "../../components/UI/SideToolkit/SideToolkit";
import {
  ContentContainer,
  Wrapper,
  ToolkitBox,
  Container,
  BtnGroup,
  SaveLink,
  BarContainer,
  ProcessBar,
  ProgressIndicator,
  FooterWrapper,
} from "./styled";
// GraphQL
import GetStepByUrl from "../../middleware/Userflow/getUserFlowStepByUrl";
import GetPageByUrl from "../../middleware/ContentPage/getPageByUrl";
// stylesheets
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../components/Snippet/Slider.css";
import "./styled.css";
// utils
import { scrollToTop, TreeFormat, unflatten, findNewPage } from "../../utils/utils";
import { useSetLoadingState } from "../../context/LoadingContext";
import "react-h5-audio-player/lib/styles.css";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { EkardoClient } from "../../middleware/EkardoClient";
import { useProjectCMSClasses } from "../../hooks/useProjectCMSClasses";

const VALIDATION_QUERY = gql`
  query validateRegistration($contentPageId: Int!, $trackingId: Int!, $userFlowStepTrackId: Int!) {
    questionAnswer {
      validateSurvey(contentPageId: $contentPageId, trackingId: $trackingId) {
        question
        mandatory
        answers(contentPageId: $contentPageId, userFlowStepTrackId: $userFlowStepTrackId) {
          id
        }
      }
    }
  }
`;

const ContentPage = (props) => {
  const { projectClasses } = useProjectCMSClasses({
    projectId: process.env.REACT_APP_PROJECT_ID,
  });

  const { match, history } = props;
  const setIsLoading = useSetLoadingState();

  const [stepLoaded, setStepLoaded] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [navLoaded, setNavLoaded] = useState(true);
  const [groupId, setGroupId] = useState(null);
  const [stepId, setStepId] = useState(null);
  const [startStep, setStartStep] = useState(true);
  const [startPage, setStartPage] = useState(false);
  const [stepTrackingId, setStepTrackingId] = useState(null);
  const [trackingId, setTrackingId] = useState(null);
  const [endStep, setEndStep] = useState(true);
  const [endPage, setEndPage] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [completedRequest, setCompletedRequest] = useState(false);

  const [pages, setPages] = useState([]);
  const [pageId, setPageId] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isShowNav, setIsShowNav] = useState(true);
  const [percent, setPercent] = useState("");
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [showNextBtn, setShowNextBtn] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [curPage, setCurPage] = useState({});
  const [currentPath, setCurrentPath] = useState([]);
  const [menuArray, setMenuArray] = useState([]);
  const [pageLvlIndex, setPageLvlIndex] = useState({});

  const isSubLanding = match.params.moduleUrl.includes("mini-module-4");

  const [isValidating, setIsValidating] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const client = useRef(EkardoClient());
  const userFlowId = props.match.params.id;
  const [pageRequestValidation] = useLazyQuery(VALIDATION_QUERY, {
    client: client.current,
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const valid =
        data.questionAnswer.validateSurvey.filter(
          ({ manditory, answers }) => !manditory && !answers
        ).length === 0;
      setIsValidating(true);
      setIsValid(valid);
      if (valid) {
        const i = menuArray.indexOf(pageId);
        const newPageId = i + 1 >= menuArray.length ? menuArray[0] : menuArray[i + 1];
        if (newPageId === menuArray[menuArray.length - 1]) setShowNextBtn(false);
        setShowBackBtn(true);
        setEndPage(false);

        // update page status
        const newPages = [...pages];
        const tmpPath = pageLvlIndex[pageId];
        let thePage = newPages.find((page) => page.id === tmpPath[0]);
        thePage.isCompleted = true;
        for (let i = 1; i < tmpPath.length; i++) {
          thePage = changeChildStatus(thePage, tmpPath[i]);
        }
        setPages(newPages);
        updateNewPage(newPageId);
        setIsValidating(false);
      }
    },
  });

  const [lastPageRequestValidation] = useLazyQuery(VALIDATION_QUERY, {
    client: client.current,
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const valid = data.questionAnswer.validateSurvey.length === 0;
      setIsValidating(true);
      setIsValid(valid);

      if (valid) {
        setCompletedRequest(true);
        setEndPage(false);
        setIsLoading(true);
        setIsValidating(false);
      }
    },
  });

  useEffect(() => {
    setStartPage(false);
  }, [match.params.pageUrl]);

  const handleGetPageId = (id) => {
    setPageId(id);
    const currentPage = findNewPage(pages, id, pageLvlIndex);
    const i = menuArray.indexOf(id);
    setPercent(parseFloat((i + (pages[i].isCompleted ? 1 : 0)) / menuArray.length).toFixed(2));
    setCurPage(currentPage);
    setIsBookmarked(currentPage.isBookmarked);
    if (id === menuArray[menuArray.length - 1]) {
      setShowNextBtn(false);
    } else {
      setShowNextBtn(true);
    }
    if (id === menuArray[0]) {
      setShowBackBtn(false);
    } else {
      setShowBackBtn(true);
    }
  };

  const handleGetPagePath = (path) => {
    setCurrentPath(path);
  };

  const handleShowNav = (show) => {
    setIsShowNav(show);
  };

  const handleClickComplete = () => {
    lastPageRequestValidation({
      variables: {
        contentPageId: pageId,
        trackingId,
        userFlowStepTrackId: stepTrackingId,
      },
    });
  };

  const changeChildStatus = (page, id) => {
    const childPages = page.contentPageChildren;
    const thePage = childPages.find((page) => page.id === id);
    thePage.isCompleted = true;
    return thePage;
  };

  const updateNewPage = (newPageId) => {
    const newPage = findNewPage(pages, newPageId, pageLvlIndex);
    let i = menuArray.indexOf(newPageId);

    if (pages[i]) {
      setPercent(parseFloat((i + (pages[i].isCompleted ? 1 : 0)) / menuArray.length).toFixed(2));
    }

    setIsBookmarked(newPage.isBookmarked);
    setPageId(newPageId);
    setCurrentPath(pageLvlIndex[newPageId]);
    setCurPage(newPage);
    scrollToTop();
    setIsLoading(true);
    history.push(`/content/${userFlowId}/${match.params.moduleUrl}/${newPage.url}`);
  };

  const handleClickPrev = () => {
    const i = menuArray.indexOf(pageId);
    const newPageId = i - 1 < 0 ? menuArray[menuArray.length - 1] : menuArray[i - 1];
    if (newPageId === menuArray[0]) setShowBackBtn(false);
    setShowNextBtn(true);
    setEndPage(false);
    updateNewPage(newPageId);
  };

  const handleClickNext = () => {
    console.log("next clicked");
    pageRequestValidation({
      variables: {
        contentPageId: pageId,
        trackingId,
        userFlowStepTrackId: stepTrackingId,
      },
    });
  };

  const handleCompleteBtn = () => {
    if (match.params.id === "domestic-violence" || match.params.id === "rural-and-remote")
      history.push("/dashboard");
    else if (match.params.moduleUrl === "family-member-questionnaire") history.push("/user-guide");
    else
      history.push(
        isSubLanding ? "/console/alcohol-or-other-drugs/mini-module-4" : `/console/${userFlowId}`
      );
  };

  const handleUpdateBookmark = (value) => {
    setIsBookmarked(value);
    const tmpPages = [...pages];
    const i = tmpPages.findIndex((page) => page.id === pageId);
    tmpPages[i].isBookmarked = value;
    setPages(tmpPages);
  };

  // const handleStepCookie = (id) => cookies.set('stepId', id);

  // *********************** handle query data ****************************

  const handleGetContentNav = (data, type) => {
    if (type === "data") {
      const tree = unflatten(data.pages);
      const tmp = TreeFormat(tree, 0, [], [], {});

      setGroupName(data.title);
      setPages(tree);
      setMenuArray(tmp[0]);
      setPageLvlIndex(tmp[1]);
      setCurrentPath(tmp[1][pageId]);
      const i = tmp[0].indexOf(pageId);
      const perc = parseFloat((i + (tree[i]?.isCompleted ? 1 : 0)) / tmp[0].length).toFixed(2);
      setPercent(perc);

      if (pageId === tmp[0][tmp[0].length - 1]) {
        setShowNextBtn(false);
      } else {
        setShowNextBtn(true);
      }
      if (pageId === tmp[0][0]) {
        setShowBackBtn(false);
      } else {
        setShowBackBtn(true);
      }
    }
    setNavLoaded(true);
    setIsLoading(false);
  };

  const handleGetStepInfo = (data, type) => {
    if (type === "data") {
      if (data.repetitionAllowed > data.userRepetition.length) {
        setStartStep(false);
      } else {
        if (data.isComplete) setIsCompleted(true);
        setStepTrackingId(data.userRepetition[0].id);
        // handleStepCookie(data.userRepetition[0].id);
      }
      let tmpPages = {};
      data.contentGroup.contentPages.map((item) => {
        tmpPages = {
          ...tmpPages,
          [item.id]: item.url,
        };
        return null;
      });
      setGroupId(data.contentGroup.id);
      setStepId(data.id);
    }
    setStepLoaded(true);
    setIsLoading(false);
  };

  const handleGetPageByUrl = (data, type) => {
    if (type === "data") {
      setGroupId(data.contentGroup.id);
      setPageId(data.id);
      setCurPage(data);
      setIsBookmarked(data.isBookmarked);
    }
    setPageLoaded(true);
    setNavLoaded(false);
    setIsLoading(false);
  };

  const handleStartStepTrack = (data, type) => {
    if (type === "data") {
      setStepTrackingId(data.id);
      // handleStepCookie(data.id);
    }
    setStartStep(true);
    setIsLoading(false);
  };

  const handleStartPageTrack = (data, type) => {
    if (type === "data") {
      setTrackingId(data.id);
    }
    setStartPage(true);
    setIsLoading(false);
  };

  const handleEndPageTrack = (data, type) => {
    setEndPage(false);
    scrollToTop();
    if (completedRequest) {
      if (isCompleted) {
        handleCompleteBtn();
      } else {
        setEndStep(false);
      }
    }
    setIsLoading(false);
    setEndPage(true);
  };

  const handleEndStepTrack = (data, type) => {
    if (type === "data") {
    }
    setEndStep(true);
    setIsLoading(false);
    handleCompleteBtn();
  };

  return (
    <Wrapper
      className={`ek-content-wrapper ${isValidating ? "show-validation" : ""}`}
      bgType={userFlowId}
    >
      <PageHelmet title={`FFSP: ${groupName}-${curPage?.title}`}></PageHelmet>

      <Header />
      <ContentContainer>
        <ContentNavBar
          pageId={pageId}
          pages={pages}
          getPageId={handleGetPageId}
          getPagePath={handleGetPagePath}
          endStackingPage={() => setEndPage(false)}
          isShowed={handleShowNav}
          groupTitle={groupName}
          pagePath={currentPath}
          pageLvlIndex={pageLvlIndex}
          bgType={userFlowId}
        />
        <Container isShowNav={isShowNav}>
          {pageId && stepTrackingId && (
            <ContentWrapper contentGroupId={0} pageId={pageId} trackingId={stepTrackingId} />
          )}
          <ToolkitBox isShowNav={isShowNav}>
            <Toolkit
              pageId={pageId}
              isBookmarked={isBookmarked}
              setBookmarkState={handleUpdateBookmark}
            />
          </ToolkitBox>

          <BtnGroup style={{ alignItems: "baseline" }}>
            {isValidating && !isValid && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  padding: "1rem",
                  borderRadius: "5px",
                  marginBottom: "3rem",
                }}
              >
                Please check your responses for any issues.
              </div>
            )}
            <SaveLink
              href={
                isSubLanding
                  ? "/console/alcohol-or-other-drugs/mini-module-4"
                  : `/console/${userFlowId}`
              }
            >
              <NormalBtn bg="#373C5B" color="#fff">
                Save &amp; Exit
              </NormalBtn>
            </SaveLink>
            {showBackBtn && (
              <NormalBtn borderColor="#373C5B" color="#373C5B" isClicked={handleClickPrev}>
                Back
              </NormalBtn>
            )}
            {showNextBtn && (
              <NormalBtn borderColor="#00A4B7" color="#00A4B7" isClicked={handleClickNext}>
                Next
              </NormalBtn>
            )}
            {!showNextBtn && (
              <NormalBtn bg="#FFCE78" color="#000" isClicked={handleClickComplete}>
                {match.params.moduleUrl === "family-member-questionnaire"
                  ? "Get started"
                  : "Complete"}
              </NormalBtn>
            )}
          </BtnGroup>
        </Container>
      </ContentContainer>
      <FooterWrapper>
        <BarContainer isShowNav={isShowNav}>
          <ProcessBar percent={percent} />
          <ProgressIndicator>{parseInt(percent * 100)}% complete</ProgressIndicator>
        </BarContainer>
        <Footer />
      </FooterWrapper>
      {!stepLoaded && (
        <GetStepByUrl
          onComplete={handleGetStepInfo}
          userFlowStepUrl={match.params.moduleUrl}
          userFlowUrl={match.params.id}
          projectId={process.env.REACT_APP_PROJECT_ID}
        />
      )}
      {!pageLoaded && (
        <GetPageByUrl
          onComplete={handleGetPageByUrl}
          projectId={process.env.REACT_APP_PROJECT_ID}
          contentPageUrl={match.params.pageUrl}
        />
      )}
      {!navLoaded && groupId && stepTrackingId && (
        <GetNavMenu
          contentGroupId={groupId}
          stepTrackingId={stepTrackingId}
          onComplete={handleGetContentNav}
        />
      )}
      {!startStep && !isCompleted && stepId && (
        <StartTrackModule stepId={stepId} onComplete={handleStartStepTrack} />
      )}
      {!startPage && stepTrackingId && pageId && (
        <StartTrackPage
          trackingId={Number(stepTrackingId)}
          pageId={pageId}
          onComplete={handleStartPageTrack}
        />
      )}
      {!endPage && trackingId && (
        <CompleteTrackPage trackingId={trackingId} onComplete={handleEndPageTrack} />
      )}
      {!endStep && !isCompleted && stepTrackingId && (
        <CompleteTrackModule trackingId={Number(stepTrackingId)} onComplete={handleEndStepTrack} />
      )}
    </Wrapper>
  );
};

export default ContentPage;
