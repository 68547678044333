import React, { useEffect } from "react";
import { domesticViolenceCardData } from "./programData";
import ButtonWithLink from "../../components/UI/Button/ButtonWithLink";
import ProgramCardContainer from "../../containers/CardContainer/ProgramCardContainer";

import ProjectPartnersFooter from "../../components/Footer/ProjectPartnersFooter";
import WhiteHeader from "../../components/Header/WhiteHeader";

import { scrollToTop } from "../../utils/utils";

import {
  DynamicContainer,
  GraphicBar,
  SectionContainer,
  IconContainer,
  TitleIcon,
  Title,
  Strong,
  P,
} from "./style";

const DomesticViolencePage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const themeColor = "#706AA8";
  return (
    <>
      <WhiteHeader themeColor={themeColor} showProgramsNav={true} />
      <GraphicBar
        height={"17rem"}
        marginTop={"11.2rem"}
        url={"/images/domestic-pattern-dark.svg"}
      >
        <DynamicContainer
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          padding={"0"}
          smallScreenMargin={"-2.2rem auto"}
          smallScreenFlex={"flex"}
          smallScreenStack={"column"}
          smallScreenJustifyContent={"center"}
        >
          <IconContainer smallScreenMargin={"1rem"}>
            <TitleIcon
              src={"/images/domestic-violence-logo.svg"}
              alt="domestic-violence-icon"
            />
          </IconContainer>
          <Title textcolor={"white"} margin={"0"}>
            Awareness. Information. Support.
          </Title>
        </DynamicContainer>
      </GraphicBar>
      <DynamicContainer height={"auto"}>
        <Title>
          What is the program for people who are experiencing{" "}
          <Strong color={themeColor}> domestic and family violence?</Strong>
        </Title>
        <P regularFontSize={"1rem"} mobileFontSize={"0.8rem"}>
          This program is designed to provide people with information about how
          they can help support themselves, or a family member or friend, who
          may be experiencing some form of violence. In any violence situation,
          seeking information and support is a big first step, which we want to
          acknowledge. It can be challenging to speak about what is happening,
          and even more challenging to seek support. This module is therefore
          designed to provide people with knowledge about how they can identify
          signs of violence and keep themselves or the person they are
          supporting safe from harm.
        </P>
        <ButtonWithLink
          fatButtonPadding={"1.2rem 0.8rem"}
          link={"/register"}
          textColor={"#373C5B"}
          buttonText={"REGISTER"}
          display={"inline-block"}
          textAlign={"center"}
          borderColor={"#E0F583"}
        />

        <ButtonWithLink
          fatButtonPadding={"1.2rem 0.8rem"}
          link={"/login"}
          textColor={"#373C5B"}
          buttonText={"LOGIN"}
          display={"inline-block"}
          textAlign={"center"}
          margin={"0 0 1.5rem 0"}
          borderColor={"#E0F583"}
        />
      </DynamicContainer>
      <SectionContainer backgroundColor={"#F4F4F9"} padding={"10rem 0 15rem 0"}>
        <DynamicContainer padding={"0 0 7rem 0"}>
          <Title>
            What the{" "}
            <Strong color={themeColor}>
              {" "}
              Family and Friend Support Program{" "}
            </Strong>{" "}
            for people supporting someone who is using alcohol and/or other
            drugs offers
          </Title>
          <P>
            A free online program and access to trusted and reliable information
            to assist families, friends and health professionals supporting
            someone who is using alcohol and/or other drugs.
          </P>
        </DynamicContainer>
      </SectionContainer>
      <GraphicBar
        tabletHeight={"auto"}
        url={"/images/domestic-pattern-light.svg"}
      >
        <DynamicContainer transform={"translateY(-20%)"}>
          <ProgramCardContainer
            cardData={domesticViolenceCardData}
            themeColor={"#706AA8"}
          />
          <Title textcolor={"white"} margin={"0 0 2rem 0"}>
            What is the for people supporting someone who is using alcohol
            and/or other drugs?
          </Title>
          <ButtonWithLink
            fatButtonPadding={"1.2rem 0.8rem"}
            link={"/register"}
            buttonText={"REGISTER"}
            display={"inline-block"}
            textAlign={"center"}
            backgroundColor={"#E0F583"}
            borderColor={"#E0F583"}
          />

          <ButtonWithLink
            fatButtonPadding={"1.2rem 0.8rem"}
            link={"/login"}
            buttonText={"LOGIN"}
            display={"inline-block"}
            textAlign={"center"}
            margin={"0 0 1.5rem 0"}
            backgroundColor={"#E0F583"}
            borderColor={"#E0F583"}
          />
        </DynamicContainer>
      </GraphicBar>

      <ProjectPartnersFooter themeColor={themeColor} textColor={"white"} />
    </>
  );
};

export default DomesticViolencePage;
