import styled from 'styled-components';
import bgImg from '../../assets/img/login-bg.svg';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5.5rem;
  height: calc(100vh - 8.9rem);
  max-width: 1500px;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    height: fit-content;
    padding: 15rem 0 5rem 0;
    background: transparent url(${bgImg}) 0% 0% no-repeat padding-box;
    background-size: cover;
  }
  @media only screen and (min-width: 1024px) and (max-height: 800px) {
    transform: scale(0.6);
  }
`;

export const PageBg = styled.div`
  position: absolute;
  width: 50%;
  height: calc(100vh);
  background: transparent url(${bgImg}) 0% 0% no-repeat padding-box;
  background-size: cover;
  z-index: -1;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const Form = styled.form`
  width: 80%;
`;

export const InputGroup = styled.label`
  display: block;
  text-align: left;
`;

export const LinkBox = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const LoginButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Hr = styled.hr`
  width: 40%;
`;

export const RequiredStar = styled.span`
  color: red;
`;

export const PasswordLink = styled.a`
  display: block;
  margin: 1rem;
`;

export const PasswordContainer = styled.div`
  position: relative;
`;

export const EyeBox = styled.div`
  position: absolute;
  right: 0.6rem;
  top: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.4rem;
  cursor: pointer;
  @media (max-width: 767px) {
    top: 0.6rem;
  }
`;

export const Eye = styled.img`
  width: 1.5rem;
`;
