import React, { useState } from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import BookmarkIcon from '@material-ui/icons/Bookmark';

const Link = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10rem;
  color: #00c7dd;
  font-weight: 700;
  text-transform: uppercase;
`;

const CollapseList = (props) => {
  const { title, pageData, userFlowStepUrl } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {pageData.length > 0 &&
            pageData.map((page, index) => (
              <ListItem
                key={`${page.bookmark.contentPageId}-${index}`}
                button
                style={{ paddingLeft: '1rem' }}
              >
                <ListItemIcon>
                  <BookmarkIcon
                    className="bookmark-icon"
                    style={{ color: 'rgba(0, 199, 221, 1)' }}
                  />
                </ListItemIcon>
                <ListItemText primary={page.bookmark.contentPage.title} />
                <Link
                  className="go-to-page"
                  href={
                    userFlowStepUrl === null
                      ? `${page.bookmark.contentPage.url}`
                      : `/content/${userFlowStepUrl}/${page.bookmark.contentPage.url}`
                  }
                >
                  go to page
                  <ListItemIcon>
                    <ArrowForwardIosIcon
                      style={{ color: 'rgba(0, 199, 221, 1)' }}
                    />
                  </ListItemIcon>
                </Link>
              </ListItem>
            ))}
        </List>
      </Collapse>
    </List>
  );
};

export default CollapseList;
