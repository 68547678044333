import { useLazyQuery } from "./useLazyQuery";
import { useRef } from "react";
import { gql } from "apollo-boost";

const CHECK_USER_AUTHORIZATION_BY_JWT = gql`
  query($claimType: String!, $token: String!) {
    user {
      checkUserAuthorizationByJwt(claimType: $claimType, token: $token)
    }
  }
`;

export const useCheckUserAuthorizationByJwt = (props: {
  onComplete: () => void;
  pollInterval: number;
}) => {
  const interval = useRef<any>();
  const { onComplete, pollInterval } = props;

  const [sendQuery, { data, refetch }] = useLazyQuery(
    CHECK_USER_AUTHORIZATION_BY_JWT,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  if (data) {
    if (data.user.checkUserAuthorizationByJwt) {
      clearInterval(interval.current);
      onComplete();
    } else if (interval.current === undefined) {
      interval.current = setInterval(() => {
        refetch();
      }, pollInterval);
    }
  }

  return sendQuery;
};
