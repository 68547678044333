import React from "react";
import { ContentWrapper } from "@netfront/ekardo-shared-library";
import CollapseList from "../../components/UI/Dropdown/CollapseList";
import OtherResources from "./OtherResources/OtherResources";
import {
  Wrapper,
  ResContainer,
  ResGeneralSection,
  ResTitle,
} from "../Bookmark/styled";
//GraphQL
import { useGetSignedUrl } from "../../hooks/useGetSignedUrl";

const ResourceContent = () => {
  const getSignedUrl = useGetSignedUrl();

  const defaultPdfs = {
    groupId: "Resources",
    userFlowStepUrl: null,
    contents: [
      {
        bookmark: {
          contentPageId: "default-pdf-1",
          contentPage: {
            title: "5 steps self-help handbook",
            url: getSignedUrl(
              "production/project/Gelada-Project-3-b91e5849-9d22-4711-be85-ec41e1da8869/Document/5-step-self-help-handbook-colour-3rd-edition-391eae99.pdf",
              "application/pdf"
            ),
          },
        },
      },
    ],
  };
  //TODO: factsheet is coming from ekardo! default text needs to be black

  return (
    <Wrapper>
      <ResContainer>
        <ResTitle>General</ResTitle>
        <ResGeneralSection id="resource-general">
          <CollapseList
            title={defaultPdfs.groupId}
            pageData={defaultPdfs.contents}
            userFlowStepUrl={defaultPdfs.userFlowStepUrl}
          />
        </ResGeneralSection>
        <ResTitle>Factsheets</ResTitle>
        <div id="toolbox-rescource">
          <ContentWrapper pageId={1246} />
        </div>
        <ResTitle>Other Resources and Services</ResTitle>
        <OtherResources />
      </ResContainer>
    </Wrapper>
  );
};

export default ResourceContent;
