import React from "react";
import { getSignedUrl } from "src/middleware/AWS/getSignedUrl";
import { ContentSnippetProps, IToolbox, UserFlowsProps } from "../Common/Interfaces";
import { convertBytesToMegabytes } from "../Common/Utils";
import { ToolboxBody } from "../Common/ToolboxBody";
interface AudioItemsProps {
  contentSnippets: ContentSnippetProps[];
  userFlows: UserFlowsProps[];
}

const KEY_PREFIX = 'tool-box-content-audio-items';

const AudioItems = ({ contentSnippets, userFlows }: AudioItemsProps) => {
  if (!contentSnippets.length) {
    return null;
  }

  const data: IToolbox[] = contentSnippets.map((contentSnippet: ContentSnippetProps) => {
    const {
      asset,
      contentPage: {
        title: accordionItemTitle,
        url: contentPageUrl,
        userFlowStep: {
          userFlowId,
          stepName: userFlowStepName,
          contentGroup: { url: contentGroupUrl },
          url: userFlowStepUrl,
        },
      },
    } = contentSnippet;

    const userFlowUrl = userFlows.find(flow => flow.id === userFlowId).url; 

    return {
      asset,
      userFlowId,
      userFlowUrl,
      accordionItemTitle,
      contentGroupUrl,
      contentPageUrl,
      userFlowStepName,
      userFlowStepUrl,
    };
  });

  function renderAccordionBody(item: IToolbox, index: number) {
    const { accordionItemTitle, asset: { contentType, fileName, fileSizeInBytes, s3Key } } = item;
    return (
      <figure key={`audio-${index}`} style={{background: 'black', borderRadius:10, padding: 10, color: 'white'}}>
      <figcaption
        style={{
          paddingBottom: '1rem',
        }}
      >
        <div>
          <b style={{ color: '#FFF'}}>Page:</b> {accordionItemTitle}
        </div>
        <div>
          <b style={{ color: '#FFF'}}>File name:</b> {fileName} ({convertBytesToMegabytes(fileSizeInBytes)})
        </div>
      </figcaption>
      <audio controls src={getSignedUrl(s3Key, contentType)}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    </figure>
    );
  }

  return (
    <ToolboxBody 
      keyPrefix={KEY_PREFIX}
      data={data}
      accordionBody={renderAccordionBody}/>
  );
};

export default AudioItems;
