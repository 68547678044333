export const CONTENT_SNIPPET_TYPE_APP = 'ContentSnippetAppType';
export const CONTENT_SNIPPET_TYPE_AUDIO = 'ContentSnippetAudioType';
export const CONTENT_SNIPPET_TYPE_DOCUMENT = 'ContentSnippetDocumentType';
export const CONTENT_SNIPPET_TYPE_EMBED = 'ContentSnippetEmbedType';
export const CONTENT_SNIPPET_TYPE_FORM = 'ContentSnippetFormType';
export const CONTENT_SNIPPET_TYPE_VIDEO = 'ContentSnippetVideoType';

export const CONTENT_SNIPPET_TYPES = {
  APP: CONTENT_SNIPPET_TYPE_APP,
  AUDIO: CONTENT_SNIPPET_TYPE_AUDIO,
  DOCUMENT: CONTENT_SNIPPET_TYPE_DOCUMENT,
  EMBED: CONTENT_SNIPPET_TYPE_EMBED,
  FORM: CONTENT_SNIPPET_TYPE_FORM,
  VIDEO: CONTENT_SNIPPET_TYPE_VIDEO,
};
