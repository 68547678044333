import React from 'react';
import { ContentSnippetProps, IToolbox, UserFlowsProps } from '../Common/Interfaces';
import { ToolboxBody } from '../Common/ToolboxBody';
interface VideoItemsProps {
  embedContentSnippets: ContentSnippetProps[];
  videoContentSnippets: ContentSnippetProps[];
  userFlows: UserFlowsProps[];
}

const KEY_PREFIX = 'tool-box-content-video-items';

const VideoItems = ({ embedContentSnippets, videoContentSnippets, userFlows }: VideoItemsProps) => {
  if (!(embedContentSnippets.length || videoContentSnippets.length)) {
    return null;
  }

  const data: IToolbox[] = embedContentSnippets.map((contentSnippet: ContentSnippetProps) => {
    const {
      code,
      title: accordionItemTitle,
      contentPage: {
        url: contentPageUrl,
        userFlowStep: {
          userFlowId,
          url: userFlowStepUrl,
          stepName: userFlowStepName,
          contentGroup: { url: contentGroupUrl, iconAsset },
        },
      },
    } = contentSnippet;

    const userFlowUrl = userFlows.find(flow => flow.id === userFlowId).url; 

    return {
      code,
      userFlowId,
      userFlowUrl,
      accordionItemTitle,
      contentGroupUrl,
      contentPageUrl,
      userFlowStepName,
      userFlowStepUrl,
      iconAsset
    };
  });

  function renderAccordionBody(item: IToolbox, index: number) {
    const { accordionItemTitle, code } = item;

    const createMarkup = () => ({
      __html: code,
    })

    return (
      <div
        key={`audio-${index}`}
        style={{ background: "black", borderRadius: 10, padding: 10, margin: 10, color: "white" }}
      >
        <div
          style={{
            paddingBottom: "1rem",
          }}
        >
          <b>Title</b>: {accordionItemTitle}
        </div>
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
    );
  }

  return (
    <ToolboxBody 
      keyPrefix={KEY_PREFIX}
      data={data}
      accordionBody={renderAccordionBody}/>
  );
};

export default VideoItems;
