import styled from "styled-components";

export const DynamicContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: ${({ padding }) => (padding ? padding : "2rem 0")};
  height: ${({ height }) => height};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  background-color: ${({ backgroundColor }) => backgroundColor};
  transform: ${({ transform }) => transform};
  @media (max-width: 1300px) {
    padding-left: 4.2rem;
    padding-right: 4.2rem;
  }
  @media (max-width: 1140px) {
    margin: ${({ smallScreenMargin }) => smallScreenMargin};
  }
  @media (min-width: 898px) {
    margin-bottom: ${({ offsetMarginForCardTransition }) =>
      offsetMarginForCardTransition};
  }
  @media (max-width: 897px) {
    transform: translateY(0);
  }
  @media (max-width: 726px) {
    display: ${({ smallScreenFlex }) => smallScreenFlex};
    flex-direction: ${({ smallScreenStack }) => smallScreenStack};
    justify-content: ${({ smallScreenJustifyContent }) =>
      smallScreenJustifyContent};
  }
`;

export const GraphicBar = styled.div`
  background: ${({ background, url }) => `${background} url(${url}) `};
  background-image: ${({ url }) => `url(${url}) `};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: ${({ height }) => height};
  margin-top: ${({ marginTop }) => marginTop};
  @media (min-width: 898px) {
    margin-bottom: ${({ offsetMarginForCardTransition }) =>
      offsetMarginForCardTransition};
  }
  @media (max-width: 1140px) {
    height: ${({ tabletHeight }) => tabletHeight};
  }
  @media (max-width: 950px) {
    height: ${({ tabletHeight }) => tabletHeight};
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 2rem 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
export const IconContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  height: 7.5rem;
  width: 7.5rem;
  min-width: 7.5rem;
  margin-right: 2rem;
  @media (max-width: 726px) {
    margin-bottom: ${({ smallScreenMargin }) => smallScreenMargin};
  }
`;
export const TitleIcon = styled.img`
  height: 6rem;
`;
export const Title = styled.h2`
  margin: ${({ margin }) => (margin ? margin : "0")};
  text-transform: uppercase;
  color: ${({ textcolor }) => (textcolor ? textcolor : "#082e3e")};
`;
export const Strong = styled.span`
  margin: 0;
  text-transform: uppercase;
  color: ${({ color }) => color};
`;
export const P = styled.p`
  color: ${({ textcolor }) => (textcolor ? textcolor : "#373c5b")};
  font-size: ${({ regularFontSize }) => regularFontSize};
  margin-bottom: 1.5rem;
  word-spacing: 0.15rem;
  line-height: 1.5;
  @media (max-width: 767px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;
export const Wrapper = styled.div`
  margin-top: 6.4rem;
  width: 100vw;
  display: flex;
  @media (max-width: 1516px) {
    flex-flow: column;
  }
`;
export const LeftSection = styled.div`
  box-sizing: border-box;
  background-image: url("/images/programs-side.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 50%;
  padding: 4.5rem;
  @media (max-width: 1516px) {
    height: auto;
    width: 100%;
  }
`;
export const RightSection = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 4.5rem;
  height: 100vh;
  width: 50%;
  @media (max-width: 1516px) {
    height: auto;
    width: 100%;
  }
`;
export const AButton = styled.a.attrs(({ link, target }) => ({
  href: link,
  target,
}))`
  border: 2px solid;
  display: ${({ display }) => display};
  text-align: ${({ textalign }) => textalign};
  color: ${({ textcolor }) => textcolor};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  border-color: ${({ bordercolor }) => bordercolor};
  font-size: 1rem;
  font-weight: bold;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  padding: ${({ fatbuttonpadding }) => fatbuttonpadding};
  border-radius: 4rem;
  min-width: 10rem;
  cursor: pointer;
  text-decoration: none;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    transform: scale(1.02);
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 0.7rem;
  }

  @media only screen and (max-width: 767px) {
    min-width: unset;
    width: 7rem;
    font-size: 0.7rem;
  }
`;
