import styled from "styled-components";
import bgImg from "../../assets/img/login-bg.svg";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 53.5px);
  max-width: 1500px;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    height: fit-content;
    padding: 15rem 0 5rem 0;
    background: transparent url(${bgImg}) 0% 0% no-repeat padding-box;
    background-size: cover;
  }
`;

export const PageBg = styled.div`
  position: absolute;
  width: 50%;
  height: calc(100vh);
  background: transparent url(${bgImg}) 0% 0% no-repeat padding-box;
  background-size: cover;
  z-index: -1;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const Wrapper = styled.form`
  width: 80%;
`;

export const Wrapper2 = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputGroup = styled.label`
  display: block;
  text-align: left;
`;

export const NameGroup = styled(InputGroup)`
  width: 45%;
`;

export const RegisterButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const RequiredStar = styled.span`
  color: red;
`;

export const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckBox = styled.input`
  margin: 1rem;
`;

export const Agreement = styled.p`
  display: inline;
`;

export const NameBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: inherit;
    ${NameGroup} {
      width: 100%;
    }
  }
`;

export const P = styled.p`
  text-align: center;
`;

export const SimpleIcon = styled.div`
  text-align: center;
  font-size: 5rem;
  color: rgba(0, 164, 183, 1);
`;
