import styled from "styled-components";

export const MenuWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const Card = styled.a`
  margin: 2rem;
  &:hover {
    filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
    transform: scale(1.02);
  }
`;

export const CardIcon = styled.img`
  width: 10rem;
`;
export const CardTitle = styled.p`
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
`;
