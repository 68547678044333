import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LogoLight from '../../assets/img/ffsp-logo_light.png';
import LogoDark from '../../assets/img/ffsp-logo_dark.png';
import Button from '../UI/Button/Button';
import EmergencyModal from '../../components/UI/EmergencyModal/EmergencyModal';

// **************styled Components********************
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: calc(100% - 8em);
  padding: 1em 4em;
  z-index: ${(props) => (props.isHome ? '10' : '5')};
  @media only screen and (max-width: 1024px) {
    padding: 1em 2em;
  }
  @media only screen and (max-width: 767px) {
    padding: 1em;
  }
`;

const Img = styled.img`
  margin: 0;
  width: 12rem;
  @media only screen and (max-width: 767px) {
    width: 6rem;
    margin: 0;
  }
`;

const ButtonGroup = styled.div``;

const BurgerBtn = styled.button`
  width: 5em;
  background-color: #02a4b7;
  color: #fff;
  position: fixed;
  top: 1em;
  right: 0px;
  border: none;
  border-radius: 5em 0 0 5em;
  height: 2em;
  text-align: left;
  outline: none;
  cursor: pointer;
  z-index: 11;
`;

const BtnSpan = styled.span`
  font-size: 1.5em;
  line-height: 1em;
  padding: 0.5em;
`;

const BtnSider = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 30%;
  z-index: 10;
  > div {
    display: flex;
    flex-direction: column;
  }
`;

// **************styled Components********************

const Header = (props) => {
  const { isHome } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const bodyScroll = () => {
    document.body.style.overflow = '';
  };

  return (
    <Wrapper onLoad={bodyScroll} isHome={isHome}>
      <Link to='/'>
        <Img src={isHome ? LogoDark : LogoLight} />
      </Link>

      <EmergencyModal />
      {width > 768 ? <BtnGroup /> : <BtnOnPhone />}
    </Wrapper>
  );
};

const BtnGroup = () => (
  <ButtonGroup>
    <Link to='/register'>
      {window.location.pathname === '/register' ? (
        <Button
          bg='#00A4B7'
          color='#fff'
          style={{ textTransform: 'uppercase' }}
        >
          REGISTER
        </Button>
      ) : (
        <Button
          bg='#373C5B'
          color='#fff'
          borderColor={window.innerWidth < 1024 ? '#fff' : null}
          style={{ textTransform: 'uppercase' }}
        >
          REGISTER
        </Button>
      )}
    </Link>
    <Link to='/login'>
      {window.location.pathname === '/login' ? (
        <Button
          bg='#00A4B7'
          color='#fff'
          bold
          style={{ textTransform: 'uppercase' }}
        >
          LOGIN
        </Button>
      ) : (
        <Button
          bg='#fff'
          color='#F02229'
          borderColor='#F02229'
          bold
          style={{ textTransform: 'uppercase' }}
        >
          LOGIN
        </Button>
      )}
    </Link>
  </ButtonGroup>
);

const BtnOnPhone = () => {
  const [isShowSider, setIsShowSider] = useState(false);
  const handleSider = () => {
    if (isShowSider) {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setIsShowSider(!isShowSider);
  };
  return (
    <>
      <BurgerBtn onClick={handleSider}>
        {!isShowSider ? <BtnSpan>&#9776;</BtnSpan> : <BtnSpan>&times;</BtnSpan>}
      </BurgerBtn>
      {isShowSider && (
        <BtnSider>
          <BtnGroup />
        </BtnSider>
      )}
    </>
  );
};

export default Header;
