import gql from "graphql-tag";

export const SEND_CONTACT_FORM = gql`
  mutation(
    $projectId: String!
    $sender: String!
    $lastname: String!
    $firstname: String!
    $phoneNumber: String
    $message: String
  ) {
    notification {
      sendContactForm(
        projectId: $projectId
        sender: $sender
        lastname: $lastname
        firstname: $firstname
        phoneNumber: $phoneNumber
        message: $message
      )
    }
  }
`;
