import React from "react";
import GeneralPage, {
  Container,
} from "../../components/LayoutGeneralPage/index";
import { ContentWrapper } from "@netfront/ekardo-shared-library";

const PrivacyPage = () => {
  return (
    <GeneralPage title="Privacy">
      <Container>
        <ContentWrapper pageId={618} mode={false} isPublic={true} />
      </Container>
    </GeneralPage>
  );
};

export default PrivacyPage;
