import adfLogo from '../../../assets/img/otherResources/adf.svg';
import bhcLogo from '../../../assets/img/otherResources/bhc.png';
import fdsLogo from '../../../assets/img/otherResources/fds.jpg';
import adisLogo from '../../../assets/img/otherResources/adis.png';
import waLogo from '../../../assets/img/otherResources/wa.svg';
import healthdirectLogo from '../../../assets/img/otherResources/healthdirect.svg';
import coLogo from '../../../assets/img/otherResources/co.png';
import tthLogo from '../../../assets/img/otherResources/tth.png';
import roLogo from '../../../assets/img/otherResources/ro.png';
import khLogo from '../../../assets/img/otherResources/kh.png';
import placeholder from '../../../assets/img/otherResources/placeholder.png';

const phoneSupport = [
  {
    id: 's1',
    title: 'Family Drug Support',
    phone: 1300368186,
    description:
      '24/7 phone support to families and carers in crisis due to alcohol and other drug use issues.',
    logo: fdsLogo,
  },
  {
    id: 's2',
    title: 'Alcohol and Other Drug Information Service',
    phone: 1800250015,
    description:
      '24/7 phone counselling, support, referrals and information for those affected by alcohol or other drugs.',
    logo: adisLogo,
  },
];

const otherResources = {
  adf: {
    id: 'r1',
    logo: adfLogo,
    description:
      'Find services in your local area with this nationwide directory',
    link: 'https://adf.org.au/help-support/',
  },
  bhc: {
    id: 'r2',
    logo: bhcLogo,
    description:
      'Information on treatments for heroine dependence and referral options',
    link:
      'https://www.betterhealth.vic.gov.au/health/HealthyLiving/heroin-dependence-medication-treatments',
  },
  healthdirect: {
    id: 'r3',
    logo: healthdirectLogo,
    description: 'Provides guidance on how to help your loved one and yourself',
    link:
      'https://www.healthdirect.gov.au/how-to-help-someone-with-a-drug-problem',
  },
  wa: {
    id: 'r4',
    logo: waLogo,
    description:
      'A booklet with info on dependence, treatments and how to support someone throughout',
    link:
      'https://www.mhc.wa.gov.au/media/1242/is-someone-you-care-about-seeking-treatment-for-heroin-or-other-opiate-use.pdf',
  },
  counsellingonline: {
    id: 'r5',
    logo: coLogo,
    description:
      'Alcohol and other drug support and chat counselling. Confidential, free and available 24/7.',
    link: 'https://www.counsellingonline.org.au/',
  },
  counsellingonlineSelfHelp: {
    id: 'r6',
    logo: coLogo,
    description:
      'A free self-help program online to help people using alcohol to set goals and make a plan to change their lifestyle',
    link: 'https://www.counsellingonline.org.au/find-support/self-help-modules',
  },
  counsellingonlineOthers: {
    id: 'r7',
    logo: coLogo,
    description:
      'Info on how you can best support your loved one and also access support yourself',
    link: 'https://www.counsellingonline.org.au/helping-others',
  },
  turntohelp: {
    id: 'r8',
    logo: tthLogo,
    description:
      'A summary of the treatment options available for opioid addiction',
    link:
      'https://www.turntohelp.com.au/treatment-opioid-painkiller-dependence/',
  },
  reachout: {
    id: 'r9',
    logo: roLogo,
    description:
      'Info for young people about alcohol addiction and where to get help',
    link: 'https://au.reachout.com/articles/alcohol-addiction',
  },
  kidshelpline: {
    id: 'r10',
    logo: khLogo,
    description:
      'Info for children and young people about alcohol addiction and how to stay away',
    link: 'https://kidshelpline.com.au/teens/issues/drugs-and-alcohol',
  },
};

export const OtherResourcesData = [
  {
    id: 'Heroin',
    resTitle: 'Help, treatment quit',
    resources: [
      otherResources.adf,
      otherResources.bhc,
      otherResources.healthdirect,
      otherResources.wa,
    ],
    support: phoneSupport,
  },
  {
    id: 'Ecstasy',
    resTitle: 'Help, treatment, quit etc.',
    resources: [
      otherResources.adf,
      otherResources.bhc,
      otherResources.healthdirect,
      otherResources.counsellingonlineOthers,
    ],
    support: phoneSupport,
  },
  {
    id: 'Opiate',
    resTitle: 'Help, treatment, quit etc.',
    resources: [
      otherResources.adf,
      otherResources.turntohelp,
      otherResources.bhc,
      otherResources.healthdirect,
      otherResources.counsellingonlineOthers,
    ],
    support: [
      ...phoneSupport,
      {
        id: 's3',
        title: 'Opioid Treatment Line',
        phone: 1800642428,
        description:
          'The Opioid Treatment Line (OTL) provides information, advice and referral to opioid treatment options. The OTL is a free service available Monday to Friday: 9:30am to 5pm.',
        logo: placeholder,
      },
    ],
  },
  {
    id: 'Alcohol',
    resTitle: 'Help, treatment, quit etc.',
    resources: [
      otherResources.adf,
      otherResources.counsellingonline,
      otherResources.counsellingonlineSelfHelp,
      otherResources.kidshelpline,
      otherResources.bhc,
      otherResources.healthdirect,
      otherResources.counsellingonlineOthers,
    ],
    support: phoneSupport,
  },
  {
    id: 'Marijuana',
    resTitle: 'Help, treatment, quit etc.',
    resources: [
      otherResources.adf,
      otherResources.bhc,
      otherResources.healthdirect,
      otherResources.counsellingonlineOthers,
    ],
    support: phoneSupport,
  },
];
