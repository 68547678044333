
import React, { useState } from 'react';
import { withRouter } from 'react-router';

import PageHelmet from '../../components/PageHelmet/index';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import LoginContainer from '../../containers/LoginContainer/LoginContainer';
import ErrorMessage from '../../components/Message/ErrorMessage';
import ValidationProcessor from '../../components/Message/ValidationProcessor';
import {
  PageWrapper,
  PageBg,
  Form,
  InputGroup,
  LinkBox,
  LoginButton,
  Hr,
  RequiredStar,
  PasswordLink,
  PasswordContainer,
  EyeBox,
  Eye,
} from './styled';
import ReactGA from 'react-ga';

import { useHandleLoadingState } from '../../context/LoadingContext';
// GraphQL
import { Login, _GetAccessToken } from '@netfront/gelada-react-shared';
import GetStep from '../../middleware/Userflow/getUserFlowStep';
// assets
import img from '../../assets/img/login.svg';
import eye from '../../assets/img/eye.svg';
import eyeOff from '../../assets/img/eye-off.svg';

const title = 'LOGIN';
const description = 'Please fill out the information to log into your account';

const LoginPage = (props) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordType, setPasswordType] = useState('password');
  const [loginRequest, setLoginRequest] = useState(false);
  const [checkSurveyComplete, setCheckSurveyComplete] = useState(false);
  const [validationErrors, setValidationErrors] = useState('');
  const validate = () => ({
    UsernameEmpty: {
      validation: username && username.trim().length > 0,
      errorMessage: 'Username must not be empty',
    },
    PasswordEmpty: {
      validation: password && password.trim().length > 0,
      errorMessage: 'Password must not be empty',
    },
  });

  useHandleLoadingState(loginRequest);

  const handleLoginCompleted = (_data, type) => {
    setLoginRequest(false);

    if (
      type === 'data' &&
      _GetAccessToken() // required as the token saving is async, but needed immediately
    ) {
      setCheckSurveyComplete(true);
      ReactGA.set({ userId: _data.data.login.user.id });
    }
    if (type === 'error') {
      var last = _data.toString().lastIndexOf(':');
      const apiErrMsg = _data.toString().slice(last, _data.toString().length);
      if (
        apiErrMsg.includes('User not found') ||
        apiErrMsg.includes('The login or password is incorrect')
      ) {
        setValidationErrors(
          "Sorry, but we can't find an account with this email address and password. Please try again."
        );
      } else {
        setValidationErrors('Error' + apiErrMsg);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = ValidationProcessor(validate());
    if (!validationErrors.modelValid) {
      setValidationErrors(validationErrors.validations);
    } else {
      setValidationErrors('');
      setLoginRequest(true);
    }
  };

  const handleCheckSurveyComplete = (data, type) => {
    if (
      data.userRepetition.length <= data.repetitionAllowed &&
      (data.userRepetition.length === 0 ||
        data.userRepetition[0].completed === null)
    ) {
      const lastViewed =
        data.lastViewedContentPage !== null
          ? data.lastViewedContentPage.url
          : data.contentGroup.contentPages.find((p) => p.sort === 0).url;
      props.history.push(`/content/alcohol-or-other-drugs/${data.url}/${lastViewed}`);
    } else {
      props.history.push('/dashboard');
    }
  };

  return (
    <div style={{ minWidth: '300px' }}>
      <PageHelmet title="FFSP: Login" />
      <Header />
      <PageBg />
      <PageWrapper>
        <LoginContainer logo={img} title={title} description={description}>
          <Form onSubmit={handleSubmit}>
            <InputGroup htmlFor="input">
              Username<RequiredStar>*</RequiredStar>
              <Input isChanged={(value) => setUsername(value)} />
            </InputGroup>
            <InputGroup htmlFor="input">
              Password<RequiredStar>*</RequiredStar>
              <PasswordContainer>
                <Input
                  isChanged={(value) => setPassword(value)}
                  type={passwordType}
                />
                <EyeBox>
                  <Eye
                    alt="show/hide password"
                    src={passwordType === 'password' ? eye : eyeOff}
                    onClick={() =>
                      setPasswordType(
                        passwordType === 'password' ? 'text' : 'password'
                      )
                    }
                  />
                </EyeBox>
              </PasswordContainer>
            </InputGroup>
            <LoginButton>
              <Button
                bg="#fff"
                color="#F02229"
                borderColor="#F02229"
                bold
                type="submit"
              >
                LOGIN
              </Button>
            </LoginButton>
            <LinkBox>
              <PasswordLink href="/forget-password">
                Forget Password?
              </PasswordLink>
              <Hr />
              <p>
                Don&apos;t have an account? &nbsp;
                <a href="/register">
                  <b>Sign up now</b>
                </a>
              </p>
            </LinkBox>
          </Form>
        </LoginContainer>
      </PageWrapper>
      <Footer />
      {validationErrors !== '' && <ErrorMessage message={validationErrors} />}
      {loginRequest && (
        <Login
          onComplete={handleLoginCompleted}
          login={username}
          password={password}
        />
      )}
      {checkSurveyComplete && (
        <GetStep userFlowStepId={40} onComplete={handleCheckSurveyComplete} />
      )}
    </div>
  );
};

export default withRouter(LoginPage);
