import React from "react";
import styled from "styled-components";
import ButtonWithLink from "../Button/ButtonWithLink";

const Card = styled.li`
  box-shadow: 0px 0px 13px #231f2026;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: ${({ alternatecardproperties }) =>
    alternatecardproperties ? alternatecardproperties.height : "14rem"};
  width: ${({ alternatecardproperties }) =>
    alternatecardproperties ? alternatecardproperties.width : "17rem"};
  margin-bottom: 2rem;
  margin-right: 2rem;
  @media (min-width: 2047px) {
    height: ${({ alternatecardproperties }) =>
      alternatecardproperties ? alternatecardproperties.LargeHeight : "14rem"};
    width: ${({ alternatecardproperties }) =>
      alternatecardproperties ? alternatecardproperties.LargeWidth : "17rem"};
  }
  @media (max-width: 1343px) {
    width: 37%;
  }
  @media (max-width: 1015px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    height: auto;
  }
`;

const TextContainer = styled.div`
  margin: 1rem 0;
`;

const CardText = styled.p`
  display: inline;
  font-size: 1em;
  font-weight: bold;
  word-spacing: 0.1rem;
`;
const HighlightedText = styled.p`
  display: inline;
  color: ${({ color }) => color};
  font-size: 1em;
  font-weight: bold;
`;

const CardLogo = styled.img`
  height: ${({ logoSize }) => (logoSize ? logoSize : "3.5rem")};
`;

const CardWithButton = ({
  logo,
  logoSize,
  info,
  highlightedText,
  buttonText,
  link,
  themeColor,
  alternateCardProperties,
  textColor,
  borderColor,
}) => {
  return (
    <Card alternatecardproperties={alternateCardProperties}>
      <CardLogo logoSize={logoSize} src={logo} alt={"placeholder"} />
      <TextContainer>
        <CardText>{info} </CardText>
        <HighlightedText color={themeColor}>{highlightedText}</HighlightedText>
      </TextContainer>
      <ButtonWithLink
        link={link}
        themeColor={themeColor}
        buttonText={buttonText}
        textColor={textColor}
        borderColor={borderColor}
      />
    </Card>
  );
};

export default CardWithButton;
