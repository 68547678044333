import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types';
import ToggleBtn from "../UI/Button/ToggleButton";
import "animate.css";

const Outter = styled.div`
  height: 100%;
  position: absolute;
  z-index: 10;

  @media print {
    display: none;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 18em;
  background: ${(props) =>
    `transparent linear-gradient(${
      props.bgType ?? "204deg, #0383ba 0%, #363d5c 88%, #373c5b 100%"
    }) 0% 0% no-repeat padding-box`};
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 10;
  @media (min-width: 1600px) {
    width: 18em;
  }
`;

const Content = styled.nav`
  padding: 0 2rem 2rem 2rem;
`;

const Title = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 2.5rem 0 2rem 0;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
`;

const H3 = styled.h3`
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 500;
  margin: 0 1em;
  color: #373c5b;
`;

const ToggleWrapper = styled.div`
  position: absolute;
  right: -30px;
  top: 6.4em;
  visibility: visible;
`;

const bgGradients = {
  aod: "204deg, #0383ba 0%, #363d5c 88%, #373c5b 100%",
  domestic: "204deg, #706AA8 0%, #706aa8ab 88%, #706aa858 100%",
  rural: "204deg, #A85B54 0%, #a85b5496 88%, #a85b5434 100%",
};

const getBgGradient = (userFlowId) => {
  if (userFlowId.includes("domestic")) return bgGradients.domestic;
  else if (userFlowId.includes("rural")) return bgGradients.rural;
  else return bgGradients.aod;
};

const LeftSideBar = (props) => {
  const { children, title, isShowed, defaultOpen, userFlowId } = props;
  const [showSidebar, setShowSidebar] = useState(false);
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setShowSidebar(defaultOpen);
  }, [defaultOpen]);

  const handleShowBar = () => {
    isShowed(!showSidebar);
    if (showSidebar) {
      setAnimation("animated slideOutLeft");
      setTimeout(() => {
        setShowSidebar(false);
      }, 800);
    } else {
      setShowSidebar(true);
      setAnimation("animated slideInLeft");
    }
  };

  const bgType = getBgGradient(userFlowId);

  return (
    <Outter className={`${animation} fast`}>
      {showSidebar && (
        <>
          <Wrapper bgType={bgType}>
            <Title>
              <H3>{title}</H3>
            </Title>
            <Content>
              <ul>{children}</ul>
            </Content>
          </Wrapper>
        </>
      )}
      <ToggleWrapper>
        <ToggleBtn direction={defaultOpen ? "left" : "right"} isClicked={handleShowBar} size={6} />
      </ToggleWrapper>
    </Outter>
  );
};

export default LeftSideBar;
