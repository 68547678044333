/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useRef } from 'react';
import { getSignedUrl } from '../middleware/AWS/getSignedUrl';
import { expiryTime } from '../consts/s3';

export function useGetSignedUrl() {
  return (key: string, type: string): string => {
    function get() {
      return getSignedUrl(key, type);
    }

    const [fileHref, setFileHref] = useState<string>(get);
    const interval = useRef<any>();

    // eslint-disable-next-line eqeqeq
    if (interval.current == undefined) {
      interval.current = setInterval(() => {
        setFileHref(get());
        // expiryTime - 5 ensures a new url is obtained before the old one expires
      }, (expiryTime - 5) * 1000);
    }

    return fileHref;
  };
}
