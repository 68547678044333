import { useMutation as useApolloMutation } from "@apollo/react-hooks";
import { useHandleLoadingState } from '../context/LoadingContext'

export function useMutation(...args) {
  const query = useApolloMutation(...args)

  useHandleLoadingState(query[1].loading)
  
  return query
}

