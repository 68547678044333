import React from "react";
import { useQuery } from "../../hooks/useQuery";
import gql from "graphql-tag";
import Error from "../QueryError";

export const SEARCH_USERFLOWS = gql`
  query searchUserflows($projectId: String) {
    userFlow {
      searchUserFlows(projectId: $projectId) {
        id
        title
        url
        userFlowSteps {
          id
          stepName
          description
          url
          isComplete
          lastViewedContentPage {
            id
            url
          }
          contentGroup {
            id
            url
            description
            type
            iconAsset {
              assetId
              contentType
              s3Key
            }
            contentPages(status: [UNPUBLISHED, PUBLISHED], topLevelOnly: true) {
              sort
              url
            }
          }
        }
      }
    }
  }
`;

const SearchUserflows = (props) => {
  const { onComplete, projectId } = props;
  const { error, data } = useQuery(SEARCH_USERFLOWS, {
    fetchPolicy: "network-only",
    variables: {
      projectId,
    },
  });

  const handleError = (error) => {
    onComplete(error, "error");
  };

  if (error) {
    onComplete(error.message, "error");
    return <Error message={error.message} onError={handleError} />;
  }
  if (data && Object.entries(data).length !== 0) {
    onComplete(data.userFlow.searchUserFlows, "data");
    return null;
  }
  return <></>;
};

export default SearchUserflows;
