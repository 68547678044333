import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { ReactNode, useState } from "react";

interface ToolboxAccordionProps {
  header: ReactNode;
  children: ReactNode;
}

const ToolboxAccordion: React.FC<ToolboxAccordionProps> = ({ header, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        {header}
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

export default ToolboxAccordion;