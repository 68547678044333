import React, { useEffect } from "react";
import WhiteHeader from "../../components/Header/WhiteHeader";
import styled from "styled-components";
import Footer from "../../components/Footer/Footer";
import {
  InnerContainer,
  Container7,
  LeftBox4,
  H2Title2,
  RightBox4,
  Strong,
  P,
  Img,
} from "../HomePage/styled";
import bsImg from "../../assets/img/pexels-liza-summer.png";
import { scrollToTop } from "../../utils/utils";
import { Wrapper } from "../AppPage/style";

const BreathingSpacePage = () => {
  const Section = styled.section`
    margin-top: 5rem;
  `;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <WhiteHeader showProgramsNav={false} />
      <Wrapper>
        <div className="min-h-body w-full">
          <div className="bg-ocean">
            <div className="container-sm text-center pt-12 pb-16 relative">
              <h1 className="color-white text-uppercase mb-8 font-header weight-300">
                You can find the Breathing Space app on <br /> the Apple Store
                and Google Play Store
              </h1>
              <p className="color-white">Click the links to download</p>

              <div className="bg-white radius p-6 px-8 absolute left-50 top-full -translate-50 shadow flex align-center">
                <a
                  className="mr-4 flex align-center"
                  href="https://apps.apple.com/au/app/breathing-space-community/id1106738279"
                  target="_blank"
                >
                  <img src="/images/apple-store.svg" alt="apple store link" />
                </a>
                <a
                  className="flex align-center"
                  href="https://play.google.com/store/apps/details?id=com.breathingspacecommunity.netfront"
                  target="_blank"
                >
                  <img
                    src="/images/google-play.svg"
                    alt="google play store link"
                  />
                </a>
              </div>
            </div>
          </div>
          <Section>
            <Container7>
              <InnerContainer>
                <LeftBox4>
                  <Img alt="Woman reading a tablet device" src={bsImg} />
                </LeftBox4>
                <RightBox4>
                  <div>
                    <H2Title2>
                      Launching the&nbsp;
                      <Strong>Family and Friends Support Program (ffsp)</Strong>
                      &nbsp;online community
                    </H2Title2>
                    <P>
                      We are pleased to announce the commencement of a six-month
                      trial for family and friends of a moderated online
                      discussion forum. This will provide an opportunity to
                      share experiences with people within the community who
                      might be going through a similar experience to yourself.
                      We also have psychologists and social workers who will
                      contribute and moderate this site. It is available from 10
                      January 2022.
                    </P>
                    <P>
                      Click&nbsp;
                      <a
                        href="https://breathingspace.community/FFSP"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>
                      &nbsp;to participate
                    </P>
                  </div>
                </RightBox4>
              </InnerContainer>
            </Container7>
          </Section>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

export default BreathingSpacePage;
