import React, { useState, useContext } from "react";
import styled from "styled-components";
import jwt_decode from "jwt-decode";
import ReactGA from "react-ga";
import { Link, withRouter } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import GlobalContext from "../../context/globalContext";
import EmergencyModal from "../../components/UI/EmergencyModal/EmergencyModal";
import Logo from "../../assets/img/ffsp-logo-icon.png";
// Assets
import NotificationIcon from "../../assets/img/Notification.svg";
import {
  GetLoggedUser,
  _ClearStorage,
  _GetAccessToken,
} from "@netfront/gelada-react-shared";
import { useHandleLoadingState } from "../../context/LoadingContext";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 0.8rem 4rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  z-index: 99;
  @media only screen and (max-width: 1023px) {
  }
  @media only screen and (max-width: 767px) {
    padding: 0.5rem 1rem;
  }

  @media print {
    display: none;
  }
`;

const Img = styled.img`
  width: 3.3rem;
  @media only screen and (max-width: 1023px) {
    width: 3rem;
  }
  @media only screen and (max-width: 767px) {
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  position: relative;
`;

const UserInfo = styled.span`
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #00a4b7;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 1.5rem;
`;

const UserOperation = styled.div`
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-width: 140px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  right: 3px;
  overflow: hidden;
`;

const OperationItem = styled.a`
  display: block;
  padding: 0.7rem 1.2rem;
  border: none;
  cursor: pointer;
  color: #000;
  font-size: 1rem;
  font-family: Arial;
  &:hover {
    background-color: #f3f3f4;
  }
`;

const LogoutBtn = styled.button`
  display: block;
  padding: 0.7rem 1.2rem;
  border: none;
  cursor: pointer;
  color: #000;
  font-size: 1rem;
  font-family: Arial;
  outline: none;
  text-align: left;
  &:hover {
    background-color: #e1e1e1;
  }
`;

const NoticeIcon = styled.div`
  padding-top: 2px;
  margin-right: 1rem;
  cursor: pointer;
`;

const ConsoleHeader = (props) => {
  const { user, storeUser } = useContext(GlobalContext);
  const [showOperation, setShowOperation] = useState(false);
  const [checkUser, setCheckUser] = useState(true);

  let decoded = jwt_decode(_GetAccessToken());
  let permissions = decoded.permissions ? JSON.parse(decoded.permissions) : [];
  permissions = permissions.find(
    (p) =>
      p.EntityGuid === process.env.REACT_APP_PROJECT_ID ||
      p.ParentGuid === process.env.REACT_APP_PROJECT_ID
  );

  const handleCheckUser = (data, type) => {
    setCheckUser(false);
    if (type === "data") {
      storeUser(data.data.user.getLoggedUser);
    }
  };

  const handleClickAway = () => {
    setShowOperation(false);
  };
  const handleLogout = () => {
    ReactGA.set({ userId: "" });

    _ClearStorage();
    window.localStorage.clear();
    window.sessionStorage.clear();

    // refreshing the app in order to quickly flush old information
    window.location.href = "/";
  };

  useHandleLoadingState(checkUser);
  return (
    <>
      {checkUser ? (
        <GetLoggedUser onComplete={handleCheckUser} />
      ) : (
        <Wrapper>
          <Link to="/dashboard">
            <Img src={Logo} />
          </Link>
          <EmergencyModal />
          <ClickAwayListener onClickAway={handleClickAway}>
            <Container>
              <NoticeIcon>
                <img src={NotificationIcon} alt="Notification Icon" />
              </NoticeIcon>
              {user.firstname !== undefined && (
                <div>
                  <UserInfo onClick={() => setShowOperation(!showOperation)}>
                    {user.firstname.charAt(0).toUpperCase()}
                  </UserInfo>
                  {showOperation && (
                    <UserOperation
                      isAuth={permissions && permissions.Permission <= 8}
                    >
                      {permissions && permissions.Permission <= 8 && (
                        <OperationItem href="https://backoffice.ffsp.com.au/">
                          Backoffice
                        </OperationItem>
                      )}
                      <OperationItem href="/toolbox">Toolbox</OperationItem>
                      <OperationItem href="/bookmarks">
                        Bookmarks
                      </OperationItem>
                      <OperationItem href="/dashboard">Dashboard</OperationItem>
                      <OperationItem href="/contact">Contact</OperationItem>
                      <LogoutBtn onClick={handleLogout}>Logout</LogoutBtn>
                    </UserOperation>
                  )}
                </div>
              )}
            </Container>
          </ClickAwayListener>
        </Wrapper>
      )}
    </>
  );
};

export default withRouter(ConsoleHeader);
