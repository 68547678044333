import styled from "styled-components";

export const SectionTitle = styled.h2`
  font-size: 1.35rem;
  line-height: 1.5;
  margin: 0 0 1.5rem 0;
`;

export const Container = styled.div`
  background-color: #fff;
  margin: 2em auto;
  max-width: 1000px;
  padding: 1em;
  width: 80%;
`;

export const P = styled.p`
  font-size: .9rem;
`;


export const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 22rem);
`;

export const SectionContainer = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  max-width: ${props => props.size === 'sm' ? '750px' : '70rem'};
  margin: 0 auto;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);
  height: 100%;
  min-height: 20rem;
  margin: 1rem 0 4rem 0;
  padding: 3rem;
  width: 100%;
`;

export const Section = styled.div`
  border: 1px solid rgba(207, 209, 210, 1);
  margin-bottom: 1rem;
  color: rgba(21, 76, 117, 1);
`;
