import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// **************styled Components********************
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* position: fixed; */
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0 2rem;
  background: #373c5b;
  color: white;
  z-index: 100;
  @media only screen and (max-width: 767px) {
    flex-flow: column wrap;
    align-items: center;
    font-size: 0.8rem;
    padding: 1rem 0;
  }
`;

const LinkWrapper = styled.nav`
  display: flex;
  justify-content: space-around;
`;

const TextWrapper = styled(LinkWrapper)`
  @media only screen and (max-width: 425px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Line = styled.p`
  margin: 1rem;
`;

const TextLine = styled(Line)`
  @media only screen and (max-width: 425px) {
    display: none;
  }
`;

const Link = styled(NavLink)`
  color: white;
  text-decoration: none;
  margin: 1rem 0;
`;
// **************styled Components********************

const footer = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <p>© {new Date().getFullYear()} Family and Friends Support Program</p>
        <TextLine>|</TextLine>
        <p>Site by Netfront</p>
      </TextWrapper>
      <LinkWrapper>
        <Link to="/contact">Contact</Link>
        <Line>|</Line>
        <Link to="/terms-and-conditions">Terms and conditions</Link>
        <Line>|</Line>
        <Link to="/privacy">Privacy</Link>
      </LinkWrapper>
    </Wrapper>
  );
};

export default footer;
