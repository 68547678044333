import React from "react";
import { Helmet } from "react-helmet";
import { usePageView } from "../GoogleAnalytics/index";

const PageHelmet = (props) => {
  const { children, title } = props;
  usePageView();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta
        name="description"
        content="Family and Friend Support Program is an online intervention and support package for families/friends supporting loved ones with substance use issues"
      />
      {children}
    </Helmet>
  );
};

export default PageHelmet;
