import { useQuery as useApolloQuery } from "@apollo/react-hooks";
import { useHandleLoadingState } from '../context/LoadingContext'

export function useQuery(...args) {
  const query = useApolloQuery(...args)

  useHandleLoadingState(query.loading)
  
  return query
}

