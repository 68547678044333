import React from "react";
// Components
import PageHelmet from "../../components/PageHelmet/index";
import Header from "../../components/Header/ConsoleHeader";
import SecondaryHeader from "../../components/Header/SecondaryHeader";
import BookmarkContent from "../../components/Bookmark/Content";
import Footer from "../../components/Footer/Footer";
import { Wrapper } from "../ConsolePage/styled";

const BookmarksPage = () => {
  return (
    <Wrapper>
      <PageHelmet title="FFSP: Bookmarks" />
      <Header />
      <SecondaryHeader title="Bookmarks" />
      <BookmarkContent />
      <Footer />
    </Wrapper>
  );
};

export default BookmarksPage;
