import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { _GetAccessToken } from "@netfront/gelada-react-shared";

export default function GeladaClient(getToken = true) {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GELADA_API_URL,
    fetch,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = getToken ? _GetAccessToken() : "";
    return {
      headers: {
        ...headers,
        authorization: token ? `${token}` : "",
        custom_build_guid: process.env.REACT_APP_PROJECT_ID,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return client;
}
