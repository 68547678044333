import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import LogoDark from "../../assets/img/ffsp-logo_dark.png";
import Button from "../UI/Button/Button";

// **************styled Components********************
const Wrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  background-color: white;
  justify-content: space-between;
  top: 0;
  width: 100%;
  padding: 1em 4em;
  z-index: ${(props) => (props.isHome ? "10" : "5")};
  @media only screen and (max-width: 1024px) {
    padding: 1em 2em;
  }
  @media only screen and (max-width: 767px) {
    padding: 1em;
  }
`;

const ProgramBar = styled.div`
  position: fixed;
  top: 6.2rem;
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: ${({ themeColor }) => themeColor};
  z-index: 1;
  @media only screen and (max-width: 767px) {
    top: 4.2rem;
  }
`;

const WhatArePrograms = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  color: #0383ba;
  padding: 0 2rem;
  max-width: 48rem;
  @media only screen and (max-width: 907px) {
    font-size: 0.7rem;
    flex-flow: column;
  }
  @media only screen and (max-width: 725px) {
    display: none;
  }
`;
const ProgramNav = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  width: 36rem;
  height: 4rem;
  border-radius: 0.6rem;
  transform: translateY(50%);
  color: #0383ba;
  @media only screen and (max-width: 725px) {
    font-size: 0.7rem;
    width: 80%;
    height: auto;
  }
`;
const ProgramNavLink = styled(NavLink)`
  cursor: pointer;
  font-weight: bold;
  color: #0383ba;
  &.active {
    color: ${({ themecolor }) => themecolor};
  }
  @media only screen and (max-width: 725px) {
    margin: 1rem 0;
  }
`;

const Img = styled.img`
  margin: 0;
  width: 12rem;
  @media only screen and (max-width: 767px) {
    width: 6rem;
    margin: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

const BurgerBtn = styled.button`
  width: 5em;
  background-color: #02a4b7;
  color: #fff;
  top: 1em;
  right: 0px;
  border: none;
  border-radius: 5em;
  height: 2em;
  text-align: center;
  outline: none;
  cursor: pointer;
  z-index: 11;
`;

const BtnSpan = styled.span`
  font-size: 1.5em;
  line-height: 1em;
  padding: 0.5em;
`;

const BtnSider = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 30%;
  z-index: 10;
  > div {
    display: flex;
    flex-direction: column;
  }
`;

// **************styled Components********************

const WhiteHeader = (props) => {
  const { isHome, themeColor, showProgramsNav } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const bodyScroll = () => {
    document.body.style.overflow = "";
  };

  return (
    <>
      <Wrapper onLoad={bodyScroll} isHome={isHome}>
        <Link to="/">
          <Img src={LogoDark} />
        </Link>
        <WhatArePrograms>
          <ProgramNavLink themecolor={themeColor} to="/">
            What are the Family and Friend Support Programs?
          </ProgramNavLink>
          <ProgramNavLink themecolor={themeColor} to="/programs">
            Programs
          </ProgramNavLink>
          <ProgramNavLink themecolor={themeColor} to="/app">
            App
          </ProgramNavLink>
          <ProgramNavLink themecolor={themeColor} to="/breathing-space">
            Breathing Space
          </ProgramNavLink>
        </WhatArePrograms>
        {width > 1244 ? <BtnGroup /> : <BtnOnPhone />}
      </Wrapper>

      {showProgramsNav && (
        <ProgramBar themeColor={themeColor}>
          <ProgramNav>
            <ProgramNavLink themecolor={themeColor} to="/ice">
              ICE
            </ProgramNavLink>
            <ProgramNavLink themecolor={themeColor} to="/aod">
              AOD
            </ProgramNavLink>
            <ProgramNavLink themecolor={themeColor} to="/domestic-violence">
              DOMESTIC VIOLENCE
            </ProgramNavLink>
            <ProgramNavLink themecolor={themeColor} to="/rural-remote">
              RURAL REMOTE
            </ProgramNavLink>
          </ProgramNav>
        </ProgramBar>
      )}
    </>
  );
};

const BtnGroup = () => (
  <ButtonGroup>
    <Link to="/register">
      {window.location.pathname === "/register" ? (
        <Button
          bg="#00A4B7"
          color="#fff"
          style={{ textTransform: "uppercase" }}
        >
          REGISTER
        </Button>
      ) : (
        <Button
          bg="#373C5B"
          color="#fff"
          borderColor={window.innerWidth < 1024 ? "#fff" : null}
          style={{ textTransform: "uppercase" }}
        >
          REGISTER
        </Button>
      )}
    </Link>
    <Link to="/login">
      {window.location.pathname === "/login" ? (
        <Button
          bg="#00A4B7"
          color="#fff"
          bold
          style={{ textTransform: "uppercase" }}
        >
          LOGIN
        </Button>
      ) : (
        <Button
          bg="#fff"
          color="#F02229"
          borderColor="#F02229"
          bold
          style={{ textTransform: "uppercase" }}
        >
          LOGIN
        </Button>
      )}
    </Link>
  </ButtonGroup>
);

const BtnOnPhone = () => {
  const [isShowSider, setIsShowSider] = useState(false);
  const handleSider = () => {
    if (isShowSider) {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = "hidden";
    }
    setIsShowSider(!isShowSider);
  };
  return (
    <>
      <BurgerBtn onClick={handleSider}>
        {!isShowSider ? <BtnSpan>&#9776;</BtnSpan> : <BtnSpan>&times;</BtnSpan>}
      </BurgerBtn>
      {isShowSider && (
        <BtnSider>
          <ul className="p-0">
            <li className="mb-4">
              <a className="color-blue" href="/">
                What are the Family and Friend Support Programs?
              </a>
            </li>
            <li className="mb-4">
              <a className="color-blue" href="/programs">
                Programs
              </a>
            </li>
            <li className="mb-4">
              <a className="color-blue" href="/app">
                App
              </a>
            </li>
            <li className="mb-8">
              <a className="color-blue" href="/breathing-space">
                Breathing Space
              </a>
            </li>
          </ul>

          <BtnGroup />
        </BtnSider>
      )}
    </>
  );
};

export default WhiteHeader;
